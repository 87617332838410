import { fetchItemsSuccess } from "actions/ItemsActions";
import {
  FETCH_ITEMS,
  UPDATE_ITEM_LEVEL_QUANTITY,
  EDIT_WAREHOUSE,
} from "constants/ActionTypes";
import { editWarehouse } from "actions/ItemsActions";
import { NotificationManager } from "react-notifications";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { workForceAxios as axios } from "util/Api";

const fetchItemsAPI = async level => {
  let response = {};
  try {
    response = await axios.get(
      `/inventory/getItemsForTechnician?level=${level}`
    );
  } catch (error) {
    return {
      message: error.message,
    };
  }
  return response.data;
};

function* fetchItemsRequest(action) {
  const { level } = action;
  try {
    const items = yield call(fetchItemsAPI, level);
    if (items.success) {
      yield put(fetchItemsSuccess(items));
    } else {
      NotificationManager.listNotify.forEach(n =>
        NotificationManager.remove({ id: n.id })
      );
      NotificationManager.error(items.message);
    }
  } catch (error) {
    NotificationManager.listNotify.forEach(n =>
      NotificationManager.remove({ id: n.id })
    );
    NotificationManager.error(error.message || "Error Fetching Items");
  }
}

const updateQuantityAPI = async ({ itemId, level, quantity }) => {
  let response = {};
  try {
    response = await axios.post("/inventory/updateLevelQty", {
      item_id: itemId,
      level: `${level}`,
      quantity,
    });
  } catch (error) {
    return {
      message: error.message,
    };
  }
  return response.data;
};

function* updateItemLevelQuantityRequest(action) {
  const { payload } = action;
  try {
    const response = yield call(updateQuantityAPI, payload);
    if (response.success) {
      NotificationManager.listNotify.forEach(n =>
        NotificationManager.remove({ id: n.id })
      );
      NotificationManager.success(response.message);
    } else {
      NotificationManager.listNotify.forEach(n =>
        NotificationManager.remove({ id: n.id })
      );
      NotificationManager.error(response.message);
      return;
    }
  } catch (error) {
    NotificationManager.listNotify.forEach(n =>
      NotificationManager.remove({ id: n.id })
    );
    NotificationManager.error(error.message || "Error Fetching Items");
    return;
  }

  const { level, fetchLevel } = payload;
  if (level !== fetchLevel) return;
  try {
    const items = yield call(fetchItemsAPI, fetchLevel);
    yield put(fetchItemsSuccess(items));
  } catch (error) {
    console.log(error);
  }
}
const editWareHouse = async params => {
  let response = {};
  try {
    response = await axios.post(
      "/inventory/updateWarehouseItemDetails",
      params
    );
  } catch (error) {
    return {
      message: error.message,
    };
  }
  return response.data;
};
function* editWarehouseRequest(action) {
  try {
    const response = yield call(editWareHouse, action.payload);
    if (response.success) {
      NotificationManager.success(response.message);
    } else {
      NotificationManager.error(response.message);
      return;
    }
  } catch (error) {
    NotificationManager.error(error.message);
    return;
  }
}
export function* fetchItemsData() {
  yield takeLatest(FETCH_ITEMS, fetchItemsRequest);
  yield takeLatest(UPDATE_ITEM_LEVEL_QUANTITY, updateItemLevelQuantityRequest);
  yield takeLatest(EDIT_WAREHOUSE, editWarehouseRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchItemsData)]);
}
