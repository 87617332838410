import {
  OPEN_STATUS_UPDATE_MODAL,
  CLOSE_STATUS_UPDATE_MODAL,
  RESET_STATUS_UPDATE_MODAL,
  UPDATE_DELIVERY_JOB_STATUS_ERROR,
  CLOSE_UPDATE_DELIVERY_STATUS_NOTIFICATION,
  UPDATE_DELIVERY_JOB_STATUS_SUCCESS,
  UPDATE_JOB_STATUS_NOTE,
} from "../constants/ActionTypes.js";

const initialState = {
  open: false,
  error: "",
  message: "",
  status: "",
  showNotification: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_STATUS_UPDATE_MODAL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CLOSE_STATUS_UPDATE_MODAL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_DELIVERY_JOB_STATUS_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case RESET_STATUS_UPDATE_MODAL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_JOB_STATUS_NOTE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CLOSE_UPDATE_DELIVERY_STATUS_NOTIFICATION: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_DELIVERY_JOB_STATUS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
