import { all, call, fork, put, takeLeading } from "redux-saga/effects";
import { workForceAxios as axios } from "util/Api";
import { showAPIMessage } from "../actions/DeliveryAPI";
import { fetchDeliveryDashboardSuccess } from "../actions/DeliveryDashboardAPI";
import { FETCH_DELIVERY_DASHBOARD } from "../constants/ActionTypes";

/*
 * Get  delivery dashboard
 *
 */
function* fetchDeliveryDashboardRequest(action) {
  try {
    const deliveryDash = yield call(getDeliveryDashboard, action.payload);
    yield put(fetchDeliveryDashboardSuccess(deliveryDash));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getDeliveryDashboard = async function (params) {
  let deliveryDash = [];
  try {
    const response = await axios.get("/delivery/stats", {
      params,
    });
    deliveryDash = response.data;
  } catch (error) {
    console.log(error);
  }
  return deliveryDash;
};

function* fetchDeliveryDash() {
  yield takeLeading(FETCH_DELIVERY_DASHBOARD, fetchDeliveryDashboardRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchDeliveryDash)]);
}
