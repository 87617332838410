import { ALLOCATE_TO_EMPLOYEE, CONFIRM_NON_SERIALIZE_ITEM_RETURN, CONFIRM_RETURN_ITEM, FETCH_AVAILABLE_ITEMS, FETCH_AVAILABLE_ITEMS_ERROR, FETCH_AVAILABLE_ITEMS_SUCCESS, FETCH_EMPLOYEE_INVENTORY, FETCH_EMPLOYEE_INVENTORY_ERROR, FETCH_EMPLOYEE_INVENTORY_SUCCESS, REMOVE_NONPICKED_ITEMS, REMOVE_PICKED_ITEMS, SHOW_API_MESSAGE } from "../constants/ActionTypes";
import { ERROR, SUCCESS } from "../constants/uiStates";


export const fetchEmployeeInventory = (id) => {
  return {
    type: FETCH_EMPLOYEE_INVENTORY,
    payload: { id }
  }
}
export const fetchEmployeeInventorySuccess = inventory => {
  return {
    type: FETCH_EMPLOYEE_INVENTORY_SUCCESS,
    payload: {
      uiState: SUCCESS,
      inventoryData: inventory,
    },
  }
}
export const fetchEmployeeInventoryError = error => {
  return {
    type: FETCH_EMPLOYEE_INVENTORY_ERROR,
    payload: {
      uiState: ERROR,
      error,
    },
  }
}

export const fetchAvailableItems = () => {
  return {
    type: FETCH_AVAILABLE_ITEMS,
  }
}
export const fetchAvailableItemsSuccess = items => {
  return {
    type: FETCH_AVAILABLE_ITEMS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      items: items,
    }
  }
}

export const fetchAvailableItemsError = error => {
  return {
    type: FETCH_AVAILABLE_ITEMS_ERROR,
    payload: {
      uiState: ERROR,
      error,
    }
  }
}

export const allocateToEmployee = (payload) => {
  return {
    type: ALLOCATE_TO_EMPLOYEE,
    payload,
  }

}
export const removeNonPickedItems = (payload) => {
  return {
    type: REMOVE_NONPICKED_ITEMS,
    payload
  }
}

export const removePickedItems = (payload) => {
  return {
    type: REMOVE_PICKED_ITEMS,
    payload
  }
}

export const confirmReturnForItem = (payload) => {
  return {
    type: CONFIRM_RETURN_ITEM,
    payload
  }
}

export const confirmReturnForNonSerializableItem = (payload) => {
  return {
    type: CONFIRM_NON_SERIALIZE_ITEM_RETURN,
    payload
  }
}
export const showAPIMessage = message => {
  return {
    type: SHOW_API_MESSAGE,
    payload: message,
  };
};