import {
    FETCH_ITEMS,
    FETCH_ITEMS_ERROR,
    FETCH_ITEMS_SUCCESS,
    UPDATE_ITEM_LEVEL_QUANTITY,
    UPDATE_ITEM_LEVEL_QUANTITY_ERROR,
    EDIT_WAREHOUSE,
    EDIT_WAREHOUSE_ERROR,
} from 'constants/ActionTypes';

export const fetchItems = level => {
    return {
        type: FETCH_ITEMS,
        level,
    };
};

export const fetchItemsSuccess = payload => {
    return {
        type: FETCH_ITEMS_SUCCESS,
        payload,
    };
};

export const fetchItemsError = payload => {
    return {
        type: FETCH_ITEMS_ERROR,
        payload,
    };
};

export const updateItemLevelQuantity = payload => {
    return {
        type: UPDATE_ITEM_LEVEL_QUANTITY,
        payload,
    };
};

export const updateItemLevelQuantityError = payload => {
    return {
        type: UPDATE_ITEM_LEVEL_QUANTITY_ERROR,
        payload,
    };
};

export const editWarehouse = payload=>{
    return {
        type:EDIT_WAREHOUSE,
        payload
    }
}
export const editWarehouseError = payload=>{
    return {
        type:EDIT_WAREHOUSE_ERROR,
        payload
    }
}