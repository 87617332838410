import { FETCH_CUSTEMER_PLANS_ERROR, FETCH_CUSTOMER_PLANS_REQUEST, FETCH_CUSTOMER_PLANS_SUCCESS } from "constants/ActionTypes";
const INIT_STATE = {
    loading: false,
    data: {
        success: false,
        message: "",
        body: [],
        failCode: ""
    },
};
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_CUSTOMER_PLANS_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case FETCH_CUSTOMER_PLANS_SUCCESS: {
            return {
                ...state,
                loading: false,
                ...action.payload
            }
        }
        case FETCH_CUSTEMER_PLANS_ERROR: {
            return {
                ...state,
                loading: false,
                ...action.payload
            }
        }
        default:
            return state
    }
}