import { FETCH_ALL_TECHNICIANS,FETCH_ALL_TECHNICIANS_SUCCESS,SHOW_API_MESSAGE } from "constants/ActionTypes";
import { SUCCESS } from "constants/uiStates";



export const fetchTechnicians = payload => {
    return {
      type: FETCH_ALL_TECHNICIANS,
      payload,
    };
  };


  export const fetchTechniciansSuccess = employee => {
    return {
      type: FETCH_ALL_TECHNICIANS_SUCCESS,
      payload: employee,
    };
  };

export const showAPIMessage = message => {
    return {
      type: SHOW_API_MESSAGE,
      payload: message,
    };
  };