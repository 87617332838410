import React from "react";
import { Provider } from "react-redux";
import { HashRouter, Route, Switch } from "react-router-dom";
import App from "./containers/App";
import configureStore, { history } from "./store";

export const store = configureStore();

const MainApp = () => (
  <Provider store={store}>
    <HashRouter history={history}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </HashRouter>
  </Provider>
);

export default MainApp;
