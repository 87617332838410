import { FETCH_AVAILABLE_ITEMS_SUCCESS, FETCH_EMPLOYEE_INVENTORY_SUCCESS } from 'constants/ActionTypes';

const INIT_STATE = {
    inventoryData: {
        part_info: [],
        return_info: [],
        employee_first_name: "",
        employee_last_name: "",
        not_picked_up: 0,
        parts_quantity: 0
    },
    items: []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_EMPLOYEE_INVENTORY_SUCCESS:
            return {
                ...state,
                inventoryData: action.payload.inventoryData
            }
        case FETCH_AVAILABLE_ITEMS_SUCCESS:
            return {
                ...state,
                items: action.payload.items
            }
        default:
            return state;
    }
}