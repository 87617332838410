import {UPDATE_FIELD,UPDATE_FIELDS} from "constants/ActionTypes";

export const updateFields = payload => {
  return {
    type: `@ui/${UPDATE_FIELDS}`,
    payload,
  };
};

const updateField = (name, value) => {
  return {
    type: `@ui/${UPDATE_FIELD}`,
    payload: {
      name,
      value,
    },
  };
};

export const toggleAgentModal = updateField.bind(this, "agentModal");
export const toggleStatusModal = updateField.bind(this, "statusModal");
export const toggleZoneModal = updateField.bind(this, "zoneModal");
export const toggleAppointmentModal = updateField.bind(
  this,
  "appointmentModal"
);
export const toggleCommunicationModal = updateField.bind(
  this,
  "communicationModal"
);
export const togglePaymentModal = updateField.bind(this, "paymentModal");
export const togglePriorityModal = updateField.bind(this, "priorityModal");
export const toggleJobDetailsModal = updateField.bind(this, "jobDetailsModal");
export const toggleJobSlotModal = updateField.bind(this, "jobSlotModal");
export const toggleDeviceActivateAlertModal = updateField.bind(this, "deviceActivateAlertModal");
export const toggleJobHappyCodeModal = updateField.bind(this, "jobHappyCodeModal");
export const toggleRecordRCAModal = updateField.bind(this, "recordRCAModal");
export const toggleOldPickUpJobModal = updateField.bind(this, "oldPickUpJobModal");
export const toggleInitialPaymentsModal = updateField.bind(this, "initialPaymentsModal");
export const toggleAddPaymentToBeCollectedModal = updateField.bind(this, "addPaymentToBeCollectedModal");
export const toggleNewPickUpJobModal = updateField.bind(this, "newPickUpJobModal");
export const toggleReDeliveryJobModal = updateField.bind(this, "reDeliveryJobModal");
export const toggleAddPaymentWorkflowModal = updateField.bind(this, "addPaymentWorkflowModal");
export const toggleGeneratePaymentLinkModal = updateField.bind(this, "generatePaymentLinkModal");
export const toggleWorkflowDataModal = updateField.bind(this, "workflowDataModal");
export const toggleAddNotesModal = updateField.bind(this, "addNotesModal");
export const toggleEditCustomerModal = updateField.bind(this, "editCustomerModal");
export const toggleEditCustomerAddressModal = updateField.bind(this, "editCustomerAddressModal");
export const toggleRequestNewPrimeModal = updateField.bind(this, "requestNewPrimeModal");
export const toggleAddPaymentModal = updateField.bind(this, "addPaymentModal");
export const toggleApplyPlanModal = updateField.bind(this, "applyPlanModal");
export const toggleUpdatePriorityUserModal = updateField.bind(this, "updatePriorityUser");
export const toggleUpdateNonPayingCustomerModal = updateField.bind(this, "markCustomerNonPaying");
export const toggleUpdateBotCustomerModal = updateField.bind(this, "updateCustomerBot");
export const toggleDeviceReplacement = updateField.bind(this, "deviceReplacement");
export const toggleSchemeModal = updateField.bind(this, "schemeModal");
export const toggleAdhocEmployeePaymentModal = updateField.bind(this, "employeeAdhocPayment");
export const toggleSetDefaultPasswordModal = updateField.bind(this, "setDefaultPasswordModal");
export const toggleChangePlanAtDeliveryModal = updateField.bind(this,"changePlanAtDeliveryModal");
