import { FETCH_COUPON_FAILURE, FETCH_COUPON_REQUEST, FETCH_COUPON_SUCCESS } from "constants/ActionTypes"

const initalState = {
    body:[],
    loader:false,
    success:false,
    message:""
}

export default (state=initalState,action) =>{
    switch(action.type){
        case FETCH_COUPON_REQUEST:{
           return {
            loader:true
           }
        }
        case FETCH_COUPON_SUCCESS:{
            return {
                ...action.payload,
                loader:false,
                success:true
            }
        }
        case FETCH_COUPON_FAILURE:{
           return{ 
            loader:false,
            error:true,
            ...action.payload
        }
        }
        default:{
            return state;
        }
    }
}