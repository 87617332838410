import {
  fetchCouponError,
  fetchCouponSuccess,
    fetchDeviceError, fetchDevicesSuccess,

    fetchDeviceSuccess, fetchPlanSuccess, showAPIMessage
} from "actions/DeviceActions";
import {
    ACTIVATE_DEVICE,


    APPLY_PLAN_FOR_INSTALLATION, FETCH_ALL_DEVICE,

    FETCH_COUPON_REQUEST,

    FETCH_DEVICE,
    FETCH_PLAN
} from "constants/ActionTypes";
import { NotificationManager } from "react-notifications";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { paymentAxios, workForceAxios as axios } from "util/Api";



const getDevices = async function (params) {
  let devices = [];
  try {
    const response = await axios.get("/device/list", { params });
    devices = response.data;
  } catch (error) {
    console.log(error);
  }
  return devices;
};

function* fetchDevicesRequest(action) {
  try {
    const fetchedDevice = yield call(getDevices, action.payload);
    yield put(fetchDevicesSuccess(fetchedDevice));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const activateDevice = async function (params) {
  try {
    // const response1 = await paymentAxios.post("/utils/plans/change", params);
    // if (response1.data.success) {
    const response = await axios.post("/device/code/" + params["deviceCode"] + "/activate");
    return response.data;
    // }
  } catch (error) {
    throw error;
  }
};

function* activateDeviceRequest(action) {
  try {
    const response = yield call(activateDevice, action.payload);

    if (response.success) {
      NotificationManager.success("Activate Device successfully");
    } else {
      NotificationManager.error("Failed: " + response.message,'', 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to activate device details");
  }
}
const applyPlan = async function (params) {
  let plan = {}
  try {
    const response = await paymentAxios.post("/payments/activate", params);
    plan = response.data;
    console.log(plan);
  } catch (error) {
    console.log(error);
    throw error;
  }
  return plan;
};

function* applyPlanRequest(action) {
  try {
    const response = yield call(applyPlan, action.payload);

    if (response.success) {
      NotificationManager.success("Plan applied successfully");
      // this.props.fetchPlansByInstallationId(this.props.installationId);
    } else {
      NotificationManager.error("Failed: " + response.message,'', 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to apply plan");
  }
}

function* fetchDeviceRequest(action) {
  try {
    const device = yield call(getDevice, {
      deviceId: action.payload.id,
    });
    yield put(fetchDeviceSuccess(device));
  } catch (error) {
    yield put(fetchDeviceError(error.message));
  }
}

const getDevice = async function (params) {
  let devices = [];
  try {
    const response = await axios.get("/device/" + params["deviceId"], {});
    devices = response.data;
  } catch (error) {
    console.log(error);
  }
  return devices;
};

function* fetchPlanRequest(action) {
  try {
    const device = yield call(getPlan);
    yield put(fetchPlanSuccess(device));
  } catch (error) {
    yield put(showAPIMessage(error.message));
  }
}

const getPlan = async function () {
  let plans = [];
  try {
    const response = await paymentAxios.get("/utils/plans/all");
    plans = response.data;
  } catch (error) {
    console.log(error);
  }
  return plans;
};

const getCoupon = async function(){
  let coupon ={};
  try{
    const response = await paymentAxios.get("discount/coupon");
    coupon = response.data;
  }
  catch(error){
    console.log(error);
    throw error;
  }
  return coupon;
}
function* fetchCouponRequest(action) {
  try {
    const coupon = yield call(getCoupon);
    yield put(fetchCouponSuccess(coupon));
  } catch (error) {
    yield put(fetchCouponError(error));
  }
}

export function* fetchDevicesData() {
  yield takeLatest(FETCH_ALL_DEVICE, fetchDevicesRequest);
  yield takeLatest(ACTIVATE_DEVICE, activateDeviceRequest);
  yield takeLatest(FETCH_DEVICE, fetchDeviceRequest);
  yield takeLatest(FETCH_PLAN, fetchPlanRequest);
  yield takeLatest(APPLY_PLAN_FOR_INSTALLATION, applyPlanRequest);
  yield takeLatest(FETCH_COUPON_REQUEST,fetchCouponRequest)

}

export default function* rootSaga() {
  yield all([fork(fetchDevicesData)]);
}
