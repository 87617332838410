import {
  FETCH_ALL_SPONSORS,
  FETCH_ALL_SPONSORS_SUCCESS,
  FETCH_INVESTMENTS_BY_SPONSOR_ID,
  FETCH_INVESTMENTS_BY_SPONSOR_ID_SUCCESS,
  FETCH_SPONSOR,
  FETCH_SPONSOR_CODES,
  FETCH_SPONSOR_CODES_SUCCESS,
  FETCH_SPONSOR_SUCCESS,
  FETCH_BANK_ACCOUNTS,
  FETCH_BANK_ACCOUNTS_SUCCESS,
  GENERATE_SPONSOR_CSV,
  GENERATE_SPONSOR_CSV_SUCCESS,
  FETCH_INVESTMENTS_BY_BANK_ACCOUNT,
  FETCH_INVESTMENTS_BY_BANK_ACCOUNT_SUCCESS,
  ADD_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT_SUCCESS,
  UPDATE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT_SUCCESS,
  SHOW_API_MESSAGE,
  FETCH_COMMANDS,
  FETCH_COMMANDS_SUCCESS,
} from "constants/ActionTypes";
import { SUCCESS } from "constants/uiStates";

export const fetchSponsors = payload => {
  return {
    type: FETCH_ALL_SPONSORS,
    loader: true,
    payload,
  };
};
export const fetchSponsorsSuccess = sponsors => {
  return {
    type: FETCH_ALL_SPONSORS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      sponsors: sponsors,
      loader: false,
    },
  };
};
export const fetchInvestments = payload => {
  return {
    type: FETCH_INVESTMENTS_BY_SPONSOR_ID,
    loader: true,
    payload,
  };
};
export const fetchInvestmentsSuccess = investments => {
  return {
    type: FETCH_INVESTMENTS_BY_SPONSOR_ID_SUCCESS,
    payload: {
      uiState: SUCCESS,
      investments: investments,
      loader: false,
    },
  };
};
export const fetchSponsor = payload => {
  return {
    type: FETCH_SPONSOR,
    loader: true,
    payload,
  };
};
export const fetchSponsorSuccess = sponsor => {
  return {
    type: FETCH_SPONSOR_SUCCESS,
    payload: {
      uiState: SUCCESS,
      sponsor: sponsor,
      loader: false,
    },
  };
};

export const fetchSponsorCodes = payload => {
  return {
    type: FETCH_SPONSOR_CODES,
    loader: true,
    payload,
  };
};

export const fetchSponsorCodesSuccess = codes => {
  return {
    type: FETCH_SPONSOR_CODES_SUCCESS,
    payload: {
      uiState: SUCCESS,
      codes: codes,
      loader: false,
    },
  };
};

export const generateSponsorCodeCSV = payload => {
  return {
    type: GENERATE_SPONSOR_CSV,
    loader: true,
    payload,
  };
};
export const generateSponsorCodeCSVSuccess = sponsor => {
  return {
    type: GENERATE_SPONSOR_CSV_SUCCESS,
    payload: {
      uiState: SUCCESS,
      sponsor: sponsor,
      loader: false,
    },
  };
};
export const fetchBankAccountsList = payload => {
  return {
    type: FETCH_BANK_ACCOUNTS,
    loader: true,
    payload,
  };
};
export const fetchBankAccountsListSuccess = bankAccounts => {
  return {
    type: FETCH_BANK_ACCOUNTS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      bankAccounts: bankAccounts,
      loader: false,
    },
  };
};
export const fetchCommand = payload => {
  return {
    type: FETCH_COMMANDS,
    loader: true,
    payload,
  };
};
export const fetchCommandSuccess = commands => {
  return {
    type: FETCH_COMMANDS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      commands: commands,
      loader: false,
    },
  };
};
export const fetchInvestmentsById = payload => {
  return {
    type: FETCH_INVESTMENTS_BY_BANK_ACCOUNT,
    loader: true,
    payload,
  };
};
export const fetchInvestmentsByIdSuccess = investments => {
  return {
    type: FETCH_INVESTMENTS_BY_BANK_ACCOUNT_SUCCESS,
    payload: {
      uiState: SUCCESS,
      investments: investments,
      loader: false,
    },
  };
};
export const addBankAccount = bankAccount => {
  return {
    type: ADD_BANK_ACCOUNT,
    payload: bankAccount,
  };
};
export const addBankAccountSuccess = message => {
  return {
    type: ADD_BANK_ACCOUNT_SUCCESS,
    payload: {
      type: "success",
      status: "",
      showNotification: true,
      message,
    },
  };
};
export const updateBankAccount = bankAccount => {
  return {
    type: UPDATE_BANK_ACCOUNT,
    payload: bankAccount,
  };
};
export const updateBankAccountSuccess = (result, message) => {
  return {
    type: UPDATE_BANK_ACCOUNT_SUCCESS,
    payload: {
      open: false,
      status: "",
      showNotification: true,
      type: "success",
      message,
    },
  };
};
export const showAPIMessage = error => {
  return {
    type: SHOW_API_MESSAGE,
    payload: {
      uiState: Error,
      error,
    },
  };
};
