import {
  OPEN_STATUS_UPDATE_MODAL,
  CLOSE_STATUS_UPDATE_MODAL,
  UPDATE_DELIVERY_JOB_STATUS,
  UPDATE_DELIVERY_JOB_STATUS_SUCCESS,
  UPDATE_DELIVERY_JOB_STATUS_ERROR,
  RESET_STATUS_UPDATE_MODAL,
  CLOSE_UPDATE_DELIVERY_STATUS_NOTIFICATION,
  ON_JOB_STATUS_CHANGE,
  ON_JOB_STATUS_NOTE_CHANGE,
  ON_JOB_STATUS_PAYTYPE_CHANGE,
  ON_JOB_STATUS_PAYAMOUNT_CHANGE,
} from "../constants/ActionTypes";

export const openStatusUpdateModal = () => {
  return {
    type: OPEN_STATUS_UPDATE_MODAL,
    payload: { open: true },
  };
};

export const closeStatusUpdateModal = () => {
  return {
    type: CLOSE_STATUS_UPDATE_MODAL,
    payload: {
      open: false,
      error: "",
      message: "",
      status: "",
      showNotification: false,
    },
  };
};

export const handleJobStatusChange = status => {
  return {
    type: ON_JOB_STATUS_CHANGE,
    payload: { status },
  };
};

export const resetJobStatusModal = () => {
  return {
    type: RESET_STATUS_UPDATE_MODAL,
    payload: {
      status: "",
      error: "",
      message: "",
      note: "",
      showNotification: false,
    },
  };
};

export const handleJobStatusNoteChange = note => {
  return {
    type: ON_JOB_STATUS_NOTE_CHANGE,
    payload: {
      note,
    },
  };
};

export const submitJobStatusUpdate = (id, status, note, payAmount, payType) => {
  return {
    type: UPDATE_DELIVERY_JOB_STATUS,
    payload: {
      jobId: id,
      payAmount,
      payType,
      note,
      status,
    },
  };
};
export const updateDeliveryJobStatusSuccess = (result, message) => {
  return {
    type: UPDATE_DELIVERY_JOB_STATUS_SUCCESS,
    payload: {
      open: false,
      status: "",
      showNotification: true,
      type: "success",
      message,
    },
  };
};

export const updateDeliveryJobStatusError = error => {
  return {
    type: UPDATE_DELIVERY_JOB_STATUS_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const closeDeliveryStatusNotification = () => {
  return {
    type: CLOSE_UPDATE_DELIVERY_STATUS_NOTIFICATION,
    payload: {
      showNotification: false,
      message: "",
      note: "",
    },
  };
};

export const handlePayTypeChange = payType => {
  return {
    type: ON_JOB_STATUS_PAYTYPE_CHANGE,
    payload: {
      open: true,
      payType,
    },
  };
};

export const handlePayAmountChange = payAmount => {
  return {
    type: ON_JOB_STATUS_PAYAMOUNT_CHANGE,
    payload: {
      open: true,
      payAmount,
    },
  };
};
