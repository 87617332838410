import {
  ADD_CUSTOMER,
  ADD_CUSTOMER_ERROR,
  ADD_CUSTOMER_SUCCESS,
  FETCH_ALL_CUSTOMER,
  FETCH_ALL_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_ERROR,
  FETCH_CUSTOMER_SUCCESS,
  REQUEST_NEW_PRIME,
  SHOW_API_MESSAGE,
  UPDATE_ADDRESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_BOT,
  UPDATE_CUSTOMER_NONPAYING,
  UPDATE_CUSTOMER_PRIORITY,
  UPDATE_CUSTOMER_STATUS,
  UPDATE_CUSTOMER_STATUS_ERROR,
  UPDATE_CUSTOMER_STATUS_SUCCESS,
  FETCH_CUSTOMER_ADDRESS,
  FETCH_CUSTOMER_ADDRESS_SUCCESS,
  FETCH_CUSTOMER_ADDRESS_ERROR,
  FETCH_PHP_PURIFIER_VALIDITY_DETAILS,
  FETCH_PHP_PURIFIER_VALIDITY_DETAILS_SUCCESS,
  FETCH_PHP_MONTHLY_INSTALLATIONS,
  FETCH_PHP_MONTHLY_INSTALLATIONS_SUCCESS,
  FETCH_PHP_MONTHLY_STATS,
  FETCH_PHP_MONTHLY_STATS_SUCCESS,
  FETCH_PHP_DAILY_INSTALLATIONS_DETAILS,
  FETCH_PHP_DAILY_INSTALLATIONS_DETAILS_SUCCESS,
  FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS,
  FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS_SUCCESS,
  FETCH_REWARDS,
  FETCH_REWARDS_SUCCESS,
  DEVICE_REPLACEMENT,
  DEVICE_REPLACEMENT_SUCCESS,
  MOVE_TO_UNINSTALL,
  FETCH_CUSTOMER_PLANS_SUCCESS,
  FETCH_CUSTOMER_PLANS_REQUEST,
} from "constants/ActionTypes";
import { SUCCESS } from "constants/uiStates";

export const fetchCustomer = id => {
  return {
    type: FETCH_CUSTOMER,
    payload: { id },
  };
};
export const fetchCustomerAddress = id => {
  return {
    type: FETCH_CUSTOMER_ADDRESS,
    payload: { id },
  };
};
export const fetchCustomers = payload => {
  return {
    type: FETCH_ALL_CUSTOMER,
    payload,
  };
};

export const addCustomer = customer => {
  return {
    type: ADD_CUSTOMER,
    payload: customer,
  };
};

export const updateCustomer = customer => {
  return {
    type: UPDATE_CUSTOMER,
    payload: customer,
  };
};
export const updateAddress = (id, installationId, payload) => {
  return {
    type: UPDATE_ADDRESS,
    payload: {
      id: id,
      installationId: installationId,
      params: payload,
    },
  };
};
export const moveToUninstalled = ({ id, formData }) => {
  return {
    type: MOVE_TO_UNINSTALL,
    payload: {
      id: id,
      body: formData,
    },
  };
};
export const requestNewDevice = (installationId, payload) => {
  return {
    type: REQUEST_NEW_PRIME,
    payload: {
      installationId: installationId,
      params: payload,
    },
  };
};

export const fetchCustomerAddressSuccess = address => {
  return {
    type: FETCH_CUSTOMER_ADDRESS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      address: address,
    },
  };
};

export const fetchCustomerAddressError = error => {
  return {
    type: FETCH_CUSTOMER_ADDRESS_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};
export const fetchCustomersSuccess = cust => {
  return {
    type: FETCH_ALL_CUSTOMER_SUCCESS,
    payload: cust,
  };
};

export const fetchCustomerSuccess = customer => {
  return {
    type: FETCH_CUSTOMER_SUCCESS,
    payload: {
      uiState: SUCCESS,
      customer: customer,
    },
  };
};

export const fetchCustomerError = error => {
  return {
    type: FETCH_CUSTOMER_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const addCustomerSuccess = message => {
  return {
    type: ADD_CUSTOMER_SUCCESS,
    payload: {
      status: "",
      showNotification: true,
      type: "success",
      message,
    },
  };
};

export const addCustomerError = error => {
  return {
    type: ADD_CUSTOMER_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const updateCustomerStatus = (id, payload) => {
  return {
    type: UPDATE_CUSTOMER_STATUS,
    payload: {
      customerId: id,
      payload,
    },
  };
};

export const updatePriorityUser = payload => {
  return {
    type: UPDATE_CUSTOMER_PRIORITY,
    payload: {
      payload,
    },
  };
};

export const markCustomerNonPaying = payload => {
  return {
    type: UPDATE_CUSTOMER_NONPAYING,
    payload: {
      payload,
    },
  };
};

export const updateCustomerBot = payload => {
  return {
    type: UPDATE_CUSTOMER_BOT,
    payload: {
      payload,
    },
  };
};

export const addCustomerCommunication = (id, payload) => {
  return {
    type: "ADD_CUSTOMER_COMMUNICATION",
    payload: {
      customerId: id,
      payload,
    },
  };
};

export const addPendingPayment = (id, payload) => {
  return {
    type: "ADD_PENDING_PAYMENT",
    payload: {
      customerId: id,
      payload,
    },
  };
};

export const showAPIMessage = message => {
  return {
    type: SHOW_API_MESSAGE,
    payload: message,
  };
};

export const updateCustomerStatusSuccess = (result, message) => {
  return {
    type: UPDATE_CUSTOMER_STATUS_SUCCESS,
    payload: {
      open: false,
      status: "",
      showNotification: true,
      type: "success",
      message,
    },
  };
};

export const updateCustomerStatusError = error => {
  return {
    type: UPDATE_CUSTOMER_STATUS_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};
export const deviceReplacement = payload => {
  return {
    type: DEVICE_REPLACEMENT,
    payload: {
      payload,
    },
  };
};

export const fetchPhpPurifierValidityDetails = payload => {
  return {
    type: FETCH_PHP_PURIFIER_VALIDITY_DETAILS,
    loader: true,
    payload,
  };
};
export const fetchPhpPurifierValidityDetailsSuccess = activePurifiers => {
  return {
    type: FETCH_PHP_PURIFIER_VALIDITY_DETAILS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      activePurifiers: activePurifiers,
      loader: false,
    },
  };
};
export const fetchPhpMonthlyInstallations = payload => {
  return {
    type: FETCH_PHP_MONTHLY_INSTALLATIONS,
    loader: true,
    payload,
  };
};
export const fetchPhpMonthlyInstallationsSuccess = monthlyInstallations => {
  return {
    type: FETCH_PHP_MONTHLY_INSTALLATIONS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      monthlyInstallations: monthlyInstallations,
      loader: false,
    },
  };
};
export const fetchPhpMonthlyStats = payload => {
  return {
    type: FETCH_PHP_MONTHLY_STATS,
    loader: true,
    payload,
  };
};
export const fetchPhpMonthlyStatsSuccess = monthlyStats => {
  return {
    type: FETCH_PHP_MONTHLY_STATS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      monthlyStats: monthlyStats,
      loader: false,
    },
  };
};
export const fetchRewards = payload => {
  return {
    type: FETCH_REWARDS,
    loader: true,
    payload,
  };
};
export const fetchRewardsSuccess = rewards => {
  return {
    type: FETCH_REWARDS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      rewards: rewards,
      loader: false,
    },
  };
};
export const fetchPhpDailyInstallationDetails = payload => {
  return {
    type: FETCH_PHP_DAILY_INSTALLATIONS_DETAILS,
    loader: true,
    payload,
  };
};
export const fetchPhpDailyInstallationDetailsSuccess = dailyInstallations => {
  return {
    type: FETCH_PHP_DAILY_INSTALLATIONS_DETAILS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      dailyInstallations: dailyInstallations,
      loader: false,
    },
  };
};
export const fetchPhpWeeklyInstallationDetails = payload => {
  return {
    type: FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS,
    loader: true,
    payload,
  };
};
export const fetchPhpWeeklyInstallationDetailsSuccess = weeklyInstallations => {
  return {
    type: FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      weeklyInstallations: weeklyInstallations,
      loader: false,
    },
  };
};

export const fetchCustopmerPlansListSuccess = data => {
  return {
    type: FETCH_CUSTOMER_PLANS_SUCCESS,
    payload: {
      data,
      loading: false
    }
  }
}
export const fetchCustopmerPlansList = data => {
  return {
    type: FETCH_CUSTOMER_PLANS_REQUEST,
    payload: {
      loading: true
    }
  }
}
