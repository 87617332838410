import { all } from "redux-saga/effects";
import areaAPISagas from "./AreaAPI";
import authSagas from "./Auth";
import customerAPISagas from "./CustomerAPI";
import deliveryAPISagas from "./DeliveryAPI";
import deliveryDashboardAPISagas from "./DeliveryDashboardAPI";
import deviceAPISagas from "./DeviceAPI";
import employeeAPISagas from "./EmployeeAPI";
import employeeInventoryAPISagas from './EmployeeInventoryAPI';
import paymentAPISagas from './InstallationAPI';
import itemsSagas from './ItemsAPI';
import slotZoneAPISagas from "./SlotZoneAPI";
import sponsorSagas from "./SponsorsAPI";
import technicianAPISagas from "./TechnicianAPI";
import newTechniciansSagas from "./TechniciansAPI";
import jobAPISagas from "./WorkforceAPI";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    customerAPISagas(),
    deliveryAPISagas(),
    deliveryDashboardAPISagas(),
    deviceAPISagas(),
    employeeAPISagas(),
    technicianAPISagas(),
    newTechniciansSagas(),
    areaAPISagas(),
    slotZoneAPISagas(),
    jobAPISagas(),
    paymentAPISagas(),
    employeeInventoryAPISagas(),
    sponsorSagas(),
    itemsSagas()
  ]);
}
