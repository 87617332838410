import {
  FETCH_ALL_SPONSORS,
  FETCH_ALL_SPONSORS_SUCCESS,
  FETCH_BANK_ACCOUNTS,
  FETCH_BANK_ACCOUNTS_SUCCESS,
  FETCH_COMMANDS,
  FETCH_COMMANDS_SUCCESS,
  FETCH_INVESTMENTS_BY_BANK_ACCOUNT_SUCCESS,
  FETCH_INVESTMENTS_BY_SPONSOR_ID,
  FETCH_INVESTMENTS_BY_SPONSOR_ID_SUCCESS,
  FETCH_SPONSOR,
  FETCH_SPONSOR_CODES,
  FETCH_SPONSOR_CODES_SUCCESS,
  FETCH_SPONSOR_SUCCESS,
  SHOW_API_MESSAGE,
} from "constants/ActionTypes";
const INIT_STATE = {
  sponsors: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_SPONSORS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_ALL_SPONSORS_SUCCESS: {
      return {
        ...state,
        sponsors: action.payload.sponsors,
        loader: false,
      };
    }
    case FETCH_INVESTMENTS_BY_SPONSOR_ID: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_INVESTMENTS_BY_SPONSOR_ID_SUCCESS: {
      return {
        ...state,
        investments: action.payload.investments,
        loader: false,
      };
    }

    case FETCH_SPONSOR: {
      return {
        ...state,
        loader: true,
      };
    }

    case FETCH_SPONSOR_SUCCESS: {
      return {
        ...state,
        sponsor: action.payload.sponsor,
        loader: false,
      };
    }

    case FETCH_SPONSOR_CODES: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_SPONSOR_CODES_SUCCESS: {
      return {
        ...state,
        codes: action.payload.codes,
        loader: false,
      };
    }
    case FETCH_BANK_ACCOUNTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_BANK_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        bankAccounts: action.payload.bankAccounts,
        loader: false,
      };
    }
    case FETCH_COMMANDS:{
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_COMMANDS_SUCCESS:{
      return {
        ...state,
        commands: action.payload.commands,
        loader: false,
      };
    }
    case FETCH_INVESTMENTS_BY_BANK_ACCOUNT_SUCCESS: {
      return {
        ...state,
        investments: action.payload.investments,
        loader: false,
      };
    }
    case SHOW_API_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }

    default:
      return state;
  }
};
