import {
  FETCH_ALL_CONFIG_FOR_A_SCHEME_SUCCESS,
  FETCH_ALL_EMPLOYEE_SUCCESS,
  FETCH_ALL_LEAVES_SUCCESS,
  FETCH_ALL_PERMISSIONS_SUCCESS,
  FETCH_ALL_ROLES_SUCCESS,
  FETCH_ALL_SCHEMES_SUCCESS,
  FETCH_CONFIG_BY_ID_SUCCESS,
  FETCH_DESTINATION_WAREHOUSE_SUCCESS,
  FETCH_EMPLOYEES_LEAVE_SUCCESS,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_PASSBOOK_ENTRIES_SUCCESS,
  FETCH_SOURCE_WAREHOUSE_SUCCESS,
  FETCH_TRANSIT_WAREHOUSE_SUCCESS,
  SHOW_API_MESSAGE,
} from "constants/ActionTypes";

const INIT_STATE = {
  employees: [],
  leaves: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        allEmployees: action.payload,
        employees: action.payload.employees,
        totalCount: action.payload.total,
        loader: false,
      };
    }
    case FETCH_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }
    case FETCH_DESTINATION_WAREHOUSE_SUCCESS: {
      return {
        ...state,
        destinationWarehouse: action.payload,
        loader: false,
      };
    }
    case FETCH_TRANSIT_WAREHOUSE_SUCCESS: {
      return {
        ...state,
        transitWarehouse: action.payload,
        loader: false,
      };
    }
    case FETCH_SOURCE_WAREHOUSE_SUCCESS: {
      return {
        ...state,
        sourceWarehouse: action.payload,
        loader: false,
      };
    }
    case FETCH_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }
    case FETCH_ALL_LEAVES_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }
    case FETCH_EMPLOYEES_LEAVE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }
    case FETCH_ALL_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        permissions: action.payload,
        loader: false,
      };
    }
    case FETCH_ALL_ROLES_SUCCESS: {
      return {
        ...state,
        permission: action.payload,
        loader: false,
      };
    }
    case FETCH_ALL_SCHEMES_SUCCESS: {
      return {
        ...state,
        schemes: action.payload,
        loader: false,
      };
    }
    case FETCH_ALL_CONFIG_FOR_A_SCHEME_SUCCESS: {
      return {
        ...state,
        configs: action.payload,
        loader: false,
      };
    }
    case FETCH_CONFIG_BY_ID_SUCCESS: {
      return {
        ...state,
        config: action.payload,
        loader: false,
      };
    }
    case FETCH_PASSBOOK_ENTRIES_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }
    case SHOW_API_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }

    default:
      return state;
  }
};
