import {fetchAvailableItemsError,fetchAvailableItemsSuccess,fetchEmployeeInventory,fetchEmployeeInventoryError,fetchEmployeeInventorySuccess,showAPIMessage} from "actions/EmployeeInventoryActions";
import {ALLOCATE_TO_EMPLOYEE,CONFIRM_NON_SERIALIZE_ITEM_RETURN,CONFIRM_RETURN_ITEM,FETCH_AVAILABLE_ITEMS,FETCH_EMPLOYEE_INVENTORY,REMOVE_NONPICKED_ITEMS,REMOVE_PICKED_ITEMS} from "constants/ActionTypes";
import {NotificationManager} from "react-notifications";
import {all,call,fork,put,takeLatest} from "redux-saga/effects";
import {workForceAxios as axios} from "util/Api";

// inventory
const fetchEmployeeInventoryf = async (params) => {
  let inventoryData = {
    part_info: [],
    return_info: [],
    employee_first_name: "",
    employee_last_name: ""
  };
  try {
    const response = await axios.get("/employee/inventory/" + params["id"]);
    inventoryData = { ...response.data };
  } catch (error) {
    console.log(error);
  }
  return inventoryData;
};

function* fetchEmployeeInventoryRequest(action) {
  try {
    const fetchedInventory = yield call(fetchEmployeeInventoryf, action.payload);
    yield put(fetchEmployeeInventorySuccess(fetchedInventory));
  } catch (error) {
    yield put(fetchEmployeeInventoryError(error.message));
  }
}

// available items for delivery agents
const fetchAvailableItems = async () => {
  let items = []
  try {
    let res = await axios.get('/inventory/getItemsForDeliveryAgent')
    items = res.data.body
    // console.log("items-->", items)
  } catch (error) {
    console.log(error)
  }
  return items;
}

function* fetchAvailableItemsRequest() {
  try {
    const fetchedItems = yield call(fetchAvailableItems)
    yield put(fetchAvailableItemsSuccess(fetchedItems))
  } catch (error) {
    yield put(fetchAvailableItemsError(error.message))
  }
}

// post request 
const allocateToEmployee = async (params) => {
  try {
    let res = await axios.post(`/inventory/allocateToEmployee`, { ...params })
    return res.data
  } catch (error) {
    throw error
  }
}
function* allocateToEmployeeRequest(action) {
  try {
    const response = yield call(allocateToEmployee, action.payload);
    yield all([put(fetchEmployeeInventory(action.payload["employee_id"]))]);
    if (response.success) {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.success("Allocated To Employee successfully");
    } else {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.error(`Failed:${response.message.includes("NegativeStockError") ? "Allocated quantities for this item is not available in ERPNext." : response.message}`,'', 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
    NotificationManager.error("Failed to allocate");
  }
}
const removeNonPickedItems = async (params) => {
  try {
    let res = await axios.post(`/inventory/removeNonPickedUpItems`, { ...params })
    return res.data
  } catch (error) {
    throw error
  }
}
function* removeNonPickedItemsRequest(action) {
  try {
    const response = yield call(removeNonPickedItems, action.payload);
    yield all([put(fetchEmployeeInventory(action.payload["employee_id"]))]);
    if (response.success) {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.success("Item Removed successfully");
    } else {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.error("Failed: " + response.message,'', 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
    NotificationManager.error("Failed to remove item");
  }
}
const removePickedItems = async (params) => {
  try {
    let res = await axios.post(`/inventory/removePickedUpItems`, { ...params })
    return res.data
  } catch (error) {
    throw error
  }
}
function* removePickedItemsRequest(action) {
  try {
    const response = yield call(removePickedItems, action.payload);
    yield all([put(fetchEmployeeInventory(action.payload["employee_id"]))]);
    if (response.success) {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.success("Item Picked successfully");
    } else {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.error("Failed: " + response.message,'', 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
    NotificationManager.error("Failed to pick item");
  }
}

const confirmNonSerializeItemReturn = async (params) => {
  try {
    let res = await axios.post(`/inventory/confirmNonSerializeItemReturn`, { ...params })
    return res.data
  } catch (error) {
    throw error
  }
}
function* confirmNonSerializeItemReturnRequest(action) {
  try {
    const response = yield call(confirmNonSerializeItemReturn, action.payload);
    yield all([put(fetchEmployeeInventory(action.payload["employee_id"]))]);
    if (response.success) {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.success("Item Returned successfully");
    } else {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.error("Failed: " + response.message,'', 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
    NotificationManager.error("Failed to return item");
  }
}

const confirmReturnForItem = async (params) => {
  try {
    console.log("data--->", params)
    let res = await axios.post(`/inventory/confirmReturnForItem`, { ...params })
    return res.data
  } catch (error) {
    throw error
  }
}
function* confirmReturnForItemRequest(action) {
  try {
    const response = yield call(confirmReturnForItem, action.payload);
    yield all([put(fetchEmployeeInventory(action.payload["employee_id"]))]);
    if (response.success) {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.success("Item returned successfully");
    } else {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.error("Failed: " + response.message,'', 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
    NotificationManager.error("Failed to pick item");
  }
}
export function* fetchEmployeesInventoryData() {
  yield takeLatest(FETCH_EMPLOYEE_INVENTORY, fetchEmployeeInventoryRequest)
  yield takeLatest(FETCH_AVAILABLE_ITEMS, fetchAvailableItemsRequest)
  yield takeLatest(ALLOCATE_TO_EMPLOYEE, allocateToEmployeeRequest)
  yield takeLatest(REMOVE_NONPICKED_ITEMS, removeNonPickedItemsRequest)
  yield takeLatest(REMOVE_PICKED_ITEMS, removePickedItemsRequest)
  yield takeLatest(CONFIRM_RETURN_ITEM, confirmReturnForItemRequest)
  yield takeLatest(CONFIRM_NON_SERIALIZE_ITEM_RETURN, confirmNonSerializeItemReturnRequest)
}

export default function* rootSaga() {
  yield all([fork(fetchEmployeesInventoryData)]);
}
