import {
  FETCH_DELIVERY_DASHBOARD,
  FETCH_DELIVERY_DASHBOARD_ERROR,
  FETCH_DELIVERY_DASHBOARD_SUCCESS,
} from "constants/ActionTypes";
import { ERROR, SUCCESS } from "constants/uiStates";

export const fetchDeliveryDashboard = payload => {
  return {
    type: FETCH_DELIVERY_DASHBOARD,
    payload,
  };
};

export const fetchDeliveryDashboardSuccess = dashboard => {
  return {
    type: FETCH_DELIVERY_DASHBOARD_SUCCESS,
    payload: {
      uiState: SUCCESS,
      dashboard,
    },
  };
};

export const fetchDeliveryDashboardError = error => {
  return {
    type: FETCH_DELIVERY_DASHBOARD_ERROR,
    payload: {
      uiState: ERROR,
      error,
    },
  };
};
