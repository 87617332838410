import { fetchTechniciansSuccess, showAPIMessage } from "actions/TechnicianAction";
import { FETCH_ALL_TECHNICIANS } from "constants/ActionTypes";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { workForceAxios as axios } from "util/Api";

const getTechnicians = async function (params) {
  let technicians = [];
  try {
    const response = await axios.get("/employee/master/list?role=DeliveryPerson&active=true", { params });
    technicians = response.data;
    console.log('technicians--->', technicians)
  } catch (error) {
    console.log(error);
  }
  return technicians;
};

function* fetchTechniciansRequest(action) {
  try {
    const fetchedEmployee = yield call(getTechnicians, action.payload);
    yield put(fetchTechniciansSuccess(fetchedEmployee));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

export function* fetchTechiniciansData() {
  yield takeLatest(FETCH_ALL_TECHNICIANS, fetchTechniciansRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchTechiniciansData)]);
}
