import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const area = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
      <Route
        path={`${match.url}/list`}
        component={asyncComponent(() => import("./routes/List"))}
      />
      <Route
        path={`${match.url}/view/:id`}
        component={asyncComponent(() => import("./routes/View"))}
      />
      <Route
        path={`${match.url}/edit/:id`}
        component={asyncComponent(() => import("./routes/Edit"))}
      />
      <Route
        path={`${match.url}/add`}
        component={asyncComponent(() => import("./routes/Add"))}
      />
      <Route component={asyncComponent(() => import("components/Error404"))} />
    </Switch>
  </div>
);

export default area;
