import {
  RESET,

  UPDATE_CALENDAR_FIELD, UPDATE_FIELD,

  UPDATE_FIELDS
} from "constants/ActionTypes";

const MODULE = "@job/";
const INIT_STATE = {
  fields: {
    appointmentStartTime: null,
    appointmentEndTime: null,
    appointmentDate: null,
    appointmentDateEnd: null,
    note: "",
    status: null,
    priority: null,
    slotId: "",
    jobType: null,
    legacyJobTag: null,
    description: null,
    technicians: null,
    field: "",
    addPaymentToBeCollected: null,
    zoneFilter: [],
    cityFilter: [],
    oldDeviceCode: "",
    reason:""
  },
  calendarFields: {
    date: "",
    status: "",
    technician: [],
    cityFilter: []
  },
  selected: [],
  error: {},
  uiState: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_FIELD: {
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
      };
    }
    case UPDATE_CALENDAR_FIELD: {
      return {
        ...state,
        calendarFields: {
          ...state.calendarFields,
          [action.payload.name]: action.payload.value,
        },
      };
    }
    case UPDATE_FIELDS: {
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.payload,
        },
      };
    }
    case `${MODULE}${RESET}`: {
      return INIT_STATE;
    }

    default:
      return state;
  }
};
