import {
  ADD_INSTALLATION_NOTES, APPLY_DISCOUNT, APPLY_PLAN, APPLY_REFERRAL, CHANGE_PAYMENT_PLAN, CHANGE_PAYMENT_PLAN_ERROR, CHANGE_PAYMENT_PLAN_SUCCESS, CHECK_CONNECTION,
  CHECK_CONNECTION_ERROR,
  CHECK_CONNECTION_SUCCESS, DELETE_STATE, FETCH_ALL_INSTALLATIONS,
  FETCH_ALL_INSTALLATIONS_ERROR,
  FETCH_ALL_INSTALLATIONS_SUCCESS,
  FETCH_DP_CASH_TRANSACTION_HISTORY,
  FETCH_DP_CASH_TRANSACTION_HISTORY_SUCCESS,
  FETCH_INSTALLATION_DATA,
  FETCH_INSTALLATION_DATA_ERROR,
  FETCH_INSTALLATION_DATA_SUCCESS, FETCH_PAYMENT_DETAILS,
  FETCH_PAYMENT_DETAILS_SUCCESS, FETCH_PAYMENT_LINKS,
  FETCH_PAYMENT_LINKS_ERROR,
  FETCH_PAYMENT_LINKS_SUCCESS,
  FETCH_PAYMENT_LINK_TYPE,
  FETCH_PAYMENT_LINK_TYPE_ERROR, FETCH_PAYMENT_LINK_TYPE_SUCCESS, FETCH_PAYMENT_PLANS, FETCH_PAYMENT_PLANS_BY_ID, FETCH_PAYMENT_PLANS_BY_ID_ERROR, FETCH_PAYMENT_PLANS_BY_ID_SUCCESS, FETCH_PAYMENT_PLANS_ERROR, FETCH_PAYMENT_PLANS_SUCCESS, FETCH_PLANS_BY_INSTALLATION_ID,
  FETCH_PLANS_BY_INSTALLATION_ID_ERROR,
  FETCH_PLANS_BY_INSTALLATION_ID_SUCCESS,
  FETCH_PLANS_TO_BE_APPLIED_LIST,
  FETCH_PLANS_TO_BE_APPLIED_LIST_SUCCESS,
  FETCH_PURIFIER_DETAILS,
  FETCH_PURIFIER_DETAILS_ERROR,
  FETCH_PURIFIER_DETAILS_SUCCESS, FETCH_SYNC_HISTORY, FETCH_SYNC_HISTORY_SUCCESS, FETCH_SYNC_HISTORY_SUMMARY,
  FETCH_SYNC_HISTORY_SUMMARY_SUCCESS, FETCH_TICKETS_FOR_PURIFIER,
  FETCH_TICKETS_FOR_PURIFIER_ERROR,
  FETCH_TICKETS_FOR_PURIFIER_SUCCESS,
  FETCH_TICKET_DETAILS,
  FETCH_TICKET_DETAILS_ERROR,
  FETCH_TICKET_DETAILS_SUCCESS,
  FETCH_TRANSACTION_HISTORY,
  FETCH_TRANSACTION_HISTORY_ERROR,
  FETCH_TRANSACTION_HISTORY_SUCCESS,
  FETCH_WALLET_BALANCE,
  FETCH_WALLET_BALANCE_ERROR,
  FETCH_WALLET_BALANCE_SUCCESS, GENERATE_INVOICE, GENERATE_INVOICE_SUCCESS, GENERATE_PAYMENT_LINK,
  GENERATE_PAYMENT_LINK_ERROR,
  GENERATE_PAYMENT_LINK_SUCCESS,
  SHOW_API_MESSAGE,
  RECHARGE_B2B_PAYMENT_FAILURE,
  RECHARGE_B2B_PAYMENT_REQUESTED,
  RECHARGE_B2B_PAYMENT,
  RECHARGE_B2B_PAYMENT_SUCCESS,
  RECHARGE_B2B_PAYMENT_RESET,
  FETCH_PLAN_DETAILS_FROM_PLAN_NAME_AND_VALIDITY_FAILURE,
  FETCH_PLAN_DETAILS_FROM_PLAN_NAME_AND_VALIDITY_REQUEST,
  FETCH_PLAN_DETAILS_FROM_PLAN_NAME_AND_VALIDITY_SUCCESS
} from "constants/ActionTypes";
import { ERROR, SUCCESS } from "constants/uiStates";
export const showAPIMessage = message => {
  return {
    type: SHOW_API_MESSAGE,
    payload: message,
  };
};
export const addNotes = (installationId, note) => {
  return {
    type: ADD_INSTALLATION_NOTES,
    payload: {
      installationId: installationId,
      note: note,
    },
  };
};
export const applyPlan = payload => {
  return {
    type: APPLY_PLAN,
    payload: payload,
  };
};
export const applyDiscount = payload => {
  return {
    type: APPLY_DISCOUNT,
    payload: payload,
  };
};
export const applyReferral = payload => {
  return {
    type: APPLY_REFERRAL,
    payload: payload,
  };
};
export const fetchTicketsForPurifier = params => {
  return {
    type: FETCH_TICKETS_FOR_PURIFIER,
    payload: {
      id: params.purifierid,
      ...params,
    },
  };
};

export const fetchTicketsForPurifierSuccess = data => {
  return {
    type: FETCH_TICKETS_FOR_PURIFIER_SUCCESS,
    payload: {
      uiState: SUCCESS,
      data: data,
    },
  };
};

export const fetchTicketsForPurifierError = error => {
  return {
    type: FETCH_TICKETS_FOR_PURIFIER_ERROR,
    payload: {
      uiState: ERROR,
      error,
    },
  };
};

export const fetchTicketDetails = (ticketId, deviceId) => {
  return {
    type: FETCH_TICKET_DETAILS,
    payload: {
      id: ticketId,
      deviceId: deviceId,
    },
  };
};

export const fetchTicketDetailsSuccess = data => {
  return {
    type: FETCH_TICKET_DETAILS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      data: data,
    },
  };
};

export const fetchTicketDetailsError = error => {
  return {
    type: FETCH_TICKET_DETAILS_ERROR,
    payload: {
      uiState: ERROR,
      error,
    },
  };
};
export const fetchWalletBalance = installationId => {
  return {
    type: FETCH_WALLET_BALANCE,
    payload: {
      installationId: installationId,
    },
  };
};
export const fetchPlansByInstallationId = installationId => {
  return {
    type: FETCH_PLANS_BY_INSTALLATION_ID,
    payload: {
      installationId: installationId,
    },
  };
};
export const fetchWalletBalanceSuccess = walletData => {
  return {
    type: FETCH_WALLET_BALANCE_SUCCESS,
    payload: {
      uiState: SUCCESS,
      walletData: walletData,
    },
  };
};
export const fetchPlansByInstallationIdSuccess = plans => {
  return {
    type: FETCH_PLANS_BY_INSTALLATION_ID_SUCCESS,
    payload: {
      uiState: SUCCESS,
      plans: plans,
    },
  };
};
export const fetchWalletBalanceError = error => {
  return {
    type: FETCH_WALLET_BALANCE_ERROR,
    payload: {
      uiState: ERROR,
      error,
    },
  };
};
export const fetchPlansByInstallationIdError = error => {
  return {
    type: FETCH_PLANS_BY_INSTALLATION_ID_ERROR,
    payload: {
      uiState: ERROR,
      error,
    },
  };
};
export const fetchInstallations = payload => {
  return {
    type: FETCH_ALL_INSTALLATIONS,
    loader: true,
    payload,
  };
};
export const fetchPlansToBeApplied = payload => {
  return {
    type: FETCH_PLANS_TO_BE_APPLIED_LIST,
    loader: true,
    payload,
  };
};
export const fetchPlansToBeAppliedSuccess = installation => {
  return {
    type: FETCH_PLANS_TO_BE_APPLIED_LIST_SUCCESS,
    payload: {
      uiState: SUCCESS,
      installation: installation,
      loader: false,
    },
  };
};

export const fetchInstallationsSuccess = installation => {
  return {
    type: FETCH_ALL_INSTALLATIONS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      installation: installation,
      loader: false,
    },
  };
};

export const fetchInstallationError = error => {
  return {
    type: FETCH_ALL_INSTALLATIONS_ERROR,
    payload: {
      uiState: Error,
      error,
    },
  };
};

export const fetchInstallationDataById = id => {
  return {
    type: FETCH_INSTALLATION_DATA,
    payload: {
      id: id,
      loader: true,
    },
  };
};

export const fetchInstallationDataSuccess = installation => {
  return {
    type: FETCH_INSTALLATION_DATA_SUCCESS,
    payload: {
      installation: installation,
      uiState: SUCCESS,
      loader: true,
    },
  };
};

export const fetchSyncHistory = payload => {
  return {
    type: FETCH_SYNC_HISTORY,
    payload,
  };
};

export const fetchSyncHistorySuccess = syncHistory => {
  return {
    type: FETCH_SYNC_HISTORY_SUCCESS,
    payload: {
      syncHistory: syncHistory,
      uiState: SUCCESS,
      loader: false,
    },
  };
};

export const fetchSyncHistorySummary = payload => {
  return {
    type: FETCH_SYNC_HISTORY_SUMMARY,
    payload,
  };
};

export const fetchSyncHistorySummarySuccess = syncHistorySummary => {
  return {
    type: FETCH_SYNC_HISTORY_SUMMARY_SUCCESS,
    payload: {
      syncHistorySummary: syncHistorySummary,
      uiState: SUCCESS,
      loader: false,
    },
  };
};

export const fetchPaymentPlansById = id => {
  return {
    type: FETCH_PAYMENT_PLANS_BY_ID,
    payload: {
      id: id,
      loader: true,
    },
  };
};

export const fetchPaymentPlansByIdSuccess = paymentPlans => {
  return {
    type: FETCH_PAYMENT_PLANS_BY_ID_SUCCESS,
    payload: {
      paymentPlans: paymentPlans,
      uiState: SUCCESS,
      loader: false,
    },
  };
};

export const fetchPaymentPlansByIdError = error => {
  return {
    type: FETCH_PAYMENT_PLANS_BY_ID_ERROR,
    payload: {
      error: error,
      loader: true,
    },
  };
};

export const fetchPaymentDetails = payload => {
  return {
    type: FETCH_PAYMENT_DETAILS,
    payload,
  };
};

export const fetchPaymentDetailsSuccess = paymentDetails => {
  return {
    type: FETCH_PAYMENT_DETAILS_SUCCESS,
    payload: {
      paymentDetails: paymentDetails,
      uiState: SUCCESS,
      loader: false,
    },
  };
};

export const fetchInstallationDataError = error => {
  return {
    type: FETCH_INSTALLATION_DATA_ERROR,
    payload: {
      error: error,
      loader: true,
    },
  };
};

export const fetchTransactionHistory = params => {
  return {
    type: FETCH_TRANSACTION_HISTORY,
    payload: {
      params: params,
      loader: true,
    },
  };
};
export const fetchDpCashTransactionHistory = installation => {
  return {
    type: FETCH_DP_CASH_TRANSACTION_HISTORY,
    payload: {
      walletId: installation.walletId,
      type: installation.type,
      loader: true,
    },
  };
};
export const fetchTransactionHistorySuccess = (transactions, type) => {
  return {
    type: FETCH_TRANSACTION_HISTORY_SUCCESS,
    payload: {
      uiState: SUCCESS,
      transactions: transactions,
      type: type,
    },
  };
};
export const fetchDpCashTransactionHistorySuccess = (transactions, type) => {
  return {
    type: FETCH_DP_CASH_TRANSACTION_HISTORY_SUCCESS,
    payload: {
      uiState: SUCCESS,
      transactions: transactions,
      type: type,
    },
  };
};
export const fetchTransactionHistoryError = error => {
  return {
    type: FETCH_TRANSACTION_HISTORY_ERROR,
    payload: {
      uiState: ERROR,
      error,
    },
  };
};
export const fetchPurifierDetails = deviceId => {
  return {
    type: FETCH_PURIFIER_DETAILS,
    payload: {
      deviceId: deviceId,
      loader: true,
    },
  };
};

export const fetchPurifierDetailsSuccess = purifier => {
  return {
    type: FETCH_PURIFIER_DETAILS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      purifier: purifier,
    },
  };
};

export const fetchPurifierDetailsError = error => {
  return {
    type: FETCH_PURIFIER_DETAILS_ERROR,
    payload: {
      uiState: ERROR,
      error,
    },
  };
};

export const checkConnectivity = (botId, connectivity) => {
  return {
    type: CHECK_CONNECTION,
    payload: {
      botId,
      connectivity,
      loader: true,
    },
  };
};

export const checkConnectivitySuccess = connData => {
  return {
    type: CHECK_CONNECTION_SUCCESS,
    payload: {
      uiState: SUCCESS,
      connData: connData,
    },
  };
};

export const checkConnectivityError = error => {
  return {
    type: CHECK_CONNECTION_ERROR,
    payload: {
      uiState: ERROR,
      error,
    },
  };
};

export const generateLink = params => {
  return {
    type: GENERATE_PAYMENT_LINK,
    payload: {
      params: params,
      loader: true,
    },
  };
};
export const generateLinkSuccess = link => {
  return {
    type: GENERATE_PAYMENT_LINK_SUCCESS,
    payload: {
      link: link,
      loader: false,
    },
  };
};
export const generateInvoiceSuccess = link => {
  return {
    type: GENERATE_INVOICE_SUCCESS,
    payload: {
      success: true,
      loader: false,
    },
  };
};
export const generateLinkError = error => {
  return {
    type: GENERATE_PAYMENT_LINK_ERROR,
    payload: {
      error: error,
      loader: false,
    },
  };
};
export const fetchPaymentLinks = params => {
  return {
    type: FETCH_PAYMENT_LINKS,
    payload: {
      params: params,
      loader: true,
    },
  };
};

export const fetchPaymentLinksSuccess = link => {
  return {
    type: FETCH_PAYMENT_LINKS_SUCCESS,
    payload: {
      paymentLinks: link,
      loader: false,
    },
  };
};

export const fetchPaymentLinksError = error => {
  return {
    type: FETCH_PAYMENT_LINKS_ERROR,
    payload: {
      error: error,
      loader: false,
    },
  };
};

export const fetchPaymentPlans = purifier_id => {
  return {
    type: FETCH_PAYMENT_PLANS,
    payload: {
      purifier_id: purifier_id,
    },
  };
};

export const fetchPaymentPlansSuccess = plans => {
  return {
    type: FETCH_PAYMENT_PLANS_SUCCESS,
    payload: {
      plans: plans,
      loader: false,
    },
  };
};

export const fetchPaymentPlansError = error => {
  return {
    type: FETCH_PAYMENT_PLANS_ERROR,
    payload: {
      error: error,
      loader: false,
    },
  };
};

export const changePaymentPlan = params => {
  return {
    type: CHANGE_PAYMENT_PLAN,
    payload: {
      params: params,
      loader: true,
    },
  };
};

export const changePaymentPlanSuccess = plan => {
  return {
    type: CHANGE_PAYMENT_PLAN_SUCCESS,
    payload: {
      plan: plan,
      loader: false,
    },
  };
};

export const changePaymentPlanError = error => {
  return {
    type: CHANGE_PAYMENT_PLAN_ERROR,
    payload: {
      error: error,
      loader: false,
    },
  };
};

export const getPaymentLinkTypes = params => {
  return {
    type: FETCH_PAYMENT_LINK_TYPE,
    payload: {
      params: params,
      loader: true,
    },
  };
};

export const getPaymentLinkTypesSuccess = types => {
  return {
    type: FETCH_PAYMENT_LINK_TYPE_SUCCESS,
    payload: {
      types: types,
      loader: false,
    },
  };
};

export const getPaymentLinkTypesError = error => {
  return {
    type: FETCH_PAYMENT_LINK_TYPE_ERROR,
    payload: {
      error: error,
      loader: false,
    },
  };
};

export const deleteState = () => {
  return {
    type: DELETE_STATE,
  };
};


export const generateInvoice = (rowId, installationId) => {
  return {
    type: GENERATE_INVOICE,
    payload: {
      id: rowId,
      installationId: +installationId
    },
  };
};

export const updatePlanB2BPayment =(data) =>{
  return {
    type:RECHARGE_B2B_PAYMENT,
    payload:{data:{...data}}
  }
}
export const updatePlanB2BPaymentReset =() =>{
  return {
    type:RECHARGE_B2B_PAYMENT_RESET,
  }
}
export const updatePlanB2BPaymentRequested = (data)=>{
   return {
      type:RECHARGE_B2B_PAYMENT_REQUESTED,
      payload:{data:{...data}}
   }
}
export const updatePlanB2BPaymentSuccess = (data)=>{
  return {
     type:RECHARGE_B2B_PAYMENT_SUCCESS,
     payload:{data:{...data}}
  }
}
export const updatePlanB2BPaymentError = (data) =>{
  return{
    type:RECHARGE_B2B_PAYMENT_FAILURE,
    payload:{data:{...data}}
  }
}

export const  getPlanDetailsByPlanNameAndValidityRequest = (data)=>{
  return {
    type:FETCH_PLAN_DETAILS_FROM_PLAN_NAME_AND_VALIDITY_REQUEST,
    payload : {...data}
  }
}

export const  getPlanDetailsByPlanNameAndValiditySuccess = (data)=>{
  return {
    type:FETCH_PLAN_DETAILS_FROM_PLAN_NAME_AND_VALIDITY_SUCCESS,
    payload:{...data}
  }
}

export const getPlanDetailsByPlanNameAndValidityFailure=(data)=>{
return {
  type:FETCH_PLAN_DETAILS_FROM_PLAN_NAME_AND_VALIDITY_FAILURE
}
}