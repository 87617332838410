import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const workforce = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
      <Route
        path={`${match.url}/dashboard`}
        component={asyncComponent(() => import("./routes/Dashboard"))}
      />
      <Route
        path={`${match.url}/list`}
        component={asyncComponent(() => import("./routes/List"))}
      />
      <Route
        path={`${match.url}/job/:id/workflow`}
        component={asyncComponent(() => import("./routes/Job/components/WorkFlow"))}
      />
      <Route
        path={`${match.url}/job/:id`}
        component={asyncComponent(() => import("./routes/Job"))}
      />
      <Route
        path={`${match.url}/calendar`}
        component={asyncComponent(() => import("./routes/Calendar"))}
      />
      <Route
        path={`${match.url}/availableSlots`}
        component={asyncComponent(() => import("./routes/AvailableSlots"))}
      />
      <Route
        path={`${match.url}/technician`}
        component={asyncComponent(() => import("./../../routes/technician"))}
      />
      <Route
        path={`${match.url}/GSDueDays`}
        component={asyncComponent(() => import("./routes/GSDueDays"))}
      />
      <Route
        path={`${match.url}/RepeatJobs`}
        component={asyncComponent(() => import("./routes/RepeatJobs"))}
      />
      <Route
        path={`${match.url}/item/list`}
        component={asyncComponent(() => import("./routes/ItemsList"))}
      />
      <Route component={asyncComponent(() => import("components/Error404"))} />
    </Switch>
  </div>
);

export default workforce;
