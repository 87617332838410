import React from 'react';

const Footer = () => {
  return (
    <footer className="app-footer">
      <span className="d-inline-block">&copy; DrinkPrime.in 2020</span>
    </footer>
  );
}
  ;

export default Footer;
