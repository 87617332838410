import { RECHARGE_B2B_PAYMENT, RECHARGE_B2B_PAYMENT_FAILURE, RECHARGE_B2B_PAYMENT_REQUESTED, RECHARGE_B2B_PAYMENT_RESET, RECHARGE_B2B_PAYMENT_SUCCESS } from "constants/ActionTypes";

const INIT_STATE = {
    loading: false,
    data: {
        purifier_ids: [],
        payment_type: "",
        payment_mode: "AccountTransfer",
        plan: "CoupleB2B",
        payment_reference: "",
        amount: 0.0,
        validity:"",
        liters:0,
        recharge_period:"",
    },
    error: false,
};

export default (state = INIT_STATE, action ) => {
    switch (action.type) {
        case RECHARGE_B2B_PAYMENT:{
            return{...state,loading:false,error:false,...action.payload};
        }
        case RECHARGE_B2B_PAYMENT_REQUESTED: {
            return { ...state, loading: true,
            ...action.payload
            }
        }
        case RECHARGE_B2B_PAYMENT_SUCCESS: {
            return { 
                ...INIT_STATE ,
            }
        }
        case RECHARGE_B2B_PAYMENT_FAILURE: {
            return {  ...state,loading: false,error:true }
        }
        case RECHARGE_B2B_PAYMENT_RESET:{
             return {...INIT_STATE}
        }
        default:
            return state

    }
}