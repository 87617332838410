import { showAPIMessage } from "actions/CustomerActions";
import {
  fetchInvestmentsSuccess,
  fetchSponsorCodesSuccess,
  fetchSponsorsSuccess,
  fetchSponsorSuccess,
  generateSponsorCodeCSVSuccess,
  fetchBankAccountsListSuccess,
  fetchInvestmentsByIdSuccess,
  fetchBankAccountsList,
  fetchCommandSuccess,
} from "actions/SponsorActions";
import {
  FETCH_ALL_SPONSORS,
  FETCH_INVESTMENTS_BY_SPONSOR_ID,
  FETCH_SPONSOR,
  FETCH_SPONSOR_CODES,
  GENERATE_SPONSOR_CSV,
  FETCH_BANK_ACCOUNTS,
  FETCH_COMMANDS,
  FETCH_INVESTMENTS_BY_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT,
} from "constants/ActionTypes";

import { NotificationManager } from "react-notifications";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { paymentAxios, sponsorAxios as axios } from "util/Api";

const getSponsors = async function(params) {
  let sponsors = [];
  try {
    const response = await axios.get("/sponsor/", { params });
    sponsors = response.data;
  } catch (error) {
    console.log(error);
  }
  return sponsors.body;
};

function* fetchSponsorsRequest(action) {
  try {
    const fetchSponsors = yield call(getSponsors, action.payload);
    yield put(fetchSponsorsSuccess(fetchSponsors));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}
const getSponsor = async function(params) {
  let sponsors = [];
  try {
    const response = await axios.get("/sponsor/" + params);
    sponsors = response.data;
  } catch (error) {
    console.log(error);
  }
  return sponsors.body;
};

function* fetchSponsorRequest(action) {
  try {
    const fetchSponsors = yield call(getSponsor, action.payload);
    yield put(fetchSponsorSuccess(fetchSponsors));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getSponsorCodes = async function(params) {
  let codes = {};
  try {
    // const response = await axios.get(`/device?investmentId=${params}`);
    const response = await axios.get("/device?", { params });
    codes = { ...response.data.body };
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }

  return codes;
};

function* fetchSponsorCodeRequest(action) {
  try {
    const fetchSponsorCodes = yield call(getSponsorCodes, action.payload);
    yield put(fetchSponsorCodesSuccess(fetchSponsorCodes));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getInvestment = async function(params) {
  let investments = [];
  try {
    const response = await axios.get("/investment/", { params });
    investments = response.data;
  } catch (error) {
    console.log(error);
  }
  return investments.body;
};

function* fetchInvestmentRequest(action) {
  try {
    const fetchInvestments = yield call(getInvestment, action.payload);
    yield put(fetchInvestmentsSuccess(fetchInvestments));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getFile = async function(params) {
  axios
    .get("/investment/" + params + "/codes/", { responseType: "blob" })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "SponsorCode.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
};

function* fetchSponsorCodeFileRequest(action) {
  try {
    const downloadFile = yield call(getFile, action.payload);
    yield put(generateSponsorCodeCSVSuccess(downloadFile));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

export const handleGenerate = async investmentId => {
  try {
    const response = await axios.put(
      `/investment/${investmentId}/sponserCodes`
    );
    if (response.data.success) {
      NotificationManager.success(response.data.message);
    } else {
      NotificationManager.error(response.data.message);
    }
    return response.data.success;
  } catch (error) {
    console.error(error);
    NotificationManager.error(error.message);
    throw new Error(error);
  }
};

export const handleTransfer = async formData => {
  const { sourceInvestmentId, targetInvestmentId, numberOfDevices } = formData;
  try {
    const response = await axios.put(
      `/investment/${sourceInvestmentId}/transfer`,
      {
        sourceInvestmentId,
        targetInvestmentId,
        numOfDevices: numberOfDevices,
      }
    );
    if (response.data.success) {
      NotificationManager.success(response.data.message);
    } else {
      NotificationManager.error(response.data.message);
    }
    return response.data.success;
  } catch (error) {
    console.error(error);
    NotificationManager.error(error.message);
    throw new Error(error);
  }
};

export const resetCommands = async function(params) {
  try {
    const response = await axios.post("/device/benefits/reset", {
      deviceCode: params,
    });
    if (response.data.success) {
      NotificationManager.success("Reapply Commands Success");
    } else {
      NotificationManager.error(response.data.message);
    }
    return response.data.success;
  } catch (error) {
    NotificationManager.error(error.message);
    throw error;
  }
};

export const regenerateCommands = async function(params) {
  console.log(params);
  try {
    const response = await axios.post("/device/benefits/reapply", {
      deviceCode: params,
    });
    if (response.data.success) {
      NotificationManager.success("Regenerate Commands Success");
    } else {
      NotificationManager.error(response.data.message);
    }
    return response.data.success;
  } catch (error) {
    NotificationManager.error(error.message);
    throw error;
  }
};

export const addInvestment = async function(params) {
  try {
    const response = await axios.post("/investment", { ...params });
    if (response.data.success) {
      NotificationManager.success("Investment Added Successfully");
    } else {
      NotificationManager.error(response.data.message);
    }
    return response.data.success;
  } catch (error) {
    NotificationManager.error(error.message);
    throw error;
  }
};
export const addSponsor = async function(params) {
  try {
    const response = await axios.post("/sponsor", { ...params });
    if (response.data.success) {
      NotificationManager.success("Sponsor Added Successfully");
    } else {
      NotificationManager.error(response.data.message);
    }
    return response.data.success;
  } catch (error) {
    NotificationManager.error(error.message);
    throw error;
  }
};

function* fetchBankAccountsListRequest(action) {
  try {
    const downloadFile = yield call(getBankAccounts, action.payload);
    yield put(fetchBankAccountsListSuccess(downloadFile));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getBankAccounts = async function(params) {
  let bankAccounts = {};
  try {
    const response = await paymentAxios.get("/account", { ...params });
    bankAccounts = response.data;
  } catch (error) {
    console.log(error);
  }
  return bankAccounts;
};

function* fetchInvestmentsByIdRequest(action) {
  try {
    const downloadFile = yield call(getInvestmentsById, action.payload);
    yield put(fetchInvestmentsByIdSuccess(downloadFile));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getInvestmentsById = async function(params) {
  let investments = {};
  try {
    const response = await paymentAxios.get(`/account/${params.id}`, {
      ...params,
    });
    investments = response.data;
  } catch (error) {
    console.log(error);
  }
  return investments;
};
function* addBankAccountRequest(action) {
  try {
    const response = yield call(addBankAccount, action.payload);
    yield all([put(fetchBankAccountsList())]);
    if (response.success) {
      NotificationManager.success("Bank Account added successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error(error.message);
  }
}

const addBankAccount = async function(params) {
  try {
    const response = await paymentAxios.post("/account", { ...params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* updateBankAccountRequest(action) {
  try {
    const response = yield call(updateBankAccount, action.payload);
    yield all([put(fetchBankAccountsList())]);
    if (response.success) {
      NotificationManager.success("Bank Account updated successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error(error.message);
  }
}

const updateBankAccount = async function(params) {
  try {
    const response = await paymentAxios.post(`/account/${params.id}`, {
      ...params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* fetchCommandsRequest(action) {
  try {
    const commands = yield call(getCommands, action.payload);
    yield put(fetchCommandSuccess(commands.body));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getCommands = async function(params) {
  let commands = {};
  try {
    const response = await axios.get(`/device/command?onlyPending=false`, {
      params,
    });
    commands = response.data;
  } catch (error) {
    console.log(error);
  }
  return commands;
};

export function* fetchSponsorData() {
  yield takeLatest(FETCH_ALL_SPONSORS, fetchSponsorsRequest);
  yield takeLatest(FETCH_INVESTMENTS_BY_SPONSOR_ID, fetchInvestmentRequest);
  yield takeLatest(FETCH_SPONSOR, fetchSponsorRequest);
  yield takeLatest(FETCH_SPONSOR_CODES, fetchSponsorCodeRequest);
  yield takeLatest(GENERATE_SPONSOR_CSV, fetchSponsorCodeFileRequest);
  yield takeLatest(FETCH_BANK_ACCOUNTS, fetchBankAccountsListRequest);
  yield takeLatest(FETCH_COMMANDS, fetchCommandsRequest);
  yield takeLatest(
    FETCH_INVESTMENTS_BY_BANK_ACCOUNT,
    fetchInvestmentsByIdRequest
  );
  yield takeLatest(ADD_BANK_ACCOUNT, addBankAccountRequest);
  yield takeLatest(UPDATE_BANK_ACCOUNT, updateBankAccountRequest);
}
export default function* rootSaga() {
  yield all([fork(fetchSponsorData)]);
}
