import {
  FETCH_DELIVERY_AGENTS,
  FETCH_DELIVERY_AGENTS_SUCCESS,
  FETCH_DELIVERY_AGENTS_ERROR,
} from "../constants/ActionTypes";

import { SUCCESS, ERROR } from "../constants/uiStates";

export const fetchDeliveryAgents = () => {
  return {
    type: FETCH_DELIVERY_AGENTS,
    payload: {},
  };
};

export const fetchDeliveryAgentsSuccess = agents => {
  return {
    type: FETCH_DELIVERY_AGENTS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      agents,
    },
  };
};

export const fetchDeliveryAgentsError = error => {
  return {
    type: FETCH_DELIVERY_AGENTS_ERROR,
    payload: {
      uiState: ERROR,
      error,
    },
  };
};
