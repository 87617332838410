import { UPDATE_FIELD, RESET, UPDATE_FIELDS } from "constants/ActionTypes";

const MODULE = "@customerForm/";
const INIT_STATE = {
  fields: {
    note: "",
    commOn: null,
    commMode: "",
    commDetails: "",
    followUpDate: null,
    followUpPerson: null,
    result: null,
    message: null,
    status: "",
    assignedTo: null,
    payType: null,
    payAmount: null,
  },
  error: {},
  uiState: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_FIELD: {
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
      };
    }
    case "UPDATE_STATUS_FIELD": {
      return {
        ...state,
        fields: {
          ...state.fields,
          status: { code: action.payload.value },
        },
      };
    }
    case UPDATE_FIELDS: {
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.payload,
        },
      };
    }
    case `${MODULE}${RESET}`: {
      return INIT_STATE;
    }

    default:
      return state;
  }
};
