import {
  getUserSuccess, showAuthLoader,
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess
} from "actions/Auth";
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  USER_TOKEN_SET
} from "constants/ActionTypes";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { authGateway } from "util/Api";
import { FAIL } from "util/Constants";

// const signInUserRequest = async (email, password) =>
//   await axios
//     .post("login", {
//       username: email,
//       password: password,
//     })
//     .then(token => token)
//     .catch(error => error);
const signInAuthUserRequest = async (email, password) => await authGateway
  .post("login", {
    username: email,
    password: password,
  })
  .then(token => token)
  .catch(error => error);
const getUserRequest = async () =>
  await authGateway
    .post("auth/me")
    .then(authUser => authUser)
    .catch(error => error);

const signOutRequest = async () =>
  await authGateway
    .post("auth/logout")
    .then(authUser => authUser)
    .catch(error => error);


function* signIn({ payload }) {
  const { email, password } = payload;
  try {
    yield put(showAuthLoader());
    // const signInUser = yield call(signInUserRequest, email, password);
    const authGatewayUser = yield call(signInAuthUserRequest, email, password);
    if (authGatewayUser.status !== 200) {
      yield put(showAuthMessage("Unable to login. Contact Admin."));
    } else {
      // const token = signInUser.headers.authorization.substring(7);
      const authGatewayToken = authGatewayUser.headers.authorization.substring(7);
      localStorage.setItem("token", JSON.stringify(authGatewayToken));
      // localStorage.setItem("authGatewayToken", JSON.stringify(authGatewayToken));

      //axios.defaults.headers.common['access-token'] = signInUser.headers.authorization;
      yield put(userSignInSuccess(JSON.stringify(authGatewayToken)));
      // yield put(getUser());
    }
  } catch (error) {
    if (signInUser.message)
      yield put(showAuthMessage("Unable to login. " + signInUser.message));
    else yield put(showAuthMessage("Unable to login. Contact Admin."));
  }
}

function* getUsr() {
  try {
    const getUser = yield call(getUserRequest);
    if (getUser.status !== 200 || getUser.data === FAIL) {
      localStorage.removeItem("token");
      // localStorage.removeItem("authGatewayToken");
      yield put(userSignOutSuccess());
    } else {
      yield put(getUserSuccess(JSON.stringify(getUser.data)));
    }
  } catch (error) {
    localStorage.removeItem("token");
    // localStorage.removeItem("authGatewayToken");
    yield put(userSignOutSuccess());
  }
}
function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser.status !== 200) {
      localStorage.removeItem("token");
      // localStorage.removeItem("authGatewayToken");
      yield put(userSignOutSuccess());
    } else {
      // yield put(showAuthMessage("Successfully logged out")); //ASK-PRAS
      localStorage.removeItem("token");
      // localStorage.removeItem("authGatewayToken");
      yield put(userSignOutSuccess());
    }
  } catch (error) {
    localStorage.removeItem("token");
    // localStorage.removeItem("authGatewayToken");
    yield put(userSignOutSuccess());
  }
}

export function* signInUser() {
  yield takeLatest(SIGNIN_USER, signIn);
}

export function* getUser() {
  yield takeLatest(USER_TOKEN_SET, getUsr);
}

export function* signOutUser() {
  yield takeLatest(SIGNOUT_USER, signOut);
}


// export function* validateAuthorizationReq() {
//   console.log("hey")
//   yield takeLatest(AUTHORISED_USER, validateAuthorizationRequest);
// }

export default function* rootSaga() {
  yield all([fork(signInUser), fork(getUser), fork(signOutUser),
    // fork(validateAuthorizationReq)
  ]);
}
