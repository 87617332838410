import { ACTIVATE_DEVICE, APPLY_PLAN_FOR_INSTALLATION, FETCH_ALL_DEVICE, FETCH_ALL_DEVICE_SUCCESS, FETCH_COUPON_FAILURE, FETCH_COUPON_REQUEST, FETCH_COUPON_SUCCESS, FETCH_DEVICE, FETCH_DEVICE_ERROR, FETCH_DEVICE_SUCCESS, FETCH_PLAN, FETCH_PLAN_SUCCESS, SHOW_API_MESSAGE } from "constants/ActionTypes";
import { SUCCESS } from "constants/uiStates";

export const fetchDevice = id => {
  return {
    type: FETCH_DEVICE,
    payload: { id },
  };
};
export const fetchPlans = () => {
  return {
    type: FETCH_PLAN,
  }
}
export const fetchPlanSuccess = (plans) => {
  return {
    type: FETCH_PLAN_SUCCESS,
    payload: {
      uiState: SUCCESS,
      plans: plans,
    },
  }
}

export const fetchDevices = payload => {
  return {
    type: FETCH_ALL_DEVICE,
    payload,
  };
};

export const activateDevice = (device) => {
  return {
    type: ACTIVATE_DEVICE,
    payload: {
      "deviceCode": device
    },
  };
};

export const applyPlan = (installationId, plan) => {
  return {
    type: APPLY_PLAN_FOR_INSTALLATION,
    payload: {
      "installationId": installationId,
      "plan": plan
    },
  };
};
export const fetchDeviceSuccess = device => {
  return {
    type: FETCH_DEVICE_SUCCESS,
    payload: {
      uiState: SUCCESS,
      device: device,
    },
  };
};

export const fetchDeviceError = error => {
  return {
    type: FETCH_DEVICE_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const fetchDevicesSuccess = device => {
  return {
    type: FETCH_ALL_DEVICE_SUCCESS,
    payload: device,
  };
};

export const showAPIMessage = message => {
  return {
    type: SHOW_API_MESSAGE,
    payload: message,
  };
};

export const fetchCouponRequest = () => {
  return {
      type:FETCH_COUPON_REQUEST
  }
}
export const fetchCouponSuccess = (data) => {
  return {
    type: FETCH_COUPON_SUCCESS,
    payload: {
      ...data
    },
  }
}
export const fetchCouponError = (data)=>{
  return {
    type: FETCH_COUPON_FAILURE,
    payload: {
      ...data,
    },
  }
}
