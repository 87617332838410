import {
  OPEN_APPOINTMENT_UPDATE_MODAL,
  CLOSE_APPOINTMENT_UPDATE_MODAL,
  RESET_APPOINTMENT_UPDATE_MODAL,
  UPDATE_DELIVERY_JOB_APPOINTMENT,
  UPDATE_DELIVERY_JOB_APPOINTMENT_SUCCESS,
  UPDATE_DELIVERY_JOB_APPOINTMENT_ERROR,
} from "constants/ActionTypes";

export const openAppointmentUpdateModal = () => {
  return {
    type: OPEN_APPOINTMENT_UPDATE_MODAL,
    payload: {
      open: true,
    },
  };
};

export const closeAppointmentUpdateModal = () => {
  return {
    type: CLOSE_APPOINTMENT_UPDATE_MODAL,
    payload: {
      open: false,
      value: null,
      date: null,
      time: null,
      note: null,
      result: null,
      message: null,
    },
  };
};

export const resetAppointmentUpdateModal = () => {
  return {
    type: RESET_APPOINTMENT_UPDATE_MODAL,
    payload: {
      open: true,
      time: null,
      date: null,
      note: null,
      value: "",
      error: "",
    },
  };
};

export const submitAppointmentUpdate = (jobId, appointment, note) => {
  return {
    type: UPDATE_DELIVERY_JOB_APPOINTMENT,
    payload: {
      appt: appointment,
      jobId,
      note,
    },
  };
};

export const updateJobAppointmentSuccess = (result, message) => {
  return {
    type: UPDATE_DELIVERY_JOB_APPOINTMENT_SUCCESS,
    payload: {
      open: false,
      time: null,
      date: null,
      note: null,
      result,
      message,
    },
  };
};

export const updateJobAppointmentError = (result, message) => {
  return {
    type: UPDATE_DELIVERY_JOB_APPOINTMENT_ERROR,
    payload: {
      open: true,
      result,
      message,
    },
  };
};
