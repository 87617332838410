import { UPDATE_FIELDS, RESET } from "constants/ActionTypes";

const MODULE = "@filters/";
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${MODULE}${UPDATE_FIELDS}`:
      return {
        ...state,
        ...action.payload,
      };

    case `${MODULE}${RESET}`:
      return initialState;
    default:
      return state;
  }
};
