import {
  FETCH_DELIVERY_JOB_SUCCESS,
  FETCH_DELIVERY_JOB_ERROR,
  OPEN_STATUS_UPDATE_MODAL,
  CLOSE_STATUS_UPDATE_MODAL,
  SELECT_NEW_JOB_STATUS,
} from "../constants/ActionTypes";

import { LOADING } from "../constants/uiStates";

const initialState = {
  uiState: LOADING,
  job: {},
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DELIVERY_JOB_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case FETCH_DELIVERY_JOB_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case OPEN_STATUS_UPDATE_MODAL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CLOSE_STATUS_UPDATE_MODAL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SELECT_NEW_JOB_STATUS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
