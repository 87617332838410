import {
  FETCH_DELIVERY_DASHBOARD_ERROR,
  FETCH_DELIVERY_DASHBOARD_SUCCESS,
} from "../constants/ActionTypes";
import { LOADING } from "../constants/uiStates";

const initialState = {
  uiState: LOADING,
  job: {},
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DELIVERY_DASHBOARD_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case FETCH_DELIVERY_DASHBOARD_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
