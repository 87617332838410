import { SHOW_API_MESSAGE,FETCH_NEW_TECHNICIANS, FETCH_NEW_TECHNICIANS_SUCCESS } from "constants/ActionTypes";
import { SUCCESS } from "constants/uiStates";



export const fetchNewTechnicians = payload => {
    return {
      type: FETCH_NEW_TECHNICIANS,
      payload,
    };
  };


  export const fetchNewTechniciansSuccess = employee => {
    return {
      type: FETCH_NEW_TECHNICIANS_SUCCESS,
      payload: employee,
    };
  };

export const showAPIMessage = message => {
    return {
      type: SHOW_API_MESSAGE,
      payload: message,
    };
  };