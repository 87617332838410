import {
  ADD_SLOT_ZONE,
  ADD_SLOT_ZONE_ERROR,
  ADD_SLOT_ZONE_SUCCESS,
  FETCH_ALL_SLOT_ZONE,
  FETCH_ALL_SLOT_ZONE_SUCCESS,
  FETCH_DAYS,
  FETCH_DAYS_SUCCESS,
  FETCH_ISSUE_TYPES,
  FETCH_ISSUE_TYPES_SUCCESS,
  FETCH_SLOTS,
  FETCH_SLOTS_SUCCESS,
  FETCH_SLOT_COUNT_BY_ZONE_AND_ISSUE,
  FETCH_SLOT_COUNT_BY_ZONE_AND_ISSUE_SUCCESS,
  FETCH_SLOT_ZONE,
  FETCH_SLOT_ZONE_ERROR,
  FETCH_SLOT_ZONE_SUCCESS,
  SHOW_API_MESSAGE,
  UPDATE_SLOT_ZONE,
} from "constants/ActionTypes";
import { SUCCESS } from "constants/uiStates";

export const fetchSlotZone = id => {
  return {
    type: FETCH_SLOT_ZONE,
    payload: { id },
  };
};

export const fetchSlotZones = payload => {
  return {
    type: FETCH_ALL_SLOT_ZONE,
    payload,
  };
};

export const fetchIssueTypes = payload => {
  return {
    type: FETCH_ISSUE_TYPES,
    payload,
  };
};

export const fetchDays = payload => {
  return {
    type: FETCH_DAYS,
    payload,
  };
};

export const fetchSlots = payload => {
  return {
    type: FETCH_SLOTS,
    payload,
  };
};

export const addSlotZone = slotZone => {
  return {
    type: ADD_SLOT_ZONE,
    payload: slotZone,
  };
};

export const updateSlotZone = slotZone => {
  return {
    type: UPDATE_SLOT_ZONE,
    payload: slotZone,
  };
};

export const fetchSlotZoneSuccess = slotZone => {
  return {
    type: FETCH_SLOT_ZONE_SUCCESS,
    payload: {
      uiState: SUCCESS,
      slotZone: slotZone,
    },
  };
};

export const fetchSlotZoneError = error => {
  return {
    type: FETCH_SLOT_ZONE_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const addSlotZoneSuccess = message => {
  return {
    type: ADD_SLOT_ZONE_SUCCESS,
    payload: {
      status: "",
      showNotification: true,
      type: "success",
      message,
    },
  };
};

export const addSlotZoneError = error => {
  return {
    type: ADD_SLOT_ZONE_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const fetchSlotZonesSuccess = slotZone => {
  return {
    type: FETCH_ALL_SLOT_ZONE_SUCCESS,
    payload: slotZone,
  };
};

export const fetchIssueTypesSuccess = issueTypes => {
  return {
    type: FETCH_ISSUE_TYPES_SUCCESS,
    payload: issueTypes,
  };
};

export const fetchDaysSuccess = days => {
  return {
    type: FETCH_DAYS_SUCCESS,
    payload: days,
  };
};

export const fetchSlotsSuccess = slots => {
  return {
    type: FETCH_SLOTS_SUCCESS,
    payload: slots,
  };
};

export const fetchSlotCountByZoneAndIssue = slotCount => {
  return {
    type: FETCH_SLOT_COUNT_BY_ZONE_AND_ISSUE,
    payload: slotCount,
  };
};

export const fetchSlotCountByZoneAndIssueSuccess = slotCount => {
  return {
    type: FETCH_SLOT_COUNT_BY_ZONE_AND_ISSUE_SUCCESS,
    payload: slotCount,
  };
};

export const showAPIMessage = message => {
  return {
    type: SHOW_API_MESSAGE,
    payload: message,
  };
};
