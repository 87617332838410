import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";
// import DeliveryJob from './routes/Job';

const delivery = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
      <Route
        path={`${match.url}/dashboard`}
        component={asyncComponent(() => import("./routes/Dashboard"))}
      />
      <Route
        path={`${match.url}/list`}
        component={asyncComponent(() => import("./routes/List"))}
      />
      <Route
        path={`${match.url}/job/:id`}
        component={asyncComponent(() => import("./routes/Job"))}
      />
      <Route
        path={`${match.url}/calendar`}
        component={asyncComponent(() => import("./routes/Calendar"))}
      />
      <Route
        path={`${match.url}/persons-list`}
        component={asyncComponent(() => import("./routes/PersonsList"))}
      />
      <Route
        path={`${match.url}/details/:technicianId`}
        component={asyncComponent(() => import("./routes/Details"))}
      />
      <Route component={asyncComponent(() => import("components/Error404"))} />
    </Switch>
  </div>
);

export default delivery;
