import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const Technician = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
      <Route
        path={`${match.url}/list`}
        component={asyncComponent(() => import("./routes/List"))}
      />
      <Route
        path={`${match.url}/details/:technicianId`}
        component={asyncComponent(() => import("./routes/Details"))}
      />
      <Route component={asyncComponent(() => import("components/Error404"))} />
    </Switch>
  </div>
);

export default Technician;
