import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import {
  hideMessage,
  showAuthLoader,
  showAuthMessage,
  userSignIn,

} from "actions/Auth";
import React from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
    };
  }

  componentDidUpdate() {
    if (this.props.authUser !== null) {
        this.props.history.push("/");
       // setTimeout(() => {
       //
       // }, 500)
    }
  }

  onChange = e => {
    this.props.hideMessage();
    this.setState({ [e.target.name]: e.target.value });
  };

  onFormSubmit = e => {
    e.preventDefault();
    const { email, password } = this.state;
    this.props.showAuthLoader();
    this.props.userSignIn({ email, password });
  };

  render() {
    const { email, password } = this.state;
    const { showMessage, loader, alertMessage } = this.props;
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <h1>Welcome!</h1>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <Link className="logo-lg" to="/" title="DrinkPrime">
                <img
                  src={require("assets/images/drinkprime.png")}
                  alt="DrinkPrime"
                  title="DrinkPrime"
                />
              </Link>
            </div>

            <div className="app-login-form">
              <form onSubmit={this.onFormSubmit}>
                <fieldset>
                  <TextField
                    label="Username"
                    fullWidth
                    defaultValue={email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                    name="email"
                    onChange={this.onChange}
                  />
                  <TextField
                    type="password"
                    label="Password"
                    fullWidth
                    defaultValue={password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                    name="password"
                    onChange={this.onChange}
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button type="submit" variant="contained" color="primary">
                      Sign In
                    </Button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        {/* <InfoView/> */}

        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(
  mapStateToProps,
  {
    hideMessage,
    userSignIn,
    showAuthLoader,
    showAuthMessage,
  }
)(SignIn);
