import { RESET, UPDATE_FIELDS, UPDATE_FIELD } from "constants/ActionTypes";

const MODULE = "@ui/";
const initialState = {
  agentModal: false,
  statusModal: false,
  appointmentModal: false,
  communicationModal: false,
  paymentModal: false,
  priorityModal: false,
  jobDetailsModal: false,
  jobSlotModal: false,
  changePlanAtDeliveryModal:false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${MODULE}${UPDATE_FIELDS}`:
      return {
        ...state,
        ...action.payload,
      };
    case `${MODULE}${UPDATE_FIELD}`: {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }
    case `${MODULE}${RESET}`:
      return initialState;

    default:
      return state;
  }
};
