import MomentUtils from "@date-io/moment";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MainApp from "app/index";
import "assets/vendors/style";
import React, { Component } from "react";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import {appAxios, authGateway, customerAxios, paymentAxios, sponsorAxios, workForceAxios as axios} from "util/Api";
import asyncComponent from "util/asyncComponent";
import RTL from "util/RTL";
import { getUser, setInitUrl } from "../actions/Auth";
import AppLocale from "../lngProvider";
import SignIn from "./SignIn";
import defaultTheme from "./themes/defaultTheme";


const RestrictedRoute = ({ component: Component, token, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      token ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.token) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + nextProps.token;
      authGateway.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("token"));
      paymentAxios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("token"));
      sponsorAxios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("token"));
      customerAxios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("token"));
      appAxios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("token"));
    }
    if (nextProps.token && !nextProps.authUser) {
      this.props.getUser(nextProps.token);
    }
  }

  render() {
    const { match, location, locale, token, initURL } = this.props;
    if (location.pathname === "/") {
      if (token === null) {
        return <Redirect to={"/signin"} />;
      } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
        return <Redirect to={"/app/workforce/list"} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }

    const applyTheme = createMuiTheme(defaultTheme);

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <RTL>
              <div className="app-main">
                <Switch>
                  <RestrictedRoute
                    path={`${match.url}app`}
                    token={token}
                    component={MainApp}
                  />
                  <Route path="/signin" component={SignIn} />
                  <Route
                    component={asyncComponent(() =>
                      import("components/Error404")
                    )}
                  />
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { sideNavColor, locale, isDirectionRTL } = settings;
  const { authUser, token, initURL } = auth;
  return { sideNavColor, token, locale, isDirectionRTL, authUser, initURL };
};

export default connect(
  mapStateToProps,
  { setInitUrl, getUser }
)(App);
