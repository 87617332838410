import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const Sponsor = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Route
        path={`${match.url}/list`}
        component={asyncComponent(() => import("./routes/List"))}
      />
      <Route
        exact
        path={`${match.url}/bank-accounts`}
        component={asyncComponent(() => import("./routes/BankAccounts"))}
      />
      <Route
        path={`${match.url}/add`}
        component={asyncComponent(() => import("./routes/add"))}
      />
      <Route
        path={`${match.url}/investment`}
        component={asyncComponent(() => import("./routes/investment"))}
      />
      <Route
        path={`${match.url}/`}
        exact
        component={() => <Redirect to={`${match.url}/list`} />}
      />
      <Route component={asyncComponent(() => import("components/Error404"))} />
    </Switch>
  </div>
);

export default Sponsor;
