import { UPDATE_FIELD, RESET, UPDATE_FIELDS } from "constants/ActionTypes";

const MODULE = "@deliveryJob/";
const INIT_STATE = {
  fields: {
    value: null,
    date: null,
    time: null,
    note: "",
    result: null,
    message: null,
    status: "",
    payType: null,
    payAmount: null,
    agent: null,
  },
  error: {},
  uiState: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_FIELD: {
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
      };
    }
    case UPDATE_FIELDS: {
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.payload,
        },
      };
    }
    case `${MODULE}${RESET}`: {
      return INIT_STATE;
    }

    default:
      return state;
  }
};
