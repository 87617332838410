import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardBox from "components/CardBox/index";
import React, { Component } from "react";
import ChangePassword from "./components/ChangePassword";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div style={{ marginTop: "10px" }
            }>

                <CardBox styleName="col-lg-12"
                    cardStyle=" p-6"
                    headerOutside

                    heading="Profile">
                    <Accordion style={{ margin: "10px" }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        ><h1>Change Password</h1></AccordionSummary>
                        <AccordionDetails>
                            <ChangePassword></ChangePassword>
                        </AccordionDetails>
                    </Accordion>
                </CardBox>
            </div >);
    }
}

export default Profile;