import {
  FETCH_ALL_DELIVERY_JOBS,
  UPDATE_FIELD,
  UPDATE_FIELDS,
  UPDATE_CALENDAR_FIELD,
  RESET,
  CHANGE_PLAN_DURING_DELIVERY_REQUEST,
  CHANGE_PLAN_DURING_DELIVERY_SUCCESS,
  CHANGE_PLAN_DURING_DELIVERY,
  CHANGE_PLAN_DURING_DELIVERY_FAILURE,
  CHANGE_PLAN_DURING_DELIVERY_RESET,
} from "../constants/ActionTypes";

export const fetchDeliveryJobs = payload => {
  return {
    type: FETCH_ALL_DELIVERY_JOBS,
    payload,
  };
};

export const updateField = (name, value) => {
  return {
    type: UPDATE_FIELD,
    payload: {
      name,
      value,
    },
  };
};

export const updateCalendarField = (name, value) => {
  return {
    type: UPDATE_CALENDAR_FIELD,
    payload: {
      name,
      value,
    },
  };
};

export const updateMultipleFields = payload => {
  return {
    type: UPDATE_FIELDS,
    payload,
  };
};

export const resetFields = () => {
  return {
    type: `@deliveryJob/${RESET}`,
    payload: {},
  };
};

export const resetCustomerFields = () => {
  return {
    type: `@customerForm/${RESET}`,
    payload: {},
  };
};

export const resetWorkforceJobFields = () => {
  return {
    type: `@job/${RESET}`,
    payload: {},
  };
};

export const updatePlanAtDelivery = (data)=>{
  return {
    type:CHANGE_PLAN_DURING_DELIVERY,
    payload:data
  }
}
export const updatePlanAtDeliveryRequested = (data)=>{
  return {
    type:CHANGE_PLAN_DURING_DELIVERY_REQUEST,
    payload:data
  }
}
export const updatePlanAtDeliverySucess = ()=>{
  return {
    type:CHANGE_PLAN_DURING_DELIVERY_SUCCESS
  }
}
export const updatePlanAtDeliveryFailure = (data)=>{
  return {
    type:CHANGE_PLAN_DURING_DELIVERY_FAILURE,
    payload:data
  }
}
export const updatePlanAtDeliveryReset = ()=>{
  return {
    type:CHANGE_PLAN_DURING_DELIVERY_RESET
  }
}