import { CHANGE_PLAN_DURING_DELIVERY,
     CHANGE_PLAN_DURING_DELIVERY_FAILURE, 
     CHANGE_PLAN_DURING_DELIVERY_REQUEST, 
     CHANGE_PLAN_DURING_DELIVERY_RESET, 
     CHANGE_PLAN_DURING_DELIVERY_SUCCESS 
    } from "constants/ActionTypes";


const initialState = {
    plan: "",
    tenure: "",
    offerType: "",
    amountToBeCollected: 0,
    loader:false,
    error:false,
};

export default (state=initialState, action) => {
    switch (action.type) {
        case CHANGE_PLAN_DURING_DELIVERY:{
          return {
            ...state,
            ...action.payload
        }
        }
        case CHANGE_PLAN_DURING_DELIVERY_REQUEST:{
          return{
            loader:true
        }
        }
        case CHANGE_PLAN_DURING_DELIVERY_SUCCESS:{
          return{
            ...initialState
        }
        }
        case CHANGE_PLAN_DURING_DELIVERY_FAILURE:{
          return{
           ...action.payload,
            error:true,
            loader:false,
          }
        }
        case CHANGE_PLAN_DURING_DELIVERY_RESET:{
            return{
                ...initialState
            }
        }
        default: {
            return state
        }
    }
}