import { updateEmployee } from "actions/EmployeeActions";
import React, { Component } from "react";
import { NotificationContainer } from "react-notifications";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PasswordForm from "./passwordForm";

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    handleSubmit = values => {
        let user = this.props.authUser;
        let newUser = {
            "id": user["id"],
            "password": values.password
        }
        user["password"] = values.password;
        this.props.updateEmployee(newUser);
        this.props.history.push("/app/workforce/dashboard");
    }
    render() {
        return (
            <div>
                <PasswordForm onSubmit={this.handleSubmit}></PasswordForm>
                <NotificationContainer />

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authUser: state.auth.authUser
    };
};

const mapDispatchToProps = {
    updateEmployee,
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ChangePassword)
);