import {
  changePaymentPlanError,
  changePaymentPlanSuccess,
  checkConnectivitySuccess,
  fetchDpCashTransactionHistorySuccess,
  fetchInstallationDataById,
  fetchInstallationDataError,
  fetchInstallationDataSuccess,
  fetchInstallationError,
  fetchInstallationsSuccess,
  fetchPaymentDetailsSuccess,
  fetchPaymentLinksError,
  fetchPaymentLinksSuccess,
  fetchPaymentPlansByIdError,
  fetchPaymentPlansByIdSuccess,
  fetchPaymentPlansError,
  fetchPaymentPlansSuccess,
  fetchPlansByInstallationIdSuccess,
  fetchPlansToBeAppliedSuccess,
  fetchPurifierDetailsError,
  fetchPurifierDetailsSuccess,
  fetchSyncHistorySuccess,
  fetchSyncHistorySummarySuccess,
  fetchTicketDetailsError,
  fetchTicketDetailsSuccess,
  fetchTicketsForPurifierError,
  fetchTicketsForPurifierSuccess,
  fetchTransactionHistoryError,
  fetchTransactionHistorySuccess,
  fetchWalletBalanceError,
  fetchWalletBalanceSuccess, generateInvoiceSuccess, generateLinkError,
  generateLinkSuccess,
  getPaymentLinkTypesError, getPaymentLinkTypesSuccess, showAPIMessage, updatePlanB2BPaymentError, updatePlanB2BPaymentSuccess
} from "actions/PaymentActions";
import {
  ADD_INSTALLATION_NOTES,
  APPLY_DISCOUNT,
  APPLY_PLAN,
  APPLY_REFERRAL,
  CHANGE_PAYMENT_PLAN,
  CHECK_CONNECTION,
  FETCH_ALL_INSTALLATIONS,
  FETCH_DP_CASH_TRANSACTION_HISTORY,
  FETCH_INSTALLATION_DATA,
  FETCH_PAYMENT_DETAILS,
  FETCH_PAYMENT_LINKS,
  FETCH_PAYMENT_LINK_TYPE,
  FETCH_PAYMENT_PLANS,
  FETCH_PAYMENT_PLANS_BY_ID,
  FETCH_PLANS_BY_INSTALLATION_ID,
  FETCH_PLANS_TO_BE_APPLIED_LIST,
  FETCH_PURIFIER_DETAILS,
  FETCH_SYNC_HISTORY,
  FETCH_SYNC_HISTORY_SUMMARY,
  FETCH_TICKETS_FOR_PURIFIER,
  FETCH_TICKET_DETAILS,
  FETCH_TRANSACTION_HISTORY,
  FETCH_WALLET_BALANCE,
  GENERATE_INVOICE,
  GENERATE_PAYMENT_LINK,
  RECHARGE_B2B_PAYMENT_REQUESTED
} from "constants/ActionTypes";
import { NotificationManager } from "react-notifications";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  appAxios,
  paymentAxios,
  sponsorAxios,
  workForceAxios as axios
} from "util/Api";

const getWalletBalance = async function (params) {
  let walletData = {};
  try {
    //TODO use data + params.installationId
    const response = await paymentAxios.get(
      "/wallet/find?installation=" + +params.installationId
    );
    walletData = response.data;
  } catch (error) {
    console.log(error);
  }
  return walletData;
};

function* fetchWalletBalanceRequest(action) {
  try {
    const walletData = yield call(getWalletBalance, action.payload);
    yield put(fetchWalletBalanceSuccess(walletData));
  } catch (error) {
    yield put(fetchWalletBalanceError(error));
  }
}

const getPlansByInstallationId = async function (params) {
  console.log(params);
  let plans = [];
  try {
    //TODO use data + params.installationId
    const response = await paymentAxios.get(
      "/utils/topups/all?installationId=" + params["installationId"]
    );
    plans = response.data;
  } catch (error) {
    console.log(error);
  }
  return plans;
};

function* fetchPlansByInstallationId(action) {
  try {
    const plans = yield call(getPlansByInstallationId, action.payload);
    yield put(fetchPlansByInstallationIdSuccess(plans));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const fetchTransactionHistory = async function (params) {
  let transactions = [];
  try {
    const response = await paymentAxios.get("/wallet/entries", params);
    transactions = response.data;
  } catch (error) {
    console.log(error);
  }
  return transactions;
};

function* fetchTransactionHistoryRequest(action) {
  try {
    const transactions = yield call(fetchTransactionHistory, action.payload);
    yield put(
      fetchTransactionHistorySuccess(transactions, action.payload.type)
    );
  } catch (error) {
    yield put(fetchTransactionHistoryError(error));
  }
}
function* fetchDpCashTransactionHistoryRequest(action) {
  try {
    const transactions = yield call(fetchTransactionHistory, action.payload);
    yield put(
      fetchDpCashTransactionHistorySuccess(transactions, action.payload.type)
    );
  } catch (error) {
    yield put(fetchTransactionHistoryError(error));
  }
}
const getInstallations = async function (params) {
  let installations = [];
  try {
    const response = await axios.get("/installation/list", { params });
    installations = response.data;
  } catch (error) {
    console.log(error);
  }
  return installations;
};

function* fetchInstallationRequest(action) {
  try {
    const fetchInstallations = yield call(getInstallations, action.payload);
    yield put(fetchInstallationsSuccess(fetchInstallations));
  } catch (error) {
    yield put(fetchInstallationError(error));
  }
}

const getPlansToBeAppliedList = async function (params) {
  let installations = [];
  try {
    const response = await paymentAxios.get(
      "/payments/installations/inactivated",
      { params }
    );
    installations = response.data;
  } catch (error) {
    console.log(error);
  }
  return installations;
};

function* fetchPlansToBeAppliedList(action) {
  try {
    const plansToBeAppliedList = yield call(
      getPlansToBeAppliedList,
      action.payload
    );
    yield put(fetchPlansToBeAppliedSuccess(plansToBeAppliedList));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}
const getInstallationData = async function (params) {
  let installations = {};
  try {
    const response = await axios.get("/installation/" + params.id);
    installations = response.data;
  } catch (error) {
    console.log(error);
  }
  return installations;
};

function* fetchInstallationDataRequest(action) {
  try {
    const fetchInstallation = yield call(getInstallationData, action.payload);
    yield put(fetchInstallationDataSuccess(fetchInstallation));
  } catch (error) {
    yield put(fetchInstallationDataError(error));
  }
}
const getPurifierData = async function (params) {
  let purifier = {};
  try {
    const response = await sponsorAxios.get(
      "/device/details/" + params.deviceId
    );
    purifier = response.data.body;
  } catch (error) {
    console.log(error);
  }
  return purifier;
};
function* fetchPurifierDetailsRequest(action) {
  try {
    const fetchPurifier = yield call(getPurifierData, action.payload);
    yield put(fetchPurifierDetailsSuccess(fetchPurifier));
  } catch (error) {
    yield put(fetchPurifierDetailsError(error));
  }
}

const generatedLink = async function (params) {
  let link = {};
  try {
    const response = await paymentAxios.post("/payments/adhoc", params);
    link = { link: response.data ? response.data.url : null };
  } catch (error) {
    console.log(error);
  }
  return link;
};
function* generatePaymentLinkRequest(action) {
  try {
    const link = yield call(generatedLink, action.payload.params);
    yield put(generateLinkSuccess(link));
  } catch (error) {
    yield put(generateLinkError(error));
  }
}
const fetchPaymentLinks = async function (params) {
  let link = [];
  try {
    const response = await paymentAxios.get("/payments/adhoc/all", { params });
    link = response.data;
  } catch (error) {
    console.log(error);
  }
  return link;
};
function* fetchPaymentLinksRequest(action) {
  try {
    const link = yield call(fetchPaymentLinks, action.payload.params);
    yield put(fetchPaymentLinksSuccess(link));
  } catch (error) {
    yield put(fetchPaymentLinksError(error));
  }
}
const fetchTicketsForPurifier = async function (params) {
  let data = [];
  try {
    const response = await axios.post("/crm/viewTicketsForCustomer", {
      ...params,
    });
    data = response.data;
  } catch (error) {
    console.log(error);
  }
  return data;
};
function* fetchPurifierTicketsRequest(action) {
  try {
    const installation = yield call(getInstallationData, action.payload);
    const device = yield call(getPurifierData, installation);
    const data = yield call(fetchTicketsForPurifier, {
      purifierid: device.deviceCode,
      ...action.payload,
    });
    yield put(fetchTicketsForPurifierSuccess(data));
  } catch (error) {
    yield put(fetchTicketsForPurifierError(error));
  }
}
const fetchTicketsDetails = async function (params) {
  let data = {};
  try {
    const response = await axios.post("/crm/view/ticket", {
      purifier_id: params.purifierid,
      ticket_id: params.ticketId,
    });
    data = response.data;
  } catch (error) {
    console.log(error);
  }
  return data;
};
function* fetchTicketsDetailsRequest(action) {
  try {
    const device = yield call(getPurifierData, action.payload);
    const ticket = yield call(fetchTicketsDetails, {
      purifierid: device.deviceCode,
      ticketId: action.payload.id,
    });
    yield put(fetchTicketDetailsSuccess(ticket));
  } catch (error) {
    console.log(error);
    yield put(fetchTicketDetailsError(error));
  }
}
const fetchPaymentLinkType = async function () {
  let link = {};
  try {
    const response = await paymentAxios.get("/utils/adhoctypes ");
    link = response.data;
  } catch (error) {
    console.log(error);
  }
  return link;
};

function* fetchPaymentLinkTypeRequest(action) {
  try {
    const paymentTypes = yield call(fetchPaymentLinkType);
    yield put(getPaymentLinkTypesSuccess(paymentTypes));
  } catch (error) {
    yield put(getPaymentLinkTypesError(error));
  }
}

function* addInstallationNotesRequest(action) {
  try {
    const response = yield call(addNote, action.payload);
    yield all([put(fetchInstallationDataById(action.payload.installationId))]);
    if (response.success) {
      NotificationManager.success("Note added successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to add the note");
  }
}
const addNote = async function (params) {
  try {
    const response = await axios.post("/installation/addNote", {
      ...params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* applyPlanRequest(action) {
  try {
    const response = yield call(applyPlan, action.payload);
    if (response) {
      NotificationManager.success("Recharge applied successfully");
    } else {
      NotificationManager.error("Failed to apply the recharge");
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to apply the recharge");
  }
}
const applyPlan = async function (params) {
  try {
    const response = await paymentAxios.post("/payments/applyplan", {
      ...params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
// Fetching data from API on the basis of filters
export const apiResponse = async () => {
  try {
    const response = await paymentAxios.get(
      `/paymentDashboard/getValidity/list?pageSize=10&page=1`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const fullApiResponse = async () => {
  try {
    const fullResponse = await paymentAxios.get(
      `/paymentDashboard/getValidity/list`
    );
    return fullResponse.data;
  } catch (error) {
    throw error;
  }
};

export const searchDues = async (rowsPerPage, page, query) => {
  try {
    const searchResult = await paymentAxios.get(
      `/paymentDashboard/getValidity/list?pageSize=${rowsPerPage}&page=${page}&search=${query}`
    );
    return searchResult.data;
  } catch (err) {
    throw err;
  }
};

export const allCities = async () => {
  try {
    const citiesResponse = await axios.get(`/area/cities/all`);
    return citiesResponse.data;
  } catch (error) {
    throw error;
  }
};
export const allPlans = async () => {
  try {
    const planResponse = await paymentAxios.get(`/paymentDashboard/getPlan`);
    return planResponse.data;
  } catch (error) {
    throw error;
  }
};
export const fetchedData = async (
  rowsPerPage,
  page,
  startDueDate,
  endDueDate,
  city,
  plan
) => {
  try {
    const response = await paymentAxios.get(
      `/paymentDashboard/getValidity/list?pageSize=${rowsPerPage}&page=${page}&start=${startDueDate}&end=${endDueDate}&city=${city}&plan=${plan}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const checkConnectivity = async reqParams => {
  let connData = {};
  try {
    const conn = await sponsorAxios.post(`/device/life/conn-check`, {
      botId: reqParams.botId,
      connectivity: reqParams.connectivity,
    });
    connData = conn.data;
  } catch (err) {
    throw err;
  }
  return connData;
};

export const generateInvoice = async reqParams => {
  let response = {};
  try {
    const res = await paymentAxios.get(`/payments/generateInvoice?id=` + reqParams.id + "&installationId=" + reqParams.installationId);
    response = res.data;
  } catch (err) {
    throw err;
  }
  return response;
};
function* checkConnectivityRequest(action) {
  try {
    const connectionStatus = yield call(checkConnectivity, action.payload);
    yield put(checkConnectivitySuccess(connectionStatus));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

function* generateInvoiceRequest(action) {
  try {
    const status = yield call(generateInvoice, action.payload);
    yield put(generateInvoiceSuccess(status));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

export const updatePurifirerData = async reqBody => {
  let updateResponse = {};
  try {
    const res = await sponsorAxios.post(`/device/benefits/add`, {
      ...reqBody,
    });
    updateResponse = res.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
  return updateResponse;
};

export const allCustmersData = async (
  rowsPerPage,
  page,
  startDueDate,
  endDueDate,
  city,
  plan
) => {
  try {
    const response = await paymentAxios.get(
      `/paymentDashboard/download?pageSize=${rowsPerPage}&page=${page}&city=${city}&start=${startDueDate}&end=${endDueDate}&plan=${plan}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const sendEmails = async (
  rowsPerPage,
  page,
  startDueDate,
  endDueDate,
  city,
  plan
) => {
  try {
    const response = await paymentAxios.get(
      `/paymentDashboard/sendReminder?pageSize=${rowsPerPage}&page=${page}&city=${city}&start=${startDueDate}&end=${endDueDate}&plan=${plan}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDiscountDetails = async params => {
  let discount = {};
  try {
    const response = await paymentAxios.get(`/discount/coupon/${params}`);
    discount = response.data;
  } catch (error) {
    throw error;
  }
  return discount;
};

function* applyDiscountRequest(action) {
  try {
    const response = yield call(applyDiscount, action.payload);
    if (response.success) {
      NotificationManager.success(response.message);
    } else {
      NotificationManager.error(response.message);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error(error.message);
  }
}

const applyDiscount = async params => {
  try {
    const response = await paymentAxios.post(`/discount/coupon/apply`, {
      ...params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* applyReferralRequest(action) {
  try {
    const response = yield call(applyReferral, action.payload);
    if (response) {
      NotificationManager.success(response.message);
    } else {
      NotificationManager.error(response.message);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error(error.message);
  }
}

const applyReferral = async params => {
  try {
    const response = await paymentAxios.post(`/discount/referral/apply`, {
      ...params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getSyncHistory = async function (params) {
  let data = {};
  try {
    const response = await sponsorAxios.get("/device/details/syncs", {
      params,
    });
    data = response.data;
  } catch (error) {
    console.log(error);
  }
  return data;
};

function* fetchSyncHistoryRequest(action) {
  try {
    const fetchSyncHistory = yield call(getSyncHistory, action.payload);
    if (action.payload.pings !== undefined && action.payload.pings === true) {
      yield put(fetchSyncHistorySuccess({ fetchSyncHistory, pings: true }));
    } else {
      yield put(fetchSyncHistorySuccess({ fetchSyncHistory, pings: false }));
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error(error.message);
  }
}

const getSyncSummary = async function (params) {
  let data = {};
  try {
    const response = await sponsorAxios.get("/device/details/pings/summary", {
      params,
    });
    data = response.data;
  } catch (error) {
    console.log(error);
  }
  return data.body;
};

function* fetchSyncHistorySummaryRequest(action) {
  try {
    const fetchSummary = yield call(getSyncSummary, action.payload);
    yield put(fetchSyncHistorySummarySuccess(fetchSummary));
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error(error.message);
  }
}

export const fetchPlanData = async reqBody => {
  let response = {};
  try {
    const res = await appAxios.get(`/customer/userPlan?purifierId=${reqBody}`);
    response = res.data.body;
  } catch (err) {
    console.log(err);
    throw err;
  }
  return response;
};

const getPaymentDetails = async function (params) {
  let data = {};
  try {
    const response = await paymentAxios.get("/payments/payments", { params });
    data = response.data;
  } catch (error) {
    console.log(error);
  }
  return data;
};

function* fetchPaymentDetailsRequest(action) {
  try {
    const fetchPaymentDetails = yield call(getPaymentDetails, action.payload);
    yield put(fetchPaymentDetailsSuccess(fetchPaymentDetails.body));
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error(error.message);
  }
}
const fetchPaymentPlans = async function (params) {
  let res = [];
  try {
    const response = await paymentAxios.post("/plan/getAllPlansForUI", {
      params,
    });
    res = response.data;
  } catch (error) {
    console.log(error);
  }
  return res;
};
function* fetchPaymentPlansRequest(action) {
  try {
    const response = yield call(fetchPaymentPlans, action.payload);
    yield put(fetchPaymentPlansSuccess(response));
  } catch (error) {
    yield put(fetchPaymentPlansError(error));
  }
}

const changePaymentPlan = async function (params) {
  let res = {};
  try {
    const response = await paymentAxios.post(
      "/plan/changePlanFromUI",
      params.params
    );
    res = response.data;
  } catch (error) {
    console.log(error);
  }
  return res;
};

function* changePaymentPlanRequest(action) {
  try {
    const response = yield call(changePaymentPlan, action.payload);
    yield put(changePaymentPlanSuccess(response));
    if (response) {
      NotificationManager.success("Plan changed successfully");
    } else {
      NotificationManager.error(response.message);
    }
  } catch (error) {
    yield put(changePaymentPlanError(error));
    NotificationManager.error(error.message);
  }
}

const fetchPaymentPlansById = async function (params) {
  let res = {};
  const { id } = params;
  try {
    const response = await paymentAxios.get(
      `/utils/topups/all?installationId=${id}`
    );
    res = response.data;
  } catch (error) {
    console.log(error);
  }
  return res;
};

function* fetchPaymentPlansByIdRequest(action) {
  try {
    const response = yield call(fetchPaymentPlansById, action.payload);
    yield put(fetchPaymentPlansByIdSuccess(response));
  } catch (error) {
    yield put(fetchPaymentPlansByIdError(error));
  }
}

// B2Bpayment plan change request 
function* RequestRechargeB2BPayment(action) {
  try {
    const response = yield call(RechargeB2BPayment, action.payload);
    yield put(updatePlanB2BPaymentSuccess(response.data))
    NotificationManager.success(response.message);
  } catch (error) {
    yield put(updatePlanB2BPaymentError(error));
    NotificationManager.error("Failed to apply the recharge");
  }
}
const RechargeB2BPayment = async function (params) {
  try {
    const response = await paymentAxios.post("payments/B2BPayment", {
      ...params.data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export function* fetchPaymentData() {
  yield takeLatest(FETCH_WALLET_BALANCE, fetchWalletBalanceRequest);
  yield takeLatest(FETCH_PLANS_BY_INSTALLATION_ID, fetchPlansByInstallationId);
  yield takeLatest(FETCH_ALL_INSTALLATIONS, fetchInstallationRequest);
  yield takeLatest(FETCH_INSTALLATION_DATA, fetchInstallationDataRequest);
  yield takeLatest(FETCH_TRANSACTION_HISTORY, fetchTransactionHistoryRequest);
  yield takeLatest(FETCH_PURIFIER_DETAILS, fetchPurifierDetailsRequest);
  yield takeLatest(
    FETCH_DP_CASH_TRANSACTION_HISTORY,
    fetchDpCashTransactionHistoryRequest
  );
  yield takeLatest(GENERATE_PAYMENT_LINK, generatePaymentLinkRequest);
  yield takeLatest(FETCH_PAYMENT_LINKS, fetchPaymentLinksRequest);
  yield takeLatest(FETCH_PAYMENT_PLANS, fetchPaymentPlansRequest);
  yield takeLatest(ADD_INSTALLATION_NOTES, addInstallationNotesRequest);
  yield takeLatest(FETCH_TICKETS_FOR_PURIFIER, fetchPurifierTicketsRequest);
  yield takeLatest(FETCH_TICKET_DETAILS, fetchTicketsDetailsRequest);
  yield takeLatest(FETCH_PAYMENT_LINK_TYPE, fetchPaymentLinkTypeRequest);
  yield takeLatest(APPLY_PLAN, applyPlanRequest);
  yield takeLatest(APPLY_DISCOUNT, applyDiscountRequest);
  yield takeLatest(APPLY_REFERRAL, applyReferralRequest);
  yield takeLatest(FETCH_PLANS_TO_BE_APPLIED_LIST, fetchPlansToBeAppliedList);
  yield takeLatest(CHECK_CONNECTION, checkConnectivityRequest);
  yield takeLatest(FETCH_SYNC_HISTORY, fetchSyncHistoryRequest);
  yield takeLatest(FETCH_SYNC_HISTORY_SUMMARY, fetchSyncHistorySummaryRequest);
  yield takeLatest(FETCH_PAYMENT_DETAILS, fetchPaymentDetailsRequest);
  yield takeLatest(CHANGE_PAYMENT_PLAN, changePaymentPlanRequest);
  yield takeLatest(FETCH_PAYMENT_PLANS_BY_ID, fetchPaymentPlansByIdRequest);
  yield takeLatest(GENERATE_INVOICE, generateInvoiceRequest);
  yield takeLatest(RECHARGE_B2B_PAYMENT_REQUESTED,RequestRechargeB2BPayment)
}
export default function* rootSaga() {
  yield all([fork(fetchPaymentData)]);
}
