import {
  SUBMIT_ASSIGN_AGENT_MODAL,
  UPDATE_DELIVERY_AGENT_SUCCESS,
  UPDATE_DELIVERY_AGENT_ERROR,
} from "constants/ActionTypes";

export const submitAssignAgentModal = (jobIds, assignTo) => {
  return {
    type: SUBMIT_ASSIGN_AGENT_MODAL,
    payload: {
      open: true,
      jobIds,
      assignTo,
    },
  };
};

export const updateDeliveryJobAgentSuccess = result => {
  return {
    type: UPDATE_DELIVERY_AGENT_SUCCESS,
    payload: {},
  };
};

export const updateDeliveryJobAgentError = (result, message) => {
  return {
    type: UPDATE_DELIVERY_AGENT_ERROR,
    payload: {
      open: true,
      result,
      message,
    },
  };
};
