import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const slot = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Route
        path={`${match.url}/all/list`}
        component={asyncComponent(() => import("./allSlotRoutes"))}
      />
      <Route
        path={`${match.url}/default/list`}
        component={asyncComponent(() => import("./defaultSlotRoutes/List"))}
      />
      <Route
        path={`${match.url}/default/view/:id`}
        component={asyncComponent(() => import("./defaultSlotRoutes/View"))}
      />
      <Route
        path={`${match.url}/default/edit/:id`}
        component={asyncComponent(() => import("./defaultSlotRoutes/Edit"))}
      />
      <Route
        path={`${match.url}/default/add`}
        component={asyncComponent(() => import("./defaultSlotRoutes/Add"))}
      />
      <Route
        path={`${match.url}/custom/list`}
        component={asyncComponent(() => import("./customSlotRoutes/List"))}
      />
      <Route
        path={`${match.url}/custom/view/:id`}
        component={asyncComponent(() => import("./customSlotRoutes/View"))}
      />
      <Route
        path={`${match.url}/custom/edit/:id`}
        component={asyncComponent(() => import("./customSlotRoutes/Edit"))}
      />
      <Route
        path={`${match.url}/custom/add`}
        component={asyncComponent(() => import("./customSlotRoutes/Add"))}
      />
      <Route component={asyncComponent(() => import("components/Error404"))} />
    </Switch>
  </div>
);

export default slot;
