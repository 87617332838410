import {
  FETCH_ALL_DEVICE_SUCCESS,

  FETCH_DEVICE_SUCCESS,
  FETCH_PLAN_SUCCESS, SHOW_API_MESSAGE
} from "constants/ActionTypes";

const INIT_STATE = {
  devices: [],
  totalCount: 0,
  loader: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_DEVICE_SUCCESS: {
      return {
        ...state,
        allDevices: action.payload,
        devices: action.payload.devices,
        totalCount: action.payload.total,
        loader: false,
      };
    }
    case FETCH_DEVICE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }
    case FETCH_PLAN_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }
    case SHOW_API_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }

    default:
      return state;
  }
};
