import {
  UPDATE_DELIVERY_AGENT_SUCCESS,
  UPDATE_DELIVERY_AGENT_ERROR,
} from "../constants/ActionTypes";

import { LOADING } from "../constants/uiStates";

const initialState = {
  uiState: LOADING,
  open: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DELIVERY_AGENT_SUCCESS: {
      return initialState;
    }
    case UPDATE_DELIVERY_AGENT_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
