import { LOADING } from "../constants/uiStates";

import {
  FETCH_DELIVERY_AGENTS_SUCCESS,
  FETCH_DELIVERY_AGENTS_ERROR,
} from "../constants/ActionTypes";

const initialState = {
  uiState: LOADING,
  error: null,
  agents: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DELIVERY_AGENTS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case FETCH_DELIVERY_AGENTS_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
