import {
    FETCH_NEW_TECHNICIANS_SUCCESS,
    SHOW_API_MESSAGE,
  } from "constants/ActionTypes";
  
  const INIT_STATE = {
    newTechnicians: [],
  };

  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case FETCH_NEW_TECHNICIANS_SUCCESS: {
        console.log('new=>>',action.payload.employees)
        return {
          ...state,
          allNewTechnicians: action.payload,
          newTechnicians: action.payload.employees,
          totalCount: action.payload.total,
          loader: false,
        };
      }
      case SHOW_API_MESSAGE: {
        return {
          ...state,
          alertMessage: action.payload,
          showMessage: true,
          loader: false,
        };
      }
  
      default:
        return state;
    }
  };