import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
} from "constants/ActionTypes";

export const isAuthorised = (authUser, rights) => {
  let authState = false;
  if (!authUser || !authUser.roles)
    return false;
  let level = rights ? (rights.split("_")[1]) : 0;
  let module = rights ? (rights.split("_")[0]) : "";
  let rolesArray = authUser.roles.forEach(role => {
    if (role.role.name == module && role.permission.id >= level) {
      authState = true;
    }
  })
  return authState;
}

export const userSignIn = user => {
  return {
    type: SIGNIN_USER,
    payload: user,
  };
};
export const getUser = user => {
  return {
    type: USER_TOKEN_SET,
    payload: user,
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER,
  };
};
export const userSignInSuccess = token => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: token,
  };
};
export const getUserSuccess = user => {
  return {
    type: USER_DATA,
    payload: user,
  };
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  };
};
export const showAuthMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};
export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};
export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
