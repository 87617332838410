import {
  FETCH_ALL_DELIVERY_JOBS,
  FETCH_ALL_DELIVERY_JOBS_SUCCESS,
  SHOW_API_MESSAGE,
  FETCH_DELIVERY_JOB,
  FETCH_DELIVERY_JOB_SUCCESS,
  FETCH_DELIVERY_JOB_ERROR,
  SHOW_ALERT_MESSAGE,
  SELECT_ROW,
} from "constants/ActionTypes";

import { SUCCESS, ERROR } from "constants/uiStates";

export const fetchDeliveryJobs = payload => {
  return {
    type: FETCH_ALL_DELIVERY_JOBS,
    payload,
  };
};

export const fetchDeliveryJobsSuccess = deliJobs => {
  return {
    type: FETCH_ALL_DELIVERY_JOBS_SUCCESS,
    payload: deliJobs,
  };
};

export const showAPIMessage = message => {
  return {
    type: SHOW_API_MESSAGE,
    payload: message,
  };
};

export const fetchDeliveryJob = id => {
  return {
    type: FETCH_DELIVERY_JOB,
    payload: { id },
  };
};

export const fetchDeliveryJobSuccess = job => {
  return {
    type: FETCH_DELIVERY_JOB_SUCCESS,
    payload: {
      uiState: SUCCESS,
      job,
    },
  };
};

export const fetchDeliveryJobError = error => {
  return {
    type: FETCH_DELIVERY_JOB_ERROR,
    payload: {
      uiState: ERROR,
      error,
    },
  };
};

export const showAlertMessage = message => {
  return {
    type: SHOW_ALERT_MESSAGE,
    payload: {
      message,
    },
  };
};

export const handleSelect = payload => {
  return {
    type: SELECT_ROW,
    payload,
  };
};
