import {
  FETCH_ALL_DELIVERY_PERSON,
  FETCH_ALL_DELIVERY_PERSON_ERROR,
  FETCH_ALL_DELIVERY_PERSON_SUCCESS,
  FETCH_ALL_JOB,
  FETCH_ALL_JOB_SUCCESS,
  FETCH_ALL_REPEATED_JOBS,
  FETCH_ALL_REPEATED_JOBS_SUCCESS,
  FETCH_ALL_TEAMS,
  FETCH_ALL_TEAMS_SUCCESS,
  FETCH_AREAS_SUCCESS,
  FETCH_AREA_ZONES_BY_CITY,
  FETCH_AREA_ZONES_BY_CITY_SUCCESS,
  FETCH_AVAILABLE_SLOTS,
  FETCH_AVAILABLE_SLOTS_SUCCESS,
  FETCH_AVAILABLE_TECHNICIANS,
  FETCH_AVAILABLE_TECHNICIANS_ERROR,
  FETCH_AVAILABLE_TECHNICIANS_SUCCESS,
  FETCH_DEPENDENCIES,
  FETCH_DEPENDENCIES_SUCCESS,
  FETCH_JOBS_BY_INSTALLATION_ID,
  FETCH_JOBS_BY_INSTALLATION_ID_ERROR,
  FETCH_JOBS_BY_INSTALLATION_ID_SUCCESS,
  FETCH_JOB_IMAGES_SUCCESS,
  FETCH_JOB_SLOTS_SUCCESS,
  FETCH_JOB_STATUS,
  FETCH_JOB_STATUS_SUCCESS,
  FETCH_JOB_SUCCESS,
  FETCH_JOB_TAGS,
  FETCH_JOB_TAGS_SUCCESS,
  FETCH_JOB_TYPES_SUCCESS,
  FETCH_LAST_3_JOBS_BY_INSTALLATION,
  FETCH_LAST_3_JOBS_BY_INSTALLATION_ERROR,
  FETCH_LAST_3_JOBS_BY_INSTALLATION_SUCCESS,
  FETCH_TECHNICIANS,
  FETCH_TECHNICIANS_ERROR,
  FETCH_TECHNICIANS_JOB_SLOTS,
  FETCH_TECHNICIANS_JOB_SLOTS_ERROR,
  FETCH_TECHNICIANS_JOB_SLOTS_SUCCESS,
  FETCH_TECHNICIANS_SUCCESS,
  RESET_ZONES,
  SHOW_API_MESSAGE,
  UPDATE_ELEMENT_ADD_TEXT,
  UPDATE_ELEMENT_ADD_TEXT_ERROR,
  UPDATE_ELEMENT_ADD_TEXT_SUCCESS,
  UPDATE_GS_THRESHOLD_LIMITS,
  UPDATE_GS_THRESHOLD_LIMITS_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  repeatedJobs: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_JOB: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_ALL_JOB_SUCCESS: {
      return {
        ...state,
        allJobs: action.payload,
        jobs: action.payload.jobs,
        totalCount: action.payload.total,
        loader: false,
      };
    }
    case FETCH_JOB_SLOTS_SUCCESS: {
      return {
        ...state,
        slots: action.payload,
        loader: false,
      };
    }
    case FETCH_AREAS_SUCCESS: {
      return {
        ...state,
        areas: action.payload,
        loader: false,
      };
    }
    case FETCH_JOB_TYPES_SUCCESS: {
      return {
        ...state,
        jobTypes: action.payload,
        loader: false,
      };
    }
    case FETCH_JOB_IMAGES_SUCCESS: {
      return {
        ...state,
        jobImages: action.payload,
        loader: false,
      };
    }
    case FETCH_TECHNICIANS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_TECHNICIANS_SUCCESS: {
      return {
        ...state,
        technicians: action.payload,
        loader: false,
      };
    }
    case FETCH_TECHNICIANS_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case FETCH_TECHNICIANS_JOB_SLOTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_TECHNICIANS_JOB_SLOTS_SUCCESS: {
      return {
        ...state,
        techJobSlots: action.payload,
        loader: false,
      };
    }
    case FETCH_TECHNICIANS_JOB_SLOTS_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case FETCH_AVAILABLE_TECHNICIANS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_AVAILABLE_TECHNICIANS_SUCCESS: {
      return {
        ...state,
        technicians: action.payload,
        loader: false,
      };
    }
    case FETCH_AVAILABLE_TECHNICIANS_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case FETCH_ALL_DELIVERY_PERSON: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_ALL_DELIVERY_PERSON_SUCCESS: {
      return {
        ...state,
        technicians: action.payload,
        loader: false,
      };
    }
    case FETCH_ALL_DELIVERY_PERSON_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case FETCH_LAST_3_JOBS_BY_INSTALLATION: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_LAST_3_JOBS_BY_INSTALLATION_SUCCESS: {
      return {
        ...state,
        jobs: action.payload,
        loader: false,
      };
    }
    case FETCH_LAST_3_JOBS_BY_INSTALLATION_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case FETCH_JOB_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }
    case FETCH_JOB_TAGS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_JOB_TAGS_SUCCESS: {
      return {
        ...state,
        tags: action.payload,
        loader: false,
      };
    }
    case FETCH_JOB_STATUS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_JOB_STATUS_SUCCESS: {
      return {
        ...state,
        status: action.payload,
        loader: false,
      };
    }
    case FETCH_AVAILABLE_SLOTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_AVAILABLE_SLOTS_SUCCESS: {
      return {
        ...state,
        availableSlots: action.payload,
        loader: false,
      };
    }
    case FETCH_ALL_TEAMS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_ALL_TEAMS_SUCCESS: {
      return {
        ...state,
        teams: action.payload.teams,
        loader: false,
      };
    }
    case UPDATE_ELEMENT_ADD_TEXT: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_ELEMENT_ADD_TEXT_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_ELEMENT_ADD_TEXT_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SHOW_API_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case FETCH_JOBS_BY_INSTALLATION_ID: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_JOBS_BY_INSTALLATION_ID_SUCCESS: {
      return {
        ...state,
        jobs: action.payload,
        loader: false,
      };
    }
    case FETCH_JOBS_BY_INSTALLATION_ID_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case FETCH_AREA_ZONES_BY_CITY: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_AREA_ZONES_BY_CITY_SUCCESS: {
      return {
        ...state,
        zones: action.payload.body,
        loader: false,
      };
    }
    case UPDATE_GS_THRESHOLD_LIMITS: {
      return {
        ...state,
        loader: true,
      };
    }
    case UPDATE_GS_THRESHOLD_LIMITS_SUCCESS: {
      return {
        ...state,
        zones: action.payload.body,
        loader: false,
      };
    }
    case FETCH_ALL_REPEATED_JOBS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_ALL_REPEATED_JOBS_SUCCESS: {
      return {
        ...state,
        repeatedJobs: action.payload,
        loader: false,
      };
    }
    case FETCH_DEPENDENCIES: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_DEPENDENCIES_SUCCESS: {
      return {
        ...state,
        dependencies: action.payload,
        loader: false,
      };
    }
    case RESET_ZONES: {
      return {
        ...state,
        zones: [],
        loader: false,
      };
    }
    default:
      return state;
  }
};
