import {
  fetchCustomer,
  fetchCustomerAddress,
  fetchCustomerAddressError,
  fetchCustomerAddressSuccess,
  fetchCustomerError,
  fetchCustomers,
  fetchCustomersSuccess,
  fetchCustomerSuccess,
  fetchCustopmerPlansListSuccess,
  fetchPhpDailyInstallationDetailsSuccess,
  fetchPhpMonthlyInstallationsSuccess,
  fetchPhpMonthlyStatsSuccess,
  fetchPhpPurifierValidityDetailsSuccess,
  fetchPhpWeeklyInstallationDetailsSuccess,
  fetchRewardsSuccess,
  showAPIMessage,
  updateCustomerStatusError,
  updateCustomerStatusSuccess,
} from "actions/CustomerActions";
import {
  fetchInstallationDataById,
  fetchPurifierDetails,
} from "actions/PaymentActions";
import {
  ADD_CUSTOMER,
  ADD_PENDING_PAYMENT,
  DEVICE_REPLACEMENT,
  FETCH_ALL_CUSTOMER,
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_ADDRESS,
  FETCH_CUSTOMER_PLANS_REQUEST,
  FETCH_PHP_DAILY_INSTALLATIONS_DETAILS,
  FETCH_PHP_MONTHLY_INSTALLATIONS,
  FETCH_PHP_MONTHLY_STATS,
  FETCH_PHP_PURIFIER_VALIDITY_DETAILS,
  FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS,
  FETCH_REWARDS,
  MOVE_TO_UNINSTALL,
  REQUEST_NEW_PRIME,
  UPDATE_ADDRESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_BOT,
  UPDATE_CUSTOMER_NONPAYING,
  UPDATE_CUSTOMER_PRIORITY,
  UPDATE_CUSTOMER_STATUS,
} from "constants/ActionTypes";
import { NotificationManager } from "react-notifications";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  customerAxios,
  sponsorAxios,
  workForceAxios as axios,
  workForceAxios,
  appAxios,
} from "util/Api";
import { toggleStatusModal } from "../actions/uiActions";

function* fetchCustomersRequest(action) {
  try {
    const fetchedCustomer = yield call(getCustomers, action.payload);
    yield put(fetchCustomersSuccess(fetchedCustomer));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getCustomers = async function (params) {
  let customers = [];
  try {
    const response = await axios.get("/customer/list", {
      params,
    });
    customers = response.data;
  } catch (error) {
    console.log(error);
  }
  return customers;
};

function* fetchCustomerRequest(action) {
  try {
    const customer = yield call(getCustomer, {
      customerId: action.payload.id,
    });
    yield put(fetchCustomerSuccess(customer));
  } catch (error) {
    yield put(fetchCustomerError(error.message));
  }
}
const getCustomer = async function (params) {
  let customers = [];
  try {
    const response = await axios.get("/customer/" + params["customerId"], {});
    customers = response.data;
  } catch (error) {
    throw error;
  }
  return customers;
};

function* addCustomerRequest(action) {
  try {
    const response = yield call(addCustomer, action.payload);
    yield all([put(fetchCustomers())]);
    if (response.success) {
      NotificationManager.success("Customer Added successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to add customer");
  }
}

const addCustomer = async function (params) {
  try {
    const response = await axios.post("/customer/add", { ...params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* updateCustomerRequest(action) {
  try {
    const response = yield call(updateCustomer, action.payload);
    yield all([
      // put(updateCustomerStatusSuccess(response, "Update Success")),
      // put(toggleStatusModal(false)),
      put(fetchCustomer(action.payload.id)),
    ]);
    if (response.success) {
      NotificationManager.success("Customer Updated successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to update customer details");
  }
}

const updateCustomer = async function (params) {
  try {
    const response = await axios.put("/customer/" + params["id"], {
      ...params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* updateCustomerPriorityRequest(action) {
  try {
    const response = yield call(updateCustomerPriority, action.payload.payload);
    yield all([put(fetchCustomer(action.payload.payload.id))]);
    if (response.success) {
      NotificationManager.success("Customer Priority Updated successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to update customer details");
  }
}

function* updateNonPayingCustomerRequest(action) {
  try {
    const response = yield call(
      updateNonPayingCustomer,
      action.payload.payload
    );
    if (response.success) {
      NotificationManager.success("Customer marked as non paying");
    } else {
      NotificationManager.error("Failed: " + response.message);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to update customer details");
  }
}

function* updateBotCustomerRequest(action) {
  try {
    const response = yield call(updateBotCustomer, action.payload.payload);
    yield all([put(fetchPurifierDetails(action.payload.payload.deviceId))]);
    if (response.success) {
      NotificationManager.success("Customer Bot Updated successfully");
    } else {
      NotificationManager.error("Failed: " + response.message);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to update Bot details");
  }
}

const updateCustomerPriority = async function (params) {
  try {
    const response = await axios.put(
      "/customer/updatePriorityCustomer/" + params["id"],
      params.isPriorityUser
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateNonPayingCustomer = async function (params) {
  try {
    const response = await axios.post(
      "/installation/markNonPayingCustomer/" + params["id"],
      params.isNonPayingCustomer
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateBotCustomer = async function (params) {
  try {
    const response = await sponsorAxios.post("/device/life/changeBot", params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* updateCustomerAddressRequest(action) {
  try {
    const response = yield call(updateCustomerAddress, action.payload);
    yield all([put(fetchCustomerAddress(action.payload.installationId))]);
    if (response.success) {
      NotificationManager.success("Customer Address Updated successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to update customer details");
  }
}

const updateCustomerAddress = async function (params) {
  try {
    const response = await axios.put(
      "/customer/changeAddress/" + params["id"],
      {
        ...params["params"],
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* requestNewPrimeRequest(action) {
  try {
    const response = yield call(requestNewDevice, action.payload);
    yield all([
      put(fetchInstallationDataById(action.payload.installationId)),
      // put(fetchCustomer(action.payload.id)),
    ]);
    if (response.success) {
      NotificationManager.success("Requested New Prime Successfully");
    } else {
      NotificationManager.error("Failed: " + response.message);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to request prime");
  }
}
const requestNewDevice = async function (params) {
  try {
    const response = await axios.post(
      "/workforce/createDeliveryJobForExistingCustomer/" +
      params["installationId"],
      {
        ...params["params"],
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* updateCustomerStatusRequest(action) {
  try {
    const response = yield call(updateCustomerStatus, action.payload);
    yield all([
      put(updateCustomerStatusSuccess(response, "Update Success")),
      put(toggleStatusModal(false)),
      put(fetchCustomer(action.payload.customerId)),
    ]);
    if (response.success) {
      NotificationManager.success("Customer Status updated successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield all([put(updateCustomerStatusError(error.message))]);
    NotificationManager.error("Failed to update customer status");
  }
}

const updateCustomerStatus = async function (params) {
  try {
    const response = await axios.put(
      "/customer/" + params["customerId"] + "/status",
      params["payload"]
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* addCustomerCommRequest(action) {
  try {
    const response = yield call(addCustomerComm, action.payload);
    yield all([
      put(toggleStatusModal(false)),
      put(fetchCustomer(action.payload.customerId)),
    ]);
    if (response.success) {
      NotificationManager.success("Communication added successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield all([put(updateCustomerStatusError(error.message))]);
    NotificationManager.error("Failed to add customer communication");
  }
}

const addCustomerComm = async function (params) {
  try {
    const response = await axios.post(
      "/customer/" + params["customerId"] + "/note",
      params["payload"]
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* addPendingPaymentRequest(action) {
  try {
    const response = yield call(addPendingPayment, action.payload);
    if (response.success) {
      NotificationManager.success("Customer payment added successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    NotificationManager.error("Failed to add customer communication");
  }
}

const addPendingPayment = async function (params) {
  try {
    const response = await axios.post(
      "/customer/" + params["customerId"] + "/pendingPay",
      params["payload"]
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* fetchCustomerAddressRequest(action) {
  try {
    const address = yield call(getCustomerAddress, action.payload);
    yield put(fetchCustomerAddressSuccess(address));
  } catch (error) {
    yield put(fetchCustomerAddressError(error.message));
  }
}

const getCustomerAddress = async function (params) {
  let address = [];
  try {
    const response = await axios.get(
      `/customer/getCustomerByInstallationId/${params.id}`
    );
    address = { ...response.data.addresses[0] };
  } catch (error) {
    throw error;
  }
  return address;
};

export const transferOwnerShip = async function (params) {
  try {
    const response = await axios.put(
      `/customer/transferOwnership/${params.installationId}?phoneNumber=${params.phoneNumber}`
    );
    if (response.data.success) {
      NotificationManager.success(response.data.message);
    } else {
      NotificationManager.error("Failed: " + response.data.message, "", 10000);
    }
    return response.data;
  } catch (error) {
    NotificationManager.error(error.message);
    throw error;
  }
};

function* fetchPhpPurifierRequest(action) {
  try {
    const response = yield call(getPhpPurifier, action.payload);
    yield put(fetchPhpPurifierValidityDetailsSuccess(response));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getPhpPurifier = async function (params) {
  let activePurifiers = {};
  try {
    const response = await customerAxios.get("/report/activePurifiersStat");
    activePurifiers = response.data;
  } catch (error) {
    console.log(error);
  }
  return activePurifiers;
};

function* fetchPhpMonthlyRequest(action) {
  try {
    const response = yield call(getPhpPurifierMonthly, action.payload);
    yield put(fetchPhpMonthlyInstallationsSuccess(response));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getPhpPurifierMonthly = async function (params) {
  let monthlyInstallations = {};
  try {
    const response = await customerAxios.get(
      "/report/monthlyInstallationCount",
      { ...params }
    );
    monthlyInstallations = response.data;
  } catch (error) {
    console.log(error);
  }
  return monthlyInstallations;
};

function* fetchPhpMonthlyStatsRequest(action) {
  try {
    const response = yield call(getPhpPurifierMonthlyStats, action.payload);
    yield put(fetchPhpMonthlyStatsSuccess(response));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getPhpPurifierMonthlyStats = async function (params) {
  let monthlyStats = {};
  try {
    const response = await customerAxios.get("/report/monthStats");
    monthlyStats = response.data;
  } catch (error) {
    console.log(error);
  }
  return monthlyStats;
};

function* fetchPhpDailyRequest(action) {
  try {
    const response = yield call(getPhpDaily, action.payload);
    yield put(fetchPhpDailyInstallationDetailsSuccess(response));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getPhpDaily = async function (params) {
  var monthyr = params.monthYear.toLowerCase();
  var chars = monthyr.slice(0, monthyr.search(/\d/));
  var numbs = monthyr.replace(chars, "");
  let mon = chars.slice(0, 3);
  let dailyInstallations = {};
  try {
    const response = await customerAxios.get(
      `/report/dailyStats/${mon}_${numbs}`,
      { ...params }
    );
    dailyInstallations = response.data;
  } catch (error) {
    console.log(error);
  }
  return dailyInstallations;
};

function* fetchPhpWeeklyRequest(action) {
  try {
    const response = yield call(getPhpWeekly, action.payload);
    yield put(fetchPhpWeeklyInstallationDetailsSuccess(response));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getPhpWeekly = async function (params) {
  var monthyr = params.monthYear.toLowerCase();
  var chars = monthyr.slice(0, monthyr.search(/\d/));
  var numbs = monthyr.replace(chars, "");
  let mon = chars.slice(0, 3);
  let weeklyInstallations = {};
  try {
    const response = await customerAxios.get(
      `/report/weeklyStats/${mon}_${numbs}`,
      { ...params }
    );
    weeklyInstallations = response.data;
  } catch (error) {
    console.log(error);
  }
  return weeklyInstallations;
};

function* fetchRewardsRequest(action) {
  try {
    const response = yield call(getRewards, action.payload);
    yield put(fetchRewardsSuccess(response));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getRewards = async function (params) {
  let rewards = {};
  try {
    const response = await customerAxios.get("/reward/tasks", { params });
    rewards = response.data;
  } catch (error) {
    console.log(error);
  }
  return rewards;
};

export const addReward = async function (params) {
  try {
    const response = await customerAxios.put(
      `/reward/taskInstallation/${params.id}/${params.action}`,
      null
    );
    if (response.data.success) {
      NotificationManager.success(response.data.body);
    } else {
      NotificationManager.error("Failed: " + response.data.body, "", 10000);
    }
    return response.data;
  } catch (error) {
    NotificationManager.error(error.message);
    throw error;
  }
};

function* deviceReplacementRequest(action) {
  try {
    const response = yield call(deviceReplacement, action.payload);

    if (response.success) {
      NotificationManager.success(response.message);
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const deviceReplacement = async function (params) {
  const { payload } = params;
  let deviceReplacement = {};
  try {
    const response = await customerAxios.post(
      "/installation/replace",
      payload
    );
    deviceReplacement = response.data;
  } catch (error) {
    console.log(error);
  }
  return deviceReplacement;
};

function* moveToUninstallRequest(action) {
  try {
    const response = yield call(moveToUninstall, action.payload);
    if (response.success) {
      NotificationManager.success(response.message);
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const moveToUninstall = async function (params) {
  const { id, body } = params;
  let moveToUninstall = {};
  try {
    const response = await customerAxios.post(
      "/installation/" + id + "/uninstall",
      body
    );
    moveToUninstall = response.data;
  } catch (error) {
    console.log(error);
  }
  return moveToUninstall;
};

const fetchCustomerPlans = async function (params) {
  let plansList = {};
  try {
    let response = await appAxios.get("/customer/plans");
    plansList = response.data;
  } catch (error) {
    throw error;
  }
  return plansList;
};
function* fetchCustomerPlansList(action) {
  try {
    const response = yield call(fetchCustomerPlans, action.payload);
    yield put(fetchCustopmerPlansListSuccess(response));
  } catch (error) {
    console.log(error)
  }
}

export function* fetchCustomersData() {
  yield takeLatest(FETCH_ALL_CUSTOMER, fetchCustomersRequest);
  yield takeLatest(FETCH_CUSTOMER, fetchCustomerRequest);
  yield takeLatest(FETCH_CUSTOMER_ADDRESS, fetchCustomerAddressRequest);
  yield takeLatest(ADD_CUSTOMER, addCustomerRequest);
  yield takeLatest(UPDATE_CUSTOMER, updateCustomerRequest);
  yield takeLatest(UPDATE_ADDRESS, updateCustomerAddressRequest);
  yield takeLatest(REQUEST_NEW_PRIME, requestNewPrimeRequest);
  yield takeLatest(UPDATE_CUSTOMER_STATUS, updateCustomerStatusRequest);
  yield takeLatest("ADD_CUSTOMER_COMMUNICATION", addCustomerCommRequest);
  yield takeLatest(ADD_PENDING_PAYMENT, addPendingPaymentRequest);
  yield takeLatest(UPDATE_CUSTOMER_PRIORITY, updateCustomerPriorityRequest);
  yield takeLatest(UPDATE_CUSTOMER_NONPAYING, updateNonPayingCustomerRequest);
  yield takeLatest(UPDATE_CUSTOMER_BOT, updateBotCustomerRequest);
  yield takeLatest(
    FETCH_PHP_PURIFIER_VALIDITY_DETAILS,
    fetchPhpPurifierRequest
  );
  yield takeLatest(FETCH_PHP_MONTHLY_INSTALLATIONS, fetchPhpMonthlyRequest);
  yield takeLatest(FETCH_PHP_MONTHLY_STATS, fetchPhpMonthlyStatsRequest);
  yield takeLatest(FETCH_PHP_DAILY_INSTALLATIONS_DETAILS, fetchPhpDailyRequest);
  yield takeLatest(
    FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS,
    fetchPhpWeeklyRequest
  );
  yield takeLatest(FETCH_REWARDS, fetchRewardsRequest);
  yield takeLatest(DEVICE_REPLACEMENT, deviceReplacementRequest);
  yield takeLatest(MOVE_TO_UNINSTALL, moveToUninstallRequest);
  yield takeLatest(FETCH_CUSTOMER_PLANS_REQUEST, fetchCustomerPlansList)
}

export default function* rootSaga() {
  yield all([fork(fetchCustomersData)]);
}
