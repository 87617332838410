import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from "constants/ActionTypes";

const initialState = {
  show: false,
  type: "",
  message: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case HIDE_NOTIFICATION: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
