import {
  FETCH_ALL_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_ALL_CUSTOMER,
  SHOW_API_MESSAGE,
  FETCH_CUSTOMER_ADDRESS,
  FETCH_CUSTOMER_ADDRESS_SUCCESS,
  FETCH_PHP_DAILY_INSTALLATIONS_DETAILS_SUCCESS,
  FETCH_PHP_MONTHLY_INSTALLATIONS_SUCCESS,
  FETCH_PHP_MONTHLY_STATS_SUCCESS,
  FETCH_PHP_PURIFIER_VALIDITY_DETAILS_SUCCESS,
  FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS_SUCCESS,
  FETCH_REWARDS_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
  customers: [],
  address: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_CUSTOMER: {
      return {
        ...state,
        loader: true,
      };
    }

    case FETCH_ALL_CUSTOMER_SUCCESS: {
      return {
        ...state,
        allCustomers: action.payload,
        customers: action.payload.customers,
        totalCount: action.payload.total,
        loader: false,
      };
    }
    case FETCH_CUSTOMER_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }
    case FETCH_CUSTOMER_ADDRESS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_CUSTOMER_ADDRESS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }
    case FETCH_PHP_PURIFIER_VALIDITY_DETAILS_SUCCESS: {
      return {
        ...state,
        activePurifiers: action.payload.activePurifiers.body.activepurifiers,
        loader: false,
      };
    }
    case FETCH_PHP_MONTHLY_INSTALLATIONS_SUCCESS: {
      return {
        ...state,
        monthlyInstallations: action.payload.monthlyInstallations.body,
        loader: false,
      };
    }
    case FETCH_PHP_MONTHLY_STATS_SUCCESS: {
      return {
        ...state,
        monthlyStats: action.payload.monthlyStats.body,
        loader: false,
      };
    }
    case FETCH_REWARDS_SUCCESS:{
      return {
        ...state,
        rewards: action.payload.rewards.body,
        loader: false,
      };
    }
    case FETCH_PHP_DAILY_INSTALLATIONS_DETAILS_SUCCESS: {
      return {
        ...state,
        dailyInstallations: action.payload.dailyInstallations.body,
        loader: false,
      };
    }
    case FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS_SUCCESS: {
      return {
        ...state,
        weeklyInstallations: action.payload.weeklyInstallations.body,
        loader: false,
      };
    }
    case SHOW_API_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }

    default:
      return state;
  }
};
