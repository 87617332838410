import {
  OPEN_APPOINTMENT_UPDATE_MODAL,
  CLOSE_APPOINTMENT_UPDATE_MODAL,
  UPDATE_JOB_APPOINTMENT_SUCCESS,
  UPDATE_JOB_APPOINTMENT_ERROR,
} from "constants/ActionTypes";

const initialState = {
  open: false,
  result: null,
  message: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_APPOINTMENT_UPDATE_MODAL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CLOSE_APPOINTMENT_UPDATE_MODAL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_JOB_APPOINTMENT_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_JOB_APPOINTMENT_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
