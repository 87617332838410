// Customizer const
export const TOGGLE_COLLAPSED_NAV = "toggle_collapse_menu";
export const DRAWER_TYPE = "drawer_type";
export const FIXED_DRAWER = "fixed_drawer";
export const COLLAPSED_DRAWER = "collapsible";
export const MINI_DRAWER = "mini_drawer";
export const THEME_COLOR = "theme_color";
export const DARK_THEME = "dark_theme";
export const WINDOW_WIDTH = "window-width";
export const SWITCH_LANGUAGE = "switch-language";
export const CHANGE_DIRECTION = "change-direction";

export const CHANGE_NAVIGATION_STYLE = "change-navigation-style";
export const HORIZONTAL_NAVIGATION = "horizontal_navigation";
export const VERTICAL_NAVIGATION = "vertical_navigation";

export const HORIZONTAL_MENU_POSITION = "horizontal_menu_position";
export const ABOVE_THE_HEADER = "above_the_header";
export const INSIDE_THE_HEADER = "inside_the_header";
export const BELOW_THE_HEADER = "below_the_header";

//Contact Module const

export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";

export const FETCH_ALL_CONTACT = "fetch_all_contact";
export const FETCH_ALL_CONTACT_SUCCESS = "fetch_all_contact_success";
export const ADD_FAVOURITE = "add_favourite";
export const ON_CONTACT_SELECT = "on_contact_select";
export const ON_ADD_CONTACT = "on_add_contact";
export const ON_CONTACT_CLOSE = "on_contact_close";
export const ON_FILTER_OPTION_SELECT = "on_filter_option_select";
export const ON_SAVE_CONTACT = "on_save_contact";
export const ON_DELETE_CONTACT = "on_delete_contact";
export const ON_DELETE_SELECTED_CONTACT = "on_delete_selected_contact";
export const FILTER_CONTACT = "filter_contact";
export const GET_ALL_CONTACT = "get_all_contact";
export const GET_UNSELECTED_ALL_CONTACT = "get_unselected_all_contact";
export const ON_ALL_CONTACT_SELECT = "on_all_contact_select";
export const UPDATE_SEARCH_USER = "update_search_user";
export const ON_TOGGLE_DRAWER = "on_toggle_drawer";
export const HANDLE_REQUEST_CLOSE = "handle_request_close";
export const HIDE_CONTACT_LOADER = "hide_contact_loader";

//Auth const
export const SIGNIN_USER = "signin_user";
export const SIGNIN_USER_SUCCESS = "signin_user_success";
export const SIGNOUT_USER = "signout_user";
export const SIGNOUT_USER_SUCCESS = "signout_user_success";
export const INIT_URL = "init_url";
export const USER_DATA = "user_data";
export const USER_TOKEN_SET = "user_token_set";

// Chat Module const

export const FETCH_ALL_CHAT_USER = "fetch_all_chat_user";
export const FETCH_ALL_CHAT_USER_CONVERSATION =
  "fetch_all_chat_user_conversation";
export const FETCH_ALL_CHAT_USER_SUCCESS = "fetch_all_chat_user_success";
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS =
  "fetch_all_chat_user_conversation_success";
export const FILTER_USERS = "filter_users";
export const ON_SELECT_USER = "on_select_user";
export const ON_SHOW_LOADER = "on_show_loader";
export const ON_HIDE_LOADER = "on_hide_loader";
export const USER_INFO_STATE = "user_info_state";
export const SUBMIT_COMMENT = "submit_comment";
export const UPDATE_MESSAGE_VALUE = "update_message_value";
export const UPDATE_SEARCH_CHAT_USER = "update_search_chat_user";

//// Mail Module const

export const ADD_LABEL = "ADD_LABEL";
export const GET_ALL_MAIL = "get_all_mail";
export const FETCH_ALL_MAIL = "fetch_all_mail";
export const FETCH_ALL_MAIL_SUCCESS = "fetch_all_mail_success";
export const GET_IMPORTANT_MAIL = "get_important_mail";
export const GET_NAV_FILTERS = "get_nav_filters";
export const GET_NAV_LABELS = "get_nav_labels";
export const GET_NAV_FOLDER = "get_nav_folder";
export const GET_READ_MAIL = "get_read_mail";
export const GET_STARRED_MAIL = "get_starred_mail";
export const GET_UNIMPORTANT_MAIL = "get_unimportant_mail";
export const GET_UNREAD_MAIL = "get_unread_mail";
export const GET_UNSELECTED_ALL_MAIL = "get_unselected_all_mail";
export const GET_UNSTARRED_MAIL = "get_unstarred_mail";
export const ON_ALL_MAIL_SELECT = "on_all_mail_select";
export const ON_FOLDER_MENU_ITEM_SELECT = "on_folder_menu_item_select";
export const ON_FOLDER_SELECT = "on_folder_select";
export const ON_IMPORTANT_SELECT = "on_important_select";
export const ON_LABEL_MENU_ITEM_SELECT = "on_label_menu_item_select";
export const GET_MARK_AS_START = "get_mark_as_start";
export const ON_LABEL_SELECT = "on_label_select";
export const ON_MAIL_CHECKED = "on_mail_checked";
export const ON_MAIL_SELECT = "on_mail_select";
export const ON_MAIL_SEND = "on_mail_send";
export const ON_OPTION_MENU_ITEM_SELECT = "on_option_menu_item_select";
export const ON_OPTION_MENU_SELECT = "on_option_menu_select";
export const ON_START_SELECT = "on_start_select";
export const SEARCH_MAIL = "search_mail";
export const ON_DELETE_MAIL = "on_delete_mail";
export const SET_CURRENT_MAIL_NULL = "set_current_mail_null";
export const ON_COMPOSE_MAIL = "on_compose_mail";

//// TO-DO Module const

export const ON_SORTEND = "on_sortend";
export const FETCH_ALL_TODO = "fetch_all_todo";
export const FETCH_ALL_TODO_SUCCESS = "fetch_all_todo-success";
export const FETCH_ALL_TODO_CONVERSATION = "fetch_all_todo_conversation";
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS =
  "fetch_all_todo_conversation_success";
export const SELECT_ALL_TODO = "select_all_todo";
export const GET_ALL_TODO = "get_all_todo";
export const GET_UNSELECTED_ALL_TODO = "get_unselected_all_todo";
export const GET_STARRED_TODO = "get_starred_todo";
export const GET_UNSTARRED_TODO = "get_unstarred_todo";
export const GET_IMPORTANT_TODO = "get_important_todo";
export const GET_UNIMPORTANT_TODO = "get_unimportant_todo";
export const ON_LABEL_UPDATE = "on_label_update";
export const ON_TODO_UPDATE = "on_todo_update";
export const ON_DELETE_TODO = "on_delete_todo";
export const SEARCH_TODO = "search_todo";
export const SHOW_TODOS = "show_todos";
export const GET_TODO_CONVERSATION = "get_todo_conversation";
export const ON_TODO_CHECKED = "on_todo_checked";
export const ON_TODO_ADD = "on_todo_add";
export const ON_TODO_SELECT = "on_todo_select";
export const SET_CURRENT_TODO_NULL = "set_current_todo_null";
export const REMOVE_LABEL = "remove_label";
export const UPDATE_SEARCH = "update_search";

// Customer API const
export const FETCH_ALL_CUSTOMER = "fetch_all_customer";
export const FETCH_ALL_CUSTOMER_SUCCESS = "fetch_all_customer_success";
export const FETCH_CUSTOMER = "fetch_customer";
export const FETCH_CUSTOMER_SUCCESS = "fetch_customer_success";
export const FETCH_CUSTOMER_ERROR = "fetch_customer_error";
export const ADD_CUSTOMER = "add_customer";
export const ADD_CUSTOMER_SUCCESS = "add_customer_success";
export const ADD_CUSTOMER_ERROR = "add_customer_error";
export const UPDATE_CUSTOMER = "update_customer";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const REQUEST_NEW_PRIME = "REQUEST_NEW_PRIME";
export const UPDATE_CUSTOMER_SUCCESS = "update_customer_success";
export const UPDATE_CUSTOMER_ERROR = "update_customer_error";
export const UPDATE_CUSTOMER_STATUS = "update_customer_status";
export const UPDATE_CUSTOMER_STATUS_SUCCESS = "update_customer_status_success";
export const UPDATE_CUSTOMER_STATUS_ERROR = "update_customer_status_error";
export const UPDATE_CUSTOMER_PRIORITY = "UPDATE_CUSTOMER_PRIORITY";
export const UPDATE_CUSTOMER_NONPAYING = "UPDATE_CUSTOMER_NONPAYING";
export const UPDATE_CUSTOMER_BOT = "UPDATE_CUSTOMER_BOT";
export const FETCH_CUSTOMER_ADDRESS = "FETCH_CUSTOMER_ADDRESS";
export const FETCH_CUSTOMER_ADDRESS_SUCCESS = "FETCH_CUSTOMER_ADDRESS_SUCCESS";
export const FETCH_CUSTOMER_ADDRESS_ERROR = "FETCH_CUSTOMER_ADDRESS_ERROR";
export const FETCH_PHP_PURIFIER_VALIDITY_DETAILS =
  "FETCH_PHP_PURIFIER_VALIDITY_DETAILS";
export const FETCH_PHP_PURIFIER_VALIDITY_DETAILS_SUCCESS =
  "FETCH_PHP_PURIFIER_VALIDITY_DETAILS_SUCCESS";
export const FETCH_PHP_PURIFIER_VALIDITY_DETAILS_ERROR =
  "FETCH_PHP_PURIFIER_VALIDITY_DETAILS_ERROR";
export const FETCH_PHP_MONTHLY_INSTALLATIONS =
  "FETCH_PHP_MONTHLY_INSTALLATIONS";
export const FETCH_PHP_MONTHLY_INSTALLATIONS_SUCCESS =
  "FETCH_PHP_MONTHLY_INSTALLATIONS_SUCCESS";
export const FETCH_PHP_MONTHLY_INSTALLATIONS_ERROR =
  "FETCH_PHP_MONTHLY_INSTALLATIONS_ERROR";
export const FETCH_PHP_MONTHLY_STATS = "FETCH_PHP_MONTHLY_STATS";
export const FETCH_PHP_MONTHLY_STATS_SUCCESS =
  "FETCH_PHP_MONTHLY_STATS_SUCCESS";
export const FETCH_PHP_MONTHLY_STATS_ERROR = "FETCH_PHP_MONTHLY_STATS_ERROR";
export const FETCH_PHP_DAILY_INSTALLATIONS_DETAILS =
  "FETCH_PHP_DAILY_INSTALLATIONS_DETAILS";
export const FETCH_PHP_DAILY_INSTALLATIONS_DETAILS_SUCCESS =
  "FETCH_PHP_DAILY_INSTALLATIONS_DETAILS_SUCCESS";
export const FETCH_PHP_DAILY_INSTALLATIONS_DETAILS_ERROR =
  "FETCH_PHP_DAILY_INSTALLATIONS_DETAILS_ERROR";
export const FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS =
  "FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS";
export const FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS_SUCCESS =
  "FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS_SUCCESS";
export const FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS_ERROR =
  "FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS_ERROR";
export const FETCH_REWARDS = "FETCH_REWARDS";
export const FETCH_REWARDS_SUCCESS = "FETCH_REWARDS_SUCCESS";
export const FETCH_REWARDS_ERROR = "FETCH_REWARDS_ERROR";
export const DEVICE_REPLACEMENT = "DEVICE_REPLACEMENT";
export const DEVICE_REPLACEMENT_SUCCESS = "DEVICE_REPLACEMENT_SUCCESS";
export const DEVICE_REPLACEMENT_ERROR = "DEVICE_REPLACEMENT_ERROR";

// Employee API Const
export const FETCH_ALL_EMPLOYEE = "fetch_all_employee";
export const FETCH_EMPLOYEE = "fetch_employee";
export const FETCH_EMPLOYEES_LEAVE = "fetch_employees_leave";
export const FETCH_ALL_EMPLOYEE_SUCCESS = "fetch_all_employee_success";
export const FETCH_EMPLOYEE_SUCCESS = "fetch_employee_success";
export const FETCH_EMPLOYEE_ERROR = "fetch_employee_error";
export const FETCH_EMPLOYEES_LEAVE_SUCCESS = "fetch_employees_leave_success";
export const FETCH_EMPLOYEES_LEAVE_ERROR = "fetch_employees_leave_error";
export const ADD_EMPLOYEE = "add_employee";
export const ADD_EMPLOYEE_SUCCESS = "add_employee_success";
export const ADD_EMPLOYEE_ERROR = "add_employee_error";
export const FETCH_ALL_LEAVES = "fetch_all_leaves";
export const FETCH_ALL_LEAVES_SUCCESS = "fetch_all_leaves_success";
export const FETCH_ALL_LEAVES_ERROR = "fetch_all_leaves_error";
export const UPDATE_EMPLOYEE = "update_employee";
export const UPDATE_EMPLOYEE_SUCCESS = "update_employee_success";
export const UPDATE_EMPLOYEE_ERROR = "update_employee_error";
export const FETCH_ALL_ROLES = "FETCH_ALL_ROLES";
export const FETCH_ALL_PERMISSIONS = "FETCH_ALL_PERMISSIONS";
export const FETCH_ALL_ROLES_SUCCESS = "FETCH_ALL_ROLES_SUCCESS";
export const FETCH_ALL_PERMISSIONS_SUCCESS = "FETCH_ALL_PERMISSIONS_SUCCESS";
export const FETCH_ALL_CONFIG_FOR_A_SCHEME = "FETCH_ALL_CONFIG_FOR_A_SCHEME";
export const FETCH_ALL_CONFIG_FOR_A_SCHEME_SUCCESS =
  "FETCH_ALL_CONFIG_FOR_A_SCHEME_SUCCESS";
export const FETCH_ALL_SCHEMES = "FETCH_ALL_SCHEMES";
export const FETCH_ALL_SCHEMES_SUCCESS = "FETCH_ALL_SCHEMES_SUCCESS";
export const FETCH_PASSBOOK_ENTRIES = "FETCH_PASSBOOK_ENTRIES";
export const FETCH_PASSBOOK_ENTRIES_SUCCESS = "FETCH_PASSBOOK_ENTRIES_SUCCESS";
export const ADD_EARNING_ADHOC = "ADD_EARNING_ADHOC";
export const ADD_EARNING_CONFIG = "ADD_EARNING_CONFIG";
export const ADD_EARNING_SCHEME = "ADD_EARNING_SCHEME";
export const ADD_LEAVE = "ADD_LEAVE";
export const ADD_EARNING_ADHOC_SUCCESS = "ADD_EARNING_ADHOC_SUCCESS";
export const ADD_EARNING_CONFIG_SUCCESS = "ADD_EARNING_CONFIG_SUCCESS";
export const ADD_EARNING_SCHEME_SUCCESS = "ADD_EARNING_SCHEME_SUCCESS";
export const ADD_LEAVE_SUCCESS = "ADD_LEAVE_SUCCESS";
export const ADD_EARNING_ADHOC_ERROR = "ADD_EARNING_ADHOC_ERROR";
export const ADD_EARNING_CONFIG_ERROR = "ADD_EARNING_CONFIG_ERROR";
export const ADD_EARNING_SCHEME_ERROR = "ADD_EARNING_SCHEME_ERROR";
export const ADD_LEAVE_ERROR = "ADD_LEAVE_ERROR";
export const FETCH_SOURCE_WAREHOUSE = "FETCH_SOURCE_WAREHOUSE";
export const FETCH_DESTINATION_WAREHOUSE = "FETCH_DESTINATION_WAREHOUSE";
export const FETCH_DESTINATION_WAREHOUSE_SUCCESS =
  "FETCH_DESTINATION_WAREHOUSE_SUCCESS";
export const FETCH_TRANSIT_WAREHOUSE = "FETCH_TRANSIT_WAREHOUSE";
export const FETCH_TRANSIT_WAREHOUSE_SUCCESS =
  "FETCH_TRANSIT_WAREHOUSE_SUCCESS";
export const FETCH_SOURCE_WAREHOUSE_SUCCESS = "FETCH_SOURCE_WAREHOUSE_SUCCESS";
export const UPDATE_EARNING_CONFIG = "UPDATE_EARNING_CONFIG";
export const UPDATE_LEAVE = "UPDATE_LEAVE";
export const FETCH_CONFIG_BY_ID = "FETCH_CONFIG_BY_ID";
export const FETCH_CONFIG_BY_ID_SUCCESS = "FETCH_CONFIG_BY_ID_SUCCESS";

// technicians

export const FETCH_ALL_TECHNICIANS = "FETCH_ALL_TECHNICIANS";
export const FETCH_ALL_TECHNICIANS_SUCCESS = "FETCH_ALL_TECHNICIANS_SUCCESS";

export const FETCH_NEW_TECHNICIANS = "FETCH_NEW_TECHNICIANS";
export const FETCH_NEW_TECHNICIANS_SUCCESS = "FETCH_NEW_TECHNICIANS_SUCCESS";

// Area API Const
export const FETCH_ALL_AREA = "fetch_all_area";
export const FETCH_AREA = "fetch_area";
export const FETCH_ALL_AREA_SUCCESS = "fetch_all_area_success";
export const FETCH_AREA_SUCCESS = "fetch_area_success";
export const FETCH_AREA_ERROR = "fetch_area_error";
export const ADD_AREA = "add_area";
export const ADD_AREA_SUCCESS = "add_area_success";
export const ADD_AREA_ERROR = "add_area_error";
export const UPDATE_AREA = "update_area";
export const UPDATE_AREA_SUCCESS = "update_area_success";
export const UPDATE_AREA_ERROR = "update_area_error";
export const FETCH_STATES = "fetch_states";
export const FETCH_CITIES = "fetch_cities";
export const FETCH_ALL_CITIES = "fetch_all_cities";
export const FETCH_STATUSES = "fetch_statuses";
export const FETCH_ZONES = "fetch_zones";
export const FETCH_STATES_SUCCESS = "fetch_states_success";
export const FETCH_CITIES_SUCCESS = "fetch_cities_success";
export const FETCH_ALL_CITIES_SUCCESS = "fetch_all_cities_success";
export const FETCH_ZONES_SUCCESS = "fetch_zones_success";
export const FETCH_STATUSES_SUCCESS = "fetch_statuses_success";

// Slot API Const
export const FETCH_ALL_SLOT_ZONE = "fetch_all_slot_zone";
export const FETCH_SLOT_ZONE = "fetch_slot_zone";
export const FETCH_ALL_SLOT_ZONE_SUCCESS = "fetch_all_slot_zone_success";
export const FETCH_SLOT_ZONE_SUCCESS = "fetch_slot_zone_success";
export const FETCH_SLOT_ZONE_ERROR = "fetch_slot_zone_error";
export const ADD_SLOT_ZONE = "add_slot_zone";
export const ADD_SLOT_ZONE_SUCCESS = "add_slot_zone_success";
export const ADD_SLOT_ZONE_ERROR = "add_slot_zone_error";
export const UPDATE_SLOT_ZONE = "update_slot_zone";
export const UPDATE_SLOT_ZONE_SUCCESS = "update_slot_zone_success";
export const UPDATE_SLOT_ZONE_ERROR = "update_slot_zone_error";
export const FETCH_ISSUE_TYPES = "fetch_issue_types";
export const FETCH_DAYS = "fetch_days";
export const FETCH_SLOTS = "fetch_slots";
export const FETCH_ISSUE_TYPES_SUCCESS = "fetch_issue_types_success";
export const FETCH_DAYS_SUCCESS = "fetch_days_success";
export const FETCH_SLOTS_SUCCESS = "fetch_slots_success";
export const FETCH_SLOT_COUNT_BY_ZONE_AND_ISSUE =
  "fetch_slot_count_by_zone_and_issue";
export const FETCH_SLOT_COUNT_BY_ZONE_AND_ISSUE_SUCCESS =
  "fetch_slot_count_by_zone_and_issue_success";

// Device API Const
export const FETCH_ALL_DEVICE = "fetch_all_device";
export const FETCH_DEVICE = "fetch_device";
export const ACTIVATE_DEVICE = "activate_device";
export const FETCH_ALL_DEVICE_SUCCESS = "fetch_all_device_success";
export const FETCH_DEVICE_SUCCESS = "fetch_device_success";
export const FETCH_DEVICE_ERROR = "fetch_device_error";
export const FETCH_PLAN_SUCCESS = "FETCH_PLAN_SUCCESS";
export const FETCH_PLAN = "FETCH_PLAN";
export const CHECK_CONNECTION = "check_connection";
export const CHECK_CONNECTION_SUCCESS = "CHECK_CONNECTION_SUCCESS";
export const CHECK_CONNECTION_ERROR = "CHECK_CONNECTION_ERROR";
export const MOVE_TO_UNINSTALL = "MOVE_TO_UNINSTALL";
export const MOVE_TO_UNINSTALL_SUCCESS = "MOVE_TO_UNINSTALL_SUCCESS";
export const MOVE_TO_UNINSTALL_ERROR = "MOVE_TO_UNINSTALL_ERROR";

// Job API Const
export const FETCH_ALL_JOB = "fetch_all_job";
export const FETCH_JOB = "fetch_job";
export const FETCH_ALL_JOB_SUCCESS = "fetch_all_job_success";
export const FETCH_JOB_SUCCESS = "fetch_job_success";
export const FETCH_JOB_ERROR = "fetch_job_error";
export const UPDATE_JOB = "update_job";
export const UPDATE_JOB_SUCCESS = "update_job_success";
export const UPDATE_JOB_ERROR = "update_job_error";
export const FETCH_JOB_SLOTS = "fetch_job_slots";
export const FETCH_JOB_SLOTS_SUCCESS = "fetch_job_slots_success";
export const FETCH_AREAS = "fetch_areas";
export const FETCH_AREAS_SUCCESS = "fetch_areas_success";
export const FETCH_JOB_TYPES = "fetch_issues";
export const FETCH_JOB_TYPES_SUCCESS = "fetch_issues_success";
export const FETCH_JOB_IMAGES = "fetch_images";
export const FETCH_JOB_IMAGES_SUCCESS = "fetch_images_success";
export const FETCH_JOB_SLOTS_ERROR = "fetch_job_slots_error";
export const FETCH_TECHNICIANS = "fetch_technicians";
export const FETCH_TECHNICIANS_SUCCESS = "fetch_technicians_success";
export const FETCH_TECHNICIANS_ERROR = "fetch_technicians_error";
export const FETCH_TECHNICIANS_JOB_SLOTS = "fetch_technicians_job_slots";
export const FETCH_TECHNICIANS_JOB_SLOTS_SUCCESS =
  "fetch_technicians_job_slots_success";
export const FETCH_TECHNICIANS_JOB_SLOTS_ERROR =
  "fetch_technicians_job_slots_error";
export const FETCH_AVAILABLE_TECHNICIANS = "fetch_available_technicians";
export const FETCH_AVAILABLE_TECHNICIANS_SUCCESS =
  "fetch_available_technicians_success";
export const FETCH_AVAILABLE_TECHNICIANS_ERROR =
  "fetch_available_technicians_error";
export const FETCH_ALL_DELIVERY_PERSON = "FETCH_ALL_DELIVERY_PERSON";
export const FETCH_ALL_DELIVERY_PERSON_SUCCESS =
  "FETCH_ALL_DELIVERY_PERSON_SUCCESS";
export const FETCH_ALL_DELIVERY_PERSON_ERROR =
  "FETCH_ALL_DELIVERY_PERSON_ERROR";
export const FETCH_LAST_3_JOBS_BY_INSTALLATION =
  "fetch_last_3_jobs_by_installation";
export const FETCH_LAST_3_JOBS_BY_INSTALLATION_SUCCESS =
  "fetch_last_3_jobs_by_installation_success";
export const FETCH_LAST_3_JOBS_BY_INSTALLATION_ERROR =
  "fetch_last_3_jobs_by_installation_error";
export const FETCH_JOB_TAGS = "FETCH_JOB_TAGS";
export const FETCH_JOB_TAGS_SUCCESS = "FETCH_JOB_TAGS_SUCCESS";
export const FETCH_JOB_STATUS = "FETCH_JOB_STATUS";
export const FETCH_JOB_STATUS_SUCCESS = "FETCH_JOB_STATUS_SUCCESS";
export const FETCH_JOBS_BY_INSTALLATION_ID = "FETCH_JOBS_BY_INSTALLATION_ID";
export const FETCH_JOBS_BY_INSTALLATION_ID_SUCCESS =
  "FETCH_JOBS_BY_INSTALLATION_ID_SUCCESS";
export const FETCH_JOBS_BY_INSTALLATION_ID_ERROR =
  "FETCH_JOBS_BY_INSTALLATION_ID_ERROR";
export const UPDATE_JOB_STATUS = "update_job_status";
export const UPDATE_JOB_STATUS_SUCCESS = "update_job_status_success";
export const UPDATE_JOB_STATUS_ERROR = "update_job_status_error";
export const UPDATE_ZIP = "update_zip";
export const UPDATE_ZIP_SUCCESS = "update_zip_success";
export const UPDATE_ZIP_ERROR = "update_zip_error";
export const UPDATE_JOB_APPOINTMENT = "update_job_appointment";
export const UPDATE_JOB_APPOINTMENT_SUCCESS = "update_job_appointment_success";
export const UPDATE_JOB_APPOINTMENT_ERROR = "update_job_appointment_error";
export const UPDATE_ELEMENT_ADD_TEXT = "UPDATE_ELEMENT_ADD_TEXT";
export const UPDATE_ELEMENT_ADD_TEXT_SUCCESS =
  "UPDATE_ELEMENT_ADD_TEXT_SUCCESS";
export const UPDATE_ELEMENT_ADD_TEXT_ERROR = "UPDATE_ELEMENT_ADD_TEXT_ERROR";

export const SHOW_API_MESSAGE = "show_api_message";
export const HIDE_API_MESSAGE = "hide_api_message";
export const SEND_HAPPY_CODE = "send_happy_code";
export const ADD_JOB_TO_SYSTEM = "ADD_JOB_TO_SYSTEM";
export const ADD_RE_DELIVERY_JOB_TO_SYSTEM = "ADD_RE_DELIVERY_JOB_TO_SYSTEM";
export const CREATE_INITIAL_PAYMENTS = "CREATE_INITIAL_PAYMENTS";
export const ADD_PAYMENT_WORKFLOW = "ADD_PAYMENT_WORKFLOW";
export const ADD_DEVICE_REPLACEMENT_JOB = "ADD_DEVICE_REPLACEMENT_JOB";
export const ADD_PAYMENT_TO_BE_COLLECTED = "ADD_PAYMENT_TO_BE_COLLECTED";

export const GENERATE_DELIVERY_CHALLAN = "GENERATE_DELIVERY_CHALLAN";

// Delivery API const
export const FETCH_DELIVERY_DASHBOARD = "fetch_delivery_dashboard";
export const FETCH_DELIVERY_DASHBOARD_SUCCESS =
  "fetch_delivery_dashboard_success";
export const FETCH_DELIVERY_DASHBOARD_ERROR = "fetch_delivery_dashboard_error";

export const FETCH_ALL_DELIVERY_JOBS = "fetch_all_delivery_jobs";
export const FETCH_ALL_DELIVERY_JOBS_SUCCESS =
  "fetch_all_delivery_jobs_success";
export const FETCH_DELIVERY_JOB = "fetch_delivery_job";
export const FETCH_DELIVERY_JOB_SUCCESS = "fetch_delivery_job_success";
export const FETCH_DELIVERY_JOB_ERROR = "fetch_delivery_job_error";

export const UPDATE_FIELDS = "UPDATE_FIELDS";
export const UPDATE_CALENDAR_FIELD = "UPDATE_CALENDAR_FIELD";
export const RESET = "RESET";
export const SEARCH_UPDATE_FIELD = "SEARCH_UPDATE_FIELD";

export const SHOW_ALERT_MESSAGE = "SHOW_ALERT_MESSAGE";

// Status update modal
export const OPEN_STATUS_UPDATE_MODAL = "OPEN_STATUS_UPDATE_MODAL";
export const CLOSE_STATUS_UPDATE_MODAL = "CLOSE STATUS_UPDATE_MODAL";
export const SELECT_NEW_JOB_STATUS = "SELECT_NEW_JOB_STATUS";
export const UPDATE_DELIVERY_JOB_STATUS = "UPDATE_DELIVERY_JOB_STATUS";
export const UPDATE_DELIVERY_JOB_STATUS_SUCCESS =
  "UPDATE_DELIVERY_JOB_STATUS_SUCCESS";
export const UPDATE_DELIVERY_JOB_STATUS_ERROR =
  "UPDATE_DELIVERY_JOB_STATUS_ERROR";
export const RESET_STATUS_UPDATE_MODAL = "RESET_STATUS_UPDATE_MODAL";
export const CLOSE_UPDATE_DELIVERY_STATUS_NOTIFICATION =
  "CLOSE_UPDATE_DELIVERY_STATUS_NOTIFICATION";
export const ON_JOB_STATUS_CHANGE = "ON_JOB_STATUS_UPDATE";
export const ON_JOB_STATUS_NOTE_CHANGE = "ON_JOB_STATUS_NOTE_CHANGE";
export const ON_JOB_STATUS_PAYTYPE_CHANGE = "ON_JOB_STATUS_PAYTYPE_CHANGE";
export const ON_JOB_STATUS_PAYAMOUNT_CHANGE = "ON_JOB_STATUS_PAYAMOUNT_CHANGE";

// Notifications
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const UPDATE_JOB_STATUS_NOTE = "UPDATE_JOB_STATUS_NOTE";

// Appointment update modal
export const UPDATE_FIELD = "UPDATE_FIELD";
export const OPEN_APPOINTMENT_UPDATE_MODAL = "OPEN_APPOINTMENT_UPDATE_MODAL";
export const CLOSE_APPOINTMENT_UPDATE_MODAL = "CLOSE_APPOINTMENT_UPDATE_MODAL";
export const RESET_APPOINTMENT_UPDATE_MODAL = "RESET_APPOINTMENT_UPDATE_MODAL";
export const SUBMIT_APPOINTMENT_UPDATE_MODAL =
  "SUBMIT_APPOINTMENT_UPDATE_MODAL";
export const UPDATE_DELIVERY_JOB_APPOINTMENT =
  "UPDATE_DELIVERY_JOB_APPOINTMENT";
export const ON_APPOINTMENT_DATE_CHANGE = "ON_APPOINTMENT_DATE_CHANGE";
export const ON_APPOINTMENT_TIME_CHANGE = "ON_APPOINTMENT_TIME_CHANGE";
export const ON_APPOINTMENT_NOTE_CHANGE = "ON_APPOINTMENT_NOTE_CHANGE";
export const UPDATE_DELIVERY_JOB_APPOINTMENT_SUCCESS =
  "UPDATE_DELIVERY_JOB_APPOINTMENT_SUCCESS";
export const UPDATE_DELIVERY_JOB_APPOINTMENT_ERROR =
  "UPDATE_DELIVERY_JOB_APPOINTMENT_ERROR";

// Assign delivery agent modal
export const TOGGLE_AGENT_MODAL = "TOGGLE_AGENT_MODAL";
export const RESET_ASSIGN_AGENT_MODAL = "RESET_ASSIGN_AGENT_MODAL";
export const FETCH_DELIVERY_AGENTS = "FETCH_AGENTS";
export const FETCH_DELIVERY_AGENTS_SUCCESS = "FETCH_AGENTS_SUCCESS";
export const FETCH_DELIVERY_AGENTS_ERROR = "FETCH_AGENTS_ERROR";
export const ON_AGENT_CHANGE = "ON_AGENT_CHANGE";
export const SUBMIT_ASSIGN_AGENT_MODAL = "SUBMIT_ASSIGN_AGENT_MODAL";
export const ASSIGN_AGENT_SUCCESS = "ASSIGN_AGENT_SUCCESS";
export const ASSSIGN_AGENT_ERROR = "ASSIGN_AGENT_ERROR";
export const UPDATE_DELIVERY_AGENT_SUCCESS = "UPDATE_DELIVERY_AGENT_SUCCESS";
export const UPDATE_DELIVERY_AGENT_ERROR = "UPDATE_DELIVERY_AGENT_ERROR";

//employee inventory
export const FETCH_EMPLOYEE_INVENTORY = "FETCH_EMPLOYEE_INVENTORY";
export const FETCH_EMPLOYEE_INVENTORY_SUCCESS =
  "FETCH_EMPLOYEE_INVENTORY_SUCCESS";
export const FETCH_EMPLOYEE_INVENTORY_ERROR = "FETCH_EMPLOYEE_INVENTORY_ERROR";
export const FETCH_AVAILABLE_ITEMS = "FETCH_AVAILABLE_ITEMS";
export const FETCH_AVAILABLE_ITEMS_SUCCESS = "FETCH_AVAILABLE_ITEMS_SUCCESS";
export const FETCH_AVAILABLE_ITEMS_ERROR = "FETCH_AVAILABLE_ITEMS_ERROR";

export const ALLOCATE_TO_EMPLOYEE = "ALLOCATE_TO_EMPLOYEE";
export const REMOVE_NONPICKED_ITEMS = "REMOVE_NONPICKED_ITEMS";
export const REMOVE_PICKED_ITEMS = "REMOVE_PICKED_ITEMS";
export const CONFIRM_NON_SERIALIZE_ITEM_RETURN =
  "CONFIRM_NON_SERIALIZE_ITEM_RETURN";
export const CONFIRM_RETURN_ITEM = "CONFIRM_RETURN_ITEM";

// Table select
export const SELECT_ROW = "SELECT_ROW";

//Payment Module
export const FETCH_WALLET_BALANCE = "FETCH_WALLET_BALANCE";
export const FETCH_WALLET_BALANCE_SUCCESS = "FETCH_WALLET_BALANCE_SUCCESS";
export const FETCH_WALLET_BALANCE_ERROR = "FETCH_WALLET_BALANCE_ERROR";
export const FETCH_PLANS_BY_INSTALLATION_ID = "FETCH_PLANS_BY_INSTALLATION_ID";
export const FETCH_PLANS_BY_INSTALLATION_ID_SUCCESS =
  "FETCH_PLANS_BY_INSTALLATION_ID_SUCCESS";
export const FETCH_PLANS_BY_INSTALLATION_ID_ERROR =
  "FETCH_PLANS_BY_INSTALLATION_ID_ERROR";
export const APPLY_PLAN = "APPLY_PLAN";
export const APPLY_PLAN_FOR_INSTALLATION = "APPLY_PLAN_FOR_INSTALLATION";
export const APPLY_DISCOUNT = "APPLY_DISCOUNT";
export const APPLY_REFERRAL = "APPLY_REFERRAL";
export const FETCH_TRANSACTION_HISTORY = "FETCH_TRANSACTION_HISTORY";
export const FETCH_DP_CASH_TRANSACTION_HISTORY =
  "FETCH_DP_CASH_TRANSACTION_HISTORY";
export const FETCH_TRANSACTION_HISTORY_SUCCESS =
  "FETCH_TRANSACTION_HISTORY_SUCCESS";
export const FETCH_DP_CASH_TRANSACTION_HISTORY_SUCCESS =
  "FETCH_DP_CASH_TRANSACTION_HISTORY_SUCCESS";
export const FETCH_TRANSACTION_HISTORY_ERROR =
  "FETCH_TRANSACTION_HISTORY_ERROR";
export const FETCH_ALL_INSTALLATIONS = "FETCH_ALL_INSTALLATIONS";
export const FETCH_ALL_INSTALLATIONS_SUCCESS =
  "FETCH_ALL_INSTALLATIONS_SUCCESS";
export const FETCH_ALL_INSTALLATIONS_ERROR = "FETCH_ALL_INSTALLATIONS_ERROR";
export const FETCH_INSTALLATION_DATA = "FETCH_INSTALLATION_DATA";
export const FETCH_INSTALLATION_DATA_SUCCESS =
  "FETCH_INSTALLATION_DATA_SUCCESS";
export const FETCH_INSTALLATION_DATA_ERROR = "FETCH_INSTALLATION_DATA_ERROR";
export const FETCH_PURIFIER_DETAILS = "FETCH_PURIFIER_DETAILS";
export const FETCH_PURIFIER_DETAILS_ERROR = "FETCH_PURIFIER_DETAILS_ERROR";
export const FETCH_PURIFIER_DETAILS_SUCCESS = "FETCH_PURIFIER_DETAILS_SUCCESS";
export const GENERATE_PAYMENT_LINK = "GENERATE_PAYMENT_LINK";
export const GENERATE_PAYMENT_LINK_ERROR = "GENERATE_PAYMENT_LINK_ERROR";
export const GENERATE_PAYMENT_LINK_SUCCESS = "GENERATE_PAYMENT_LINK_SUCCESS";
export const FETCH_PAYMENT_LINKS = "FETCH_PAYMENT_LINKS";
export const FETCH_PAYMENT_LINKS_ERROR = "FETCH_PAYMENT_LINKS_ERROR";
export const FETCH_PAYMENT_LINKS_SUCCESS = "FETCH_PAYMENT_LINKS_SUCCESS";
export const FETCH_PAYMENT_PLANS = "FETCH_PAYMENT_PLANS";
export const FETCH_PAYMENT_PLANS_ERROR = "FETCH_PAYMENT_PLANS_ERROR";
export const FETCH_PAYMENT_PLANS_SUCCESS = "FETCH_PAYMENT_PLANS_SUCCESS";
export const CHANGE_PAYMENT_PLAN = "CHANGE_PAYMENT_PLAN";
export const CHANGE_PAYMENT_PLAN_ERROR = "CHANGE_PAYMENT_PLAN_ERROR";
export const CHANGE_PAYMENT_PLAN_SUCCESS = "CHANGE_PAYMENT_PLAN_SUCCESS";
export const FETCH_TICKETS_FOR_PURIFIER = "FETCH_TICKETS_FOR_PURIFIER";
export const FETCH_TICKETS_FOR_PURIFIER_ERROR =
  "FETCH_TICKETS_FOR_PURIFIER_ERROR";
export const FETCH_TICKETS_FOR_PURIFIER_SUCCESS =
  "FETCH_TICKETS_FOR_PURIFIER_SUCCESS";
export const FETCH_TICKET_DETAILS = "FETCH_TICKET_DETAILS";
export const FETCH_TICKET_DETAILS_ERROR = "FETCH_TICKET_DETAILS_ERROR";
export const FETCH_TICKET_DETAILS_SUCCESS = "FETCH_TICKET_DETAILS_SUCCESS";
export const ADD_INSTALLATION_NOTES = "ADD_INSTALLATION_NOTES";
export const ADD_PENDING_PAYMENT = "ADD_PENDING_PAYMENT";
export const FETCH_PAYMENT_LINK_TYPE = "FETCH_PAYMENT_LINK_TYPE";
export const FETCH_PAYMENT_LINK_TYPE_ERROR = "FETCH_PAYMENT_LINK_TYPE_ERROR";
export const GENERATE_INVOICE = "GENERATE_INVOICE";
export const GENERATE_INVOICE_SUCCESS = "GENERATE_INVOICE_SUCCESS";
export const GENERATE_INVOICE_ERROR = "GENERATE_INVOICE_ERROR";
export const FETCH_PAYMENT_LINK_TYPE_SUCCESS =
  "FETCH_PAYMENT_LINK_TYPE_SUCCESS";
export const FETCH_PLANS_TO_BE_APPLIED_LIST = "FETCH_PLANS_TO_BE_APPLIED_LIST";
export const FETCH_PLANS_TO_BE_APPLIED_LIST_ERROR =
  "FETCH_PLANS_TO_BE_APPLIED_LIST_ERROR";
export const FETCH_PLANS_TO_BE_APPLIED_LIST_SUCCESS =
  "FETCH_PLANS_TO_BE_APPLIED_LIST_SUCCESS";
export const FETCH_SYNC_HISTORY = "FETCH_SYNC_HISTORY";
export const FETCH_SYNC_HISTORY_ERROR = "FETCH_SYNC_HISTORY_ERROR";
export const FETCH_SYNC_HISTORY_SUCCESS = "FETCH_SYNC_HISTORY_SUCCESS";
export const FETCH_SYNC_HISTORY_SUMMARY = "FETCH_SYNC_HISTORY_SUMMARY";
export const FETCH_SYNC_HISTORY_SUMMARY_ERROR =
  "FETCH_SYNC_HISTORY_SUMMARY_ERROR";
export const FETCH_SYNC_HISTORY_SUMMARY_SUCCESS =
  "FETCH_SYNC_HISTORY_SUMMARY_SUCCESS";
export const FETCH_PAYMENT_DETAILS = "FETCH_PAYMENT_DETAILS";
export const FETCH_PAYMENT_DETAILS_ERROR = "FETCH_PAYMENT_DETAILS_ERROR";
export const FETCH_PAYMENT_DETAILS_SUCCESS = "FETCH_PAYMENT_DETAILS_SUCCESS";
export const FETCH_PAYMENT_PLANS_BY_ID = "FETCH_PAYMENT_PLANS_BY_ID";
export const FETCH_PAYMENT_PLANS_BY_ID_ERROR =
  "FETCH_PAYMENT_PLANS_BY_ID_ERROR";
export const FETCH_PAYMENT_PLANS_BY_ID_SUCCESS =
  "FETCH_PAYMENT_PLANS_BY_ID_SUCCESS";
export const DELETE_STATE = "DELETE_STATE";

// Workforce Available Job Slots
export const FETCH_AVAILABLE_SLOTS = "FETCH_AVAILABLE_SLOTS";
export const FETCH_AVAILABLE_SLOTS_ERROR = "FETCH_AVAILABLE_SLOTS_ERROR";
export const FETCH_AVAILABLE_SLOTS_SUCCESS = "FETCH_AVAILABLE_SLOTS_SUCCESS";
export const FETCH_ALL_TEAMS = "FETCH_ALL_TEAMS";
export const FETCH_ALL_TEAMS_ERROR = "FETCH_ALL_TEAMS_ERROR";
export const FETCH_ALL_TEAMS_SUCCESS = "FETCH_ALL_TEAMS_SUCCESS";
export const FETCH_ALL_REPEATED_JOBS = "FETCH_ALL_REPEATED_JOBS";
export const FETCH_ALL_REPEATED_JOBS_ERROR = "FETCH_ALL_REPEATED_JOBS_ERROR";
export const FETCH_ALL_REPEATED_JOBS_SUCCESS =
  "FETCH_ALL_REPEATED_JOBS_SUCCESS";
export const SUBMIT_RCA_DETAILS = "SUBMIT_RCA_DETAILS";
export const SUBMIT_RCA_DETAILS_ERROR = "SUBMIT_RCA_DETAILS_ERROR";
export const SUBMIT_RCA_DETAILS_SUCCESS = "SUBMIT_RCA_DETAILS_SUCCESS";
export const FETCH_DEPENDENCIES = "FETCH_DEPENDENCIES";
export const FETCH_DEPENDENCIES_ERROR = "FETCH_DEPENDENCIES_ERROR";
export const FETCH_DEPENDENCIES_SUCCESS = "FETCH_DEPENDENCIES_SUCCESS";

// Workforce Items List
export const FETCH_ITEMS = "FETCH_ITEMS";
export const FETCH_ITEMS_SUCCESS = "FETCH_ITEMS_SUCCESS";
export const FETCH_ITEMS_ERROR = "FETCH_ITEMS_ERROR";

export const UPDATE_ITEM_LEVEL_QUANTITY = "UPDATE_ITEM_LEVEL_QUANTITY";
export const UPDATE_ITEM_LEVEL_QUANTITY_ERROR =
  "UPDATE_ITEM_LEVEL_QUANTITY_ERROR";
export const EDIT_WAREHOUSE = "EDIT_WAREHOUSE";
export const EDIT_WAREHOUSE_SUCCESS = "EDIT_WAREHOUSE_SUCCESS";
export const EDIT_WAREHOUSE_ERROR = "EDIT_WAREHOUSE_ERROR";
export const FETCH_AREA_ZONES_BY_CITY = "FETCH_AREA_ZONES_BY_CITY";
export const FETCH_AREA_ZONES_BY_CITY_SUCCESS =
  "FETCH_AREA_ZONES_BY_CITY_SUCCESS";
export const FETCH_AREA_ZONES_BY_CITY_ERROR = "FETCH_AREA_ZONES_BY_CITY_ERROR";
export const UPDATE_GS_THRESHOLD_LIMITS = "UPDATE_GS_THRESHOLD_LIMITS";
export const UPDATE_GS_THRESHOLD_LIMITS_SUCCESS =
  "UPDATE_GS_THRESHOLD_LIMITS_SUCCESS";
export const UPDATE_GS_THRESHOLD_LIMITS_ERROR =
  "UPDATE_GS_THRESHOLD_LIMITS_ERROR";
export const RESET_ZONES = "RESET_ZONES";

// Sponsors
export const FETCH_ALL_SPONSORS = "FETCH_ALL_SPONSORS";
export const FETCH_ALL_SPONSORS_SUCCESS = "FETCH_ALL_SPONSORS_SUCCESS";
export const FETCH_ALL_SPONSORS_ERROR = "FETCH_ALL_SPONSORS_ERROR";
export const FETCH_INVESTMENTS_BY_SPONSOR_ID =
  "FETCH_INVESTMENTS_BY_SPONSOR_ID";
export const FETCH_INVESTMENTS_BY_SPONSOR_ID_SUCCESS =
  "FETCH_INVESTMENTS_BY_SPONSOR_ID_SUCCESS";
export const FETCH_INVESTMENTS_BY_SPONSOR_ID_ERROR =
  "FETCH_INVESTMENTS_BY_SPONSOR_ID_ERROR";
export const FETCH_SPONSOR = "FETCH_SPONSOR";
export const FETCH_SPONSOR_SUCCESS = "FETCH_SPONSOR_SUCCESS";
export const FETCH_SPONSOR_ERROR = "FETCH_SPONSOR_ERROR";
export const GENERATE_SPONSOR_CSV = "GENERATE_SPONSOR_CSV";
export const GENERATE_SPONSOR_CSV_SUCCESS = "GENERATE_SPONSOR_CSV_SUCCESS";
export const FETCH_SPONSOR_CODES = "FETCH_SPONSOR_CODES";
export const FETCH_SPONSOR_CODES_SUCCESS = "FETCH_SPONSOR_CODES_SUCCESS";
export const FETCH_BANK_ACCOUNTS = "FETCH_BANK_ACCOUNTS";
export const FETCH_BANK_ACCOUNTS_SUCCESS = "FETCH_BANK_ACCOUNTS_SUCCESS";
export const FETCH_BANK_ACCOUNTS_ERROR = "FETCH_BANK_ACCOUNTS_ERROR";
export const FETCH_COMMANDS = "FETCH_COMMANDS";
export const FETCH_COMMANDS_SUCCESS = "FETCH_COMMANDS_SUCCESS";
export const FETCH_COMMANDS_ERROR = "FETCH_COMMANDS_ERROR";

export const FETCH_INVESTMENTS_BY_BANK_ACCOUNT =
  "FETCH_INVESTMENTS_BY_BANK_ACCOUNT";
export const FETCH_INVESTMENTS_BY_BANK_ACCOUNT_SUCCESS =
  "FETCH_INVESTMENTS_BY_BANK_ACCOUNT_SUCCESS";
export const FETCH_INVESTMENTS_BY_BANK_ACCOUNT_ERROR =
  "FETCH_INVESTMENTS_BY_BANK_ACCOUNT_ERROR";
export const ADD_BANK_ACCOUNT_ERROR = "ADD_BANK_ACCOUNT_ERROR";
export const ADD_BANK_ACCOUNT = "ADD_BANK_ACCOUNT";
export const ADD_BANK_ACCOUNT_SUCCESS = "ADD_BANK_ACCOUNT_SUCCESS";
export const UPDATE_BANK_ACCOUNT = "UPDATE_BANK_ACCOUNT";
export const UPDATE_BANK_ACCOUNT_SUCCESS = "UPDATE_BANK_ACCOUNT_SUCCESS";

export const RECHARGE_B2B_PAYMENT = "RECHARGE_B2B_PAYMENT";
export const RECHARGE_B2B_PAYMENT_REQUESTED = "RECHARGE_B2B_PAYMENT_REQUESTED";
export const RECHARGE_B2B_PAYMENT_SUCCESS = "RECHARGE_B2B_PAYMENT_SUCCESS";
export const RECHARGE_B2B_PAYMENT_FAILURE = "RECHARGE_B2B_PAYMENT_FAILURE";
export const RECHARGE_B2B_PAYMENT_RESET = "RECHARGE_B2B_PAYMENT_RESET";

export const FETCH_CUSTEMER_PLANS_ERROR = "FETCH_CUSTEMER_PLANS_ERROR";
export const FETCH_CUSTOMER_PLANS_REQUEST = "FETCH_CUSTOMER_PLANS_REQUEST";
export const FETCH_CUSTOMER_PLANS_SUCCESS = "FETCH_CUSTOMER_PLANS_SUCCESS";

export const CHANGE_PLAN_DURING_DELIVERY = "CHANGE_PLAN_DURING_DELIVERY";
export const CHANGE_PLAN_DURING_DELIVERY_REQUEST =
  "CHANGE_PLAN_DURING_DELIVERY_REQUEST";
export const CHANGE_PLAN_DURING_DELIVERY_SUCCESS =
  "CHANGE_PLAN_DURING_DELIVERY_SUCESS";
export const CHANGE_PLAN_DURING_DELIVERY_RESET =
  "CHANGE_PLAN_DURING_DELIVERY_RESET";
export const CHANGE_PLAN_DURING_DELIVERY_FAILURE =
  "CHANGE_PLAN_DURING_DELIVERY_FAILURE";

export const FETCH_COUPON_REQUEST = "FETCH_COUPON_REQUEST";
export const FETCH_COUPON_SUCCESS = "FETCH_COUPON_SUCESS";
export const FETCH_COUPON_FAILURE = "FETCH_COUPON_FAILURE";

export const FETCH_PLAN_DETAILS_FROM_PLAN_NAME_AND_VALIDITY_REQUEST =
  "FETCH_PLAN_DETAILS_FROM_PLAN_NAME_AND_VALIDITY_REQUEST";
export const FETCH_PLAN_DETAILS_FROM_PLAN_NAME_AND_VALIDITY_SUCCESS =
  "FETCH_PLAN_DETAILS_FROM_PLAN_NAME__AND_VALIDITY_SUCCESS";
export const FETCH_PLAN_DETAILS_FROM_PLAN_NAME_AND_VALIDITY_FAILURE =
  "FETCH_PLAN_DETAILS_FROM_PLAN_NAME__AND_VALIDITY_FAILURE";
