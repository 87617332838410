import {
  FETCH_ALL_DELIVERY_JOBS_SUCCESS,
  SHOW_API_MESSAGE,
  SELECT_ROW,
  FETCH_ALL_DELIVERY_JOBS,
} from "constants/ActionTypes";

const INIT_STATE = {
  deliveryJobs: [],
  selected: [],
  totalCount: 0,
  loader: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_DELIVERY_JOBS_SUCCESS: {
      return {
        ...state,
        deliveryJobs: action.payload.data,
        totalCount: action.payload.total,
        selected: [],
        loader: false,
      };
    }
    case SHOW_API_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case SELECT_ROW: {
      return {
        ...state,
        selected: action.payload,
      };
    }
    case FETCH_ALL_DELIVERY_JOBS: {
      return {
        ...state,
        loader: true,
      };
    }

    default:
      return state;
  }
};
