import {
    FETCH_ITEMS_SUCCESS
} from 'constants/ActionTypes';

const INIT_STATE = {
    itemData: {
        body: [],
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_ITEMS_SUCCESS: {
            const itemData = action.payload;
            return { itemData };
        }
        default: {
            return state;
        }
    }
};
