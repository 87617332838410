import { NotificationManager } from "react-notifications";
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import { workForceAxios as axios } from "util/Api";
import {
  updateDeliveryJobAgentError, updateDeliveryJobAgentSuccess
} from "../actions/assignDeliveryAgentActions";
import {
  fetchDeliveryAgentsError, fetchDeliveryAgentsSuccess
} from "../actions/deliveryAgentsActions";
import {
  fetchDeliveryJob, fetchDeliveryJobError, fetchDeliveryJobs as fetchAllJobs, fetchDeliveryJobsSuccess,
  fetchDeliveryJobSuccess,
  showAPIMessage
} from "../actions/DeliveryAPI";
import {
  toggleAgentModal,

  toggleAppointmentModal, toggleStatusModal
} from "../actions/uiActions";
import {
  updateJobAppointmentError, updateJobAppointmentSuccess
} from "../actions/updateJobAppointmentActions";
import {
  updateDeliveryJobStatusError, updateDeliveryJobStatusSuccess
} from "../actions/updateJobStatusActions";
import {
  FETCH_ALL_DELIVERY_JOBS,



  FETCH_DELIVERY_AGENTS, FETCH_DELIVERY_JOB,



  SUBMIT_ASSIGN_AGENT_MODAL, UPDATE_DELIVERY_JOB_APPOINTMENT, UPDATE_DELIVERY_JOB_STATUS
} from "../constants/ActionTypes";










const getFilterSortSearchParams = state => {
  return {
    ...state.pagination,
    ...state.filters,
    ...state.sort,
  };
};
/*
 * TODO: Fix notifications
 *
 */

/*
 * Get  delivery jobs
 *
 */
function* fetchDeliveryJobsRequest(action) {
  try {
    const dJobs = yield call(getDeliveryJobs, action.payload);
    yield put(fetchDeliveryJobsSuccess(dJobs));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getDeliveryJobs = async function (params) {
  let dJobs = [];
  try {
    const response = await axios.get("/delivery/getJobs", {
      params,
    });
    dJobs = response.data;
  } catch (error) {
    console.log(error);
  }
  return dJobs;
};

/*
 * Get delivery job
 *
 */
function* fetchDeliveryJobRequest(action) {
  try {
    const deliveryJob = yield call(getDeliveryJob, {
      jobId: action.payload.id,
    });
    yield put(fetchDeliveryJobSuccess(deliveryJob));
  } catch (error) {
    yield put(fetchDeliveryJobError(error.message));
  }
}

const getDeliveryJob = async function (params) {
  try {
    const response = await axios.get("/delivery/getJob", { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/*
 *  Get all delivery agents
 *
 */
function* fetchDeliveryAgentsRequest(action) {
  try {
    const deliveryAgents = yield call(fetchDeliveryAgents, action.payload);
    yield put(fetchDeliveryAgentsSuccess(deliveryAgents));
  } catch (error) {
    yield put(fetchDeliveryAgentsError(error.message));
    NotificationManager.error(
      "Failed to fetch delivery agents, please try again"
    );
  }
}

const fetchDeliveryAgents = async params => {
  try {
    const response = await axios.get("/delivery/getAgents", { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/*
 * Update delivery job appointment
 *
 */
function* updateDeliveryJobAppointmentRequest(action) {
  try {
    const result = yield call(updateDeliveryJobAppointment, action.payload);
    yield all([
      put(updateJobAppointmentSuccess(result, "Update Success")),
      put(toggleAppointmentModal(false)),
      put(fetchDeliveryJob(action.payload.jobId)),
    ]);

    NotificationManager.success("Appointment time updated successfully");
  } catch (error) {
    yield all([put(updateJobAppointmentError(error.message, "Update failed"))]);
    NotificationManager.error("Failed to update appointment time");
  }
}

const updateDeliveryJobAppointment = async function (params) {
  try {
    const response = await axios.put("/delivery/editJobAppt", { ...params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/*
 * Update delivery job agents[bulk]
 *
 */
function* updateDeliveryJobAgentRequest(action) {
  try {
    const result = yield call(updateDeliveryJobAgent, action.payload);
    const filterSortSearchParams = yield select(getFilterSortSearchParams);
    yield all([
      put(updateDeliveryJobAgentSuccess(result, "Update Success")),
      put(toggleAgentModal(false)),
      put(fetchAllJobs(filterSortSearchParams)),
    ]);
    NotificationManager.success("Agent assigned successfully");
  } catch (error) {
    put(updateDeliveryJobAgentError(error));
    NotificationManager.error("You do not have the access to perform this action.");
  }
}

const updateDeliveryJobAgent = async function (params) {
  try {
    const response = await axios.put("/delivery/assignJobs", { ...params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/*
 * Update delivery job status
 *
 */
function* updateDeliveryJobStatusRequest(action) {
  try {
    const result = yield call(updateDeliveryJobStatus, action.payload);

    yield all([
      put(updateDeliveryJobStatusSuccess(result, "Update Success")),
      put(toggleStatusModal(false)),
      put(fetchDeliveryJob(action.payload.jobId)),
    ]);
    NotificationManager.success("Status updated successfully");
  } catch (error) {
    yield all([put(updateDeliveryJobStatusError(error.message))]);
    NotificationManager.error("Failed to update job status");
  }
}

const updateDeliveryJobStatus = async function (params) {
  try {
    const response = await axios.put("/delivery/editJobStatus", { ...params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* TODO:
 * - Split this function into logically seperated functions
 *
 */
function* fetchDeliveryJobs() {
  yield takeLatest(FETCH_ALL_DELIVERY_JOBS, fetchDeliveryJobsRequest);
  yield takeLatest(FETCH_DELIVERY_JOB, fetchDeliveryJobRequest);
  yield takeLatest(UPDATE_DELIVERY_JOB_STATUS, updateDeliveryJobStatusRequest);
  yield takeLatest(
    UPDATE_DELIVERY_JOB_APPOINTMENT,
    updateDeliveryJobAppointmentRequest
  );
  yield takeLatest(FETCH_DELIVERY_AGENTS, fetchDeliveryAgentsRequest);
  yield takeLatest(SUBMIT_ASSIGN_AGENT_MODAL, updateDeliveryJobAgentRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchDeliveryJobs)]);
}
