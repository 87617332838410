import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";


const renderRequiredTextField = ({
    input,
    label,
    meta: { touched, error },
    ...custom
}) => (
        <TextField
            label={label}
            error={touched && error}
            helperText={error}
            margin="normal"
            value="data"
            required
            fullWidth
            {...input}
            {...custom}
        />
    );
export const passwordsMustMatch = (value, allValues) =>
    value !== allValues.password ?
        'Passwords do not match' :
        undefined
class PasswordForm extends Component {

    render() {
        const { handleSubmit, pristine, reset, submitting } = this.props;
        return (
            <div>
                <form className="row" onSubmit={handleSubmit}>

                    <div className="col-md-6 col-12">
                        <Field
                            name="password"
                            component={renderRequiredTextField}
                            label="Password"
                            type="password"
                        />
                    </div>
                    <br></br>
                    <div className="col-md-6 col-12">
                        <Field
                            name="reenterPassword"
                            component={renderRequiredTextField}
                            label="Re-enter Password"
                            type="password"
                            validate={passwordsMustMatch}
                        />
                    </div>
                    <div className="align-items-center justify-content-between" style={{ margin: "10px" }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={pristine || submitting}
                            style={{ margin: "10px" }}
                        >
                            Submit
            </Button>
                        <Button
                            variant="outlined"
                            onClick={reset}
                            color="primary"
                            disabled={pristine || submitting}
                            style={{ margin: "10px" }}
                        >
                            Clear Values
            </Button>
                    </div>
                </form>
            </div >
        );
    }
}

PasswordForm = reduxForm({
    form: "PasswordForm", // a unique identifier for this form
    enableReinitialize: true,
})(PasswordForm);

export default PasswordForm;
