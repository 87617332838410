import React from 'react';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from 'util/IntlMessages';
import { userSignOut } from 'actions/Auth';
import { Link } from 'react-router-dom';

class UserInfo extends React.Component {

  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    let name = "", role = "";
    if (this.props.authUser) {
      name = this.props.authUser.firstname + " " + this.props.authUser.lastname;
      role = this.props.authUser.role;
    }

    return (
      <div className="user-profile d-flex flex-row align-items-center">
        <Avatar className="bg-dark size-50">
          <i className="zmdi zmdi-face text-white" />
        </Avatar>
        <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>{name}
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>
          <p className="user-description">{role}</p>
        </div>
        <Menu className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0
            }
          }}>
          <MenuItem onClick={this.handleRequestClose}>
            <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
            {/* <IntlMessages id="popup.profile"/> */}
            <Link to="/app/profile" style={{ color: "black" }}>
              Profile
            </Link>
          </MenuItem>
          <MenuItem onClick={this.handleRequestClose}>
            <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.setting" />
          </MenuItem>
          <MenuItem onClick={() => {
            this.handleRequestClose();
            this.props.userSignOut();
          }}>
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser }
};

export default connect(mapStateToProps, {
  userSignOut
})(UserInfo);
