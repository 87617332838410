import {
    OPEN_STATUS_UPDATE_MODAL,
    CLOSE_STATUS_UPDATE_MODAL,
    UPDATE_DELIVERY_JOB_STATUS_ERROR,
    UPDATE_ZIP
} from "../constants/ActionTypes.js";

const initialState = {
    open: false,
    error: "",
    message: "",
    status: "",
    showNotification: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        // case OPEN_ZIP_UPDATE_MODAL: {
        //     return {
        //         ...state,
        //         ...action.payload,
        //     };
        // }
        // case CLOSE_ZIP_UPDATE_MODAL: {
        //     return {
        //         ...state,
        //         ...action.payload,
        //     };
        // }
        case UPDATE_ZIP: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
};
